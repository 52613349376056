import React, { useEffect, useState } from "react";
import { orange_arrow, rightCircle, star } from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import { withRouter } from "../../../Utils/with-router/withRouter";
// import Iframe from "react-iframe";

const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateThree = ({ router }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [slidePosition, setSlidePosition] = useState(0);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-three">
      <div className="sd-t3-one">
        <div className="nav-circles">
          <img
            src={rightCircle}
            style={{ rotate: "180deg", filter: "grayscale(1)" }}
            height="45px"
            onClick={() => {
              if (slidePosition <= 0) {
                return;
              }
              const slide = slidePosition;
              setSlidePosition(slide - 1);
            }}
          />
          <img
            src={rightCircle}
            style={{ filter: "grayscale(1)" }}
            height="45px"
            onClick={() => {
              if (slidePosition === 3) {
                return;
              }
              setSlidePosition(slidePosition + 1);
            }}
          />
        </div>
        <h1>
          <span style={{ color: "#E36414" }}>Devotee </span> Experience
        </h1>
        <p>
          Devotees from around the world are finding a new way to worship. Have
          you found yours?
        </p>
        <button
          className="sd-b-orange"
          onClick={() => {
            // router?.navigate(`/${selectedlanguage}/temples-live`);
          }}
        >
          View
          <img src={orange_arrow} alt=">" />
        </button>{" "}
      </div>
      {["1", "2", "3", "4", "5", "6", "7", "8"]
        .slice(slidePosition, slidePosition + 3)
        ?.map((item, i) => {
          return (
            <div
              key={i + "Slide position"}
              className="sd-t3-three"
              tabIndex={"1"}
              // onClick={() => {
              //   let data = templeData.find((item) => item.id === "SSVVSD")
              //     ?.devoteePortalConfiguration?.parokshaLiveStreamUrl;
              //   console.log(data);
              //   if (data) {
              //     window.open(data, "_blank");
              //   } else {
              //     toast.error(
              //       "Live link is not available for this temple. Sorry for the inconvenience"
              //     );
              //   }
              // }}
            >
              {" "}
              <div className="sd-box-one ">
                <div className="sd-bo-top">
                  <div className="sd-bot-left">
                    <h2>{item}P</h2>{" "}
                    <p>
                      Palavarappu2456,
                      <br />
                      <span>Hyderabad,India</span>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={star}
                      alt="star"
                      style={{
                        width: "44px",
                        height: "44px",
                        marginBottom: "4px",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          height: "6px",
                          width: "6px",
                          marginRight: "2px",
                          background: "#f8991d",
                        }}
                      ></p>
                      <p
                        style={{
                          height: "6px",
                          width: "6px",
                          marginRight: "2px",
                          background: "#f8991d",
                        }}
                      ></p>
                      <p
                        style={{
                          height: "6px",
                          width: "6px",
                          marginRight: "2px",
                          background: "#f8991d",
                        }}
                      ></p>
                      <p
                        style={{
                          height: "6px",
                          width: "6px",
                          marginRight: "2px",
                          background: "#f8991d",
                        }}
                      ></p>
                      <p
                        style={{
                          height: "6px",
                          width: "6px",
                          marginRight: "2px",
                          background: "#f8991d",
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
                <div className="sd-bo-middle">
                  <h3>Arunchanlam temple</h3>
                  <p>
                    Arunachalam temple is very famous old temple of Lord shiva,
                    Om namashivaya ,,, It is called tieuanamali temple in tamil
                    nadu, in the month of December special poojas will be there
                    Temple Trust.
                  </p>
                </div>
                <p className="sd-bo-bottom">21 Nov , 2022</p>
                {/* <div className="sd-box-one-image">
                <img
                  src={item?.devoteePortalConfiguration?.idolImage ?? live1}
                  alt="SD_parokshaseva"
                  className="sd-logo-img sd-size-four"
                />
              </div>
              <div
                className="sd-box-one-content w-100"
                style={{
                  display: "flex",
                  justifyContent: "flex-start !important",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    justifyContent: "flex-start !important",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={roundlogo1} height="38px" />
                  <p style={{ marginLeft: "2px", marginBottom: "0" }}>
                    {item.name}
                  </p>
                </div>
                <h2 style={{ margin: "0 0px 0px 40px ", paddingTop: "5px" }}>
                  {item?.displayName ?? "Display Name"}
                </h2>
              </div> */}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default withRouter(TemplateThree);
