import React from "react";
import { SD_divider, killi } from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";

const Sd_about_cmd = () => {
  return (
    <div className="sd-about-one">
      <div
        className="sd-about-one-bottom flex-column"
        style={{ alignItems: "center" }}
      >
        <div className="sd-about-one-bottom-top">
          <h1 style={{ fontWeight: "600", fontSize: "30px" }}>About CMD</h1>
          <img
            style={{ height: "20px" }}
            src={SD_divider}
            alt={"divider"}
          ></img>
          <div
            className="sd-about-one-bottom-bottom"
            style={{ width: "100%", margin: "30px 0px" }}
          >
            <div className="sd-abb-three" style={{ maxWidth: "100%" }}>
              <div>
                <img src={killi} alt="" />
                <h2>K Satya Prasad Killi</h2>
                <p>CMD</p>
              </div>
            </div>
          </div>
          <div className="CMDdata">
            <p>
              An Engineering and MCSE graduate, Mr. Kill held executive
              positions in salt-field and food-and-entertainment businesses
              during 1980s. He moved to UK three decades back and worked
              directly under three successive Indian High Commissioners in
              London and gained valuable experience in diplomacy and networking.
              He simultaneously utilized his free time for charity work at the
              Bhavan Centre, UK. He served as an Advisor to the Minister of
              State for Telecommunications & Information of Technology,
              Government of India. Later he served as a Consultant for
              Commonwealth in UK.
            </p>
            <p>
              Satya Prasad Killi, widely known as Kill garu among Telugu
              diaspora in the UK, is the brainwork behind establishing UKTA
              (United Kingdom Telugu Association, www.ukta.org.uk) in 2010. As
              an honorary chairman of UKTA, and being a thorough philanthropist,
              Mr. Killi successfully organized many charity and cultural
              programs in India, UK and other European countries. His wife Dr.
              Venkata Padma Killy, a General Practitioner in the UK, is a
              trustee for UKTA.
            </p>
            <p>
              A spirited soul, Mr. Killi, together with like-minded diplomats,
              bureaucrats, and intellectuals in Delhi, started the Devastanam
              Service (Worship from Home). It is an endeavor to virtually
              connect the Hindu devotees across the world to the Sevas performed
              in the Indian temples.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sd_about_cmd;
