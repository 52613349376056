/* eslint-disable react/jsx-key */
import React, { useEffect, useContext, useState, useRef } from "react";
import {
  sd_dashboard_edit_ico,
  sd_dashboard_side_arrow,
  sd_success_icon,
  sd_failure_icon,
} from "../../../../Assets/imageList";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import "../../../../Utils/sd-styles/sd-css/main.css";
import DisplayRazorpay from "../../../../Utils/sd-razorpay/sd-razorpay-display";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import { toast } from "react-toastify";
toast.configure();
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import PaymentPage from "../payment-pages/payement-page";
import { withRouter } from "../../../../Utils/with-router/withRouter";

const Sd_dashboard_pratyakshaseva_booking_confirm = ({ router }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//

  const {
    setActive,
    setActivePath,
    currentBookingPratyaksha,
    setcurrentBookingPratyaksha,
    userData,
    logout,
    templeData,
    paymentPageFlag,
    setPaymentPageFlag,
  } = useContext(SD_Context);
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [confirmedData, setConfirmedData] = useState(null);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [loading, setLoading] = useState(true);
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const refContainer = useRef();
  // const [charges, setCharges] = useState("");
  const [timestamp, setTimestamp] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  useEffect(() => {
    console.log("failed");
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
      setDissmissed("");
    }
  }, [dissmissed]);

  useEffect(() => {
    console.log(currentBookingPratyaksha);
    initFunction();
    return () => {
      setcurrentBookingPratyaksha(null);
    };
  }, []);

  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const initFunction = () => {
    setLoading(true);

    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }

    setActive("onlinebooking-pratyaksha-pooja-confirm-details");
    setActivePath(
      "Onlinebooking-Pratyaksha Pooja-Enter Details-Confirm Details"
    );
    setTimestamp("");

    const localData = JSON.parse(localStorage.getItem("payment-context"));
    if (!currentBookingPratyaksha) {
      if (localData) {
        setHalfState(localData.responseData);
        setcurrentBookingPratyaksha(localData.bookingData);
        setTimeout(() => {
          setPaymentPageFlag(true);
        }, 1000);
      } else {
        router?.navigate(
          `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja`
        );
      }
    }

    setLoading(false);
  };
  const getData = () => {
    let array = [];

    currentBookingPratyaksha.selectedArray.map((item) => {
      array.push(
        <td className="sd-bl-none">
          {item?.name}
          {currentBookingPratyaksha?.slot?.productSlot && <br></br>}
          {currentBookingPratyaksha?.slot?.productSlot &&
            `Slot Time :   ${moment(
              currentBookingPratyaksha?.slot?.productSlot?.startTime,
              "HH:mm:ss"
            ).format("hh:mm A")}
            -
            ${moment(
              currentBookingPratyaksha?.slot?.productSlot?.endTime,
              "HH:mm:ss"
            ).format("hh:mm A")}`}
          <br></br> (Pratyaksha Pooja)
        </td>
      );
    });
    return array;
  };

  const getDetailsFunction = () => {
    let array = [];
    let i = 0;
    currentBookingPratyaksha?.personStates.map((obj) => {
      if (obj?.name !== "") {
        array.push(
          <tr>
            <td>{i + 1}</td>
            <td>{obj?.name}</td>
            <td>{currentBookingPratyaksha?.gothram}</td>
          </tr>
        );
      }
      i++;
    });
    return array;
  };

  const handleSubmit = async () => {
    let res;
    if (!halfState) {
      try {
        setLoading(true);
        let dup = [...currentBookingPratyaksha.personStates];
        let arrayEx = dup.map((e) => {
          return {
            personName: e?.name,
          };
        });

        let schema = {
          cartContains: ["seva"],
          sevaBookings: [
            {
              sevaSlotId:
                currentBookingPratyaksha?.slot?.productSlotLocation
                  .productSlotId,

              bookingDate: moment(currentBookingPratyaksha?.startDate).format(
                "YYYY-MM-DD"
              ),
              phoneNumber: `+${currentBookingPratyaksha?.mobile}`,
              personDetails: arrayEx,
              gothram: currentBookingPratyaksha?.gothram,
              quotaType: "slot_location_quota",
            },
          ],
          returnPriceStats: false,
        };
        if (
          currentBookingPratyaksha?.res.templePaymentConfig.configuration
            .type === "ccavenue"
        ) {
          schema.returnURL = `${window.location.origin}/en-in/devotee-app/online-booking/payment-status`;
        }
        res = await ParokshaSevaService.bookings(
          schema,
          currentBookingPratyaksha?.selectedTemple
        );
        setHalfState(res);
        currentBookingPratyaksha?.res.templePaymentConfig.configuration.type ===
          "ccavenue" &&
          localStorage.setItem(
            "payment-context",
            JSON.stringify({
              responseData: {
                data: {
                  productDetails: res.cartItems.seva[0].productDetails,
                  price: res.cartItems.seva[0].sevaBooking.price,
                  personDetails:
                    res.cartItems.seva[0].sevaBooking.personDetails,
                },
                ...res,
              },
              bookingData: currentBookingPratyaksha,
              from: "pratyakshaSeva",
            })
          );
      } catch (e) {
        // console.log(e);ß
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          router?.navigate(
            `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-pooja`
          );
        }
        setLoading(false);

        return;
      }
    }

    try {
      if (halfState !== null) {
        if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
          toast.error(
            "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
          );
          setTimestamp("");
          setTimeout(() => {
            router?.navigate(
              `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja/booking/ganapathi-homam`
            );
          }, 3000);
          return;
        }
        console.log(
          currentBookingPratyaksha?.res.templePaymentConfig.configuration
        );
        if (
          currentBookingPratyaksha?.res.templePaymentConfig.configuration
            .type === "ccavenue"
        ) {
          if (halfState || JSON.parse(localStorage.getItem("payment"))) {
            setLoading(false);
            setTimestamp("");
            setPaymentPageFlag(true);
            return;
          }
        } else if (
          currentBookingPratyaksha?.res.templePaymentConfig.configuration
            ?.type === "razorpay"
        ) {
          await DisplayRazorpay(
            halfState?.paymentInformation?.razorOrder?.id,
            halfState?.paymentInformation?.razorOrder?.currency,
            halfState?.paymentInformation?.razorOrder?.amount,
            halfState?.cartItems?.donation,
            halfState?.paymentInformation?.onlinePaymentEntity?.id,
            "",
            setDissmissed,
            userData,
            dissmissed,
            halfState?.priceStats?.templePaymentConfig?.razorpay?.key,
            currentBookingPratyaksha?.selectedTemple
          );
          setHalfState(null);
          setConfirmedData({
            seva: currentBookingPratyaksha.selectedArray,
            date: moment(currentBookingPratyaksha.startDate).format(
              "DD-MM-YYYY"
            ),
            slot: currentBookingPratyaksha.slot,
          });
          setcurrentBookingPratyaksha(null);
          setTimestamp("");
          setSuccessOrfailureFlag(true);
          setStatus("success");
          setLoading(false);
        } else {
          setHalfState(null);
          setTimestamp("");
          toast.error(
            "Unknown payment gateway. Please contact temple authorities"
          );
          setLoading(false);
        }
        // console.log(result);
      } else {
        setTimestamp(Date.now());

        if (
          currentBookingPratyaksha?.res.templePaymentConfig.configuration
            .type === "ccavenue"
        ) {
          console.log("in ccavenue");
          if (res || JSON.parse(localStorage.getItem("payment"))) {
            setLoading(false);
            setTimestamp("");
            setPaymentPageFlag(true);
            return;
          }
        } else if (
          currentBookingPratyaksha?.res.templePaymentConfig.configuration
            .type === "razorpay"
        ) {
          await DisplayRazorpay(
            res?.paymentInformation?.razorOrder?.id,
            res?.paymentInformation?.razorOrder?.currency,
            res?.paymentInformation?.razorOrder?.amount,
            res?.cartItems?.donation,
            res?.paymentInformation?.onlinePaymentEntity?.id,
            "",
            setDissmissed,
            userData,
            dissmissed,
            res?.priceStats?.templePaymentConfig?.razorpay?.key,
            currentBookingPratyaksha?.selectedTemple
          );
          setHalfState(null);
          setSuccessOrfailureFlag(true);
          setStatus("success");
          setTimestamp("");
          setConfirmedData({
            seva: currentBookingPratyaksha.selectedArray,
            date: moment(currentBookingPratyaksha.startDate).format(
              "DD-MM-YYYY"
            ),
            slot: currentBookingPratyaksha.slot,
          });
          setcurrentBookingPratyaksha(null);
          setLoading(false);
        } else {
          toast.error(
            "Something went wrong. Please try again or contact us through support."
          );
          setLoading(false);
        }
      }
    } catch (e) {
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
    }
  };

  const getStatusFunction = () => {
    console.log(confirmedData);
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Confirmed! Your online booking for
                {confirmedData?.seva?.map((item) => {
                  return (
                    <>
                      {` ${item?.name} `}
                      on
                      {` ${moment(confirmedData?.date).format("DD-MM-YYYY")} `}
                      {confirmedData?.slot?.productSlotLocation ? (
                        ` (${moment(
                          confirmedData?.slot?.productSlotLocation?.productSlot
                            ?.startTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")} - ${moment(
                          confirmedData?.slot?.productSlotLocation
                            ?.productSlotproductSlot?.endTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")}) `
                      ) : (
                        <>
                          {IndiaTime(item?.startDate).format("hh:mm A") ===
                          IndiaTime(item?.endDate).format("hh:mm A") ? (
                            <p>
                              {item.startDate &&
                                IndiaTime(item?.startDate).format("hh:mm A")}
                            </p>
                          ) : (
                            <p>
                              {item?.startDate &&
                                IndiaTime(item?.startDate).format("hh:mm A")}
                              -
                              {item?.endDate &&
                                IndiaTime(item?.endDate).format("hh:mm A")}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
                has been made successfully. Looking forward to meeting you on a
                journey towards spirituality.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    router?.navigate(
                      `/${selectedlanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>
                <p
                  onClick={() => {
                    router?.navigate(`/${selectedlanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />
              <p>Sorry, your booking failed! {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Booking failed! Your online booking for
                {currentBookingPratyaksha.selectedArray.map((item, i) => {
                  return (
                    <>
                      {item?.name} at
                      {IndiaTime(item?.startDate).format("hh:mm A") ===
                      IndiaTime(item?.endDate).format("hh:mm A") ? (
                        <p>
                          {item.startDate &&
                            IndiaTime(item?.startDate).format("hh:mm A")}
                        </p>
                      ) : (
                        <p>
                          {item.startDate &&
                            IndiaTime(item?.startDate).format("hh:mm A")}
                          -
                          {item.end_date &&
                            IndiaTime(item?.end_date).format("hh:mm A")}
                        </p>
                      )}
                      on
                      <p>
                        {moment(currentBookingPratyaksha?.startDate).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </>
                  );
                })}
                failed. An error has occurred. Check your booking history or SMS
                after 20 mins for confirmation. Please note that any funds
                debited will credit back to your account within 7 working days.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>
                <p
                  onClick={() => {
                    router?.navigate(`/${selectedlanguage}/support/contact-us`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                router?.navigate(
                  `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja/booking/${currentBookingPratyaksha.selectedArray[0]?.name
                    ?.replace?.(/\s/g, "-")
                    .toLowerCase()}`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered sd-toggle-table">
              <thead>
                <tr>
                  <th width="50%">Temple Name</th>
                  <th width="25%">Date</th>
                  <th width="25%">Total Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {
                      templeData.find(
                        (item) =>
                          item.shortName ===
                          currentBookingPratyaksha?.selectedTemple
                      )?.name
                    }
                  </td>
                  <td>
                    {moment(currentBookingPratyaksha?.startDate).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                  <td>
                    Rs. {currentBookingPratyaksha?.res?.totalAmount}.00 <br />{" "}
                    (Handling charges:
                    {parseFloat(currentBookingPratyaksha?.res?.templeFee)})
                    {/* <br />
                    // {currentBookingPratyaksha?.res?.priestFee ??
                    //   `Priest fee: ${currentBookingPratyaksha?.res?.priestFee}`}
                    // <br />
                    {currentBookingPratyaksha?.res?.onlineConvenienceFee ??
                      `Convenience fee: ${currentBookingPratyaksha?.res?.onlineConvenienceFee}`}
                    <br />
                    {currentBookingPratyaksha?.res?.gstAmount ??
                      `Gst Amount: ${currentBookingPratyaksha?.res?.gstAmount}`}
                   */}
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th width="50%">Booking For</th>
                  <th width="25%">No of Persons</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {getData()}
                  <td>{currentBookingPratyaksha.personStates.length}</td>
                </tr>
              </tbody>
            </table>

            <div className="sd-table-detoggler">
              <div className="sd-booking-details-up">
                <label style={{ color: "grey", fontWeight: 500 }}>
                  Temple Name
                </label>
                <br />
                <span style={{ fontWeight: "600" }}>
                  {
                    templeData.find(
                      (item) =>
                        item.shortName ===
                        currentBookingPratyaksha?.selectedTemple
                    )?.name
                  }
                </span>
              </div>
              <div className="sd-booking-details-up bg">
                <div className="sd-actions-icons-main">
                  <label style={{ color: "grey", fontWeight: 500 }}>
                    BOOKING FOR
                  </label>
                  <br />
                  <span style={{ fontWeight: "600" }}>{getData()}</span>
                </div>
              </div>
              <div className="sd-booking-details-down ">
                <div>
                  <label>DATE/SLOT</label>
                  <span>
                    {moment(currentBookingPratyaksha.startDate).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </div>
                {/* {currentBooking.occasion && (
                  <div>
                    
                    <label>OCCASION</label>
                    <span> {currentBooking?.occasion}</span>
                  </div>
                )} */}
                <div style={{ marginRight: "0px" }}>
                  <label>AMOUNT</label>
                  <span>
                    {userData?.phone?.substring(0, 3) === "+91" ? "Rs." : "$"}
                    {currentBookingPratyaksha?.res?.totalAmount}.00
                    <br />
                    <span style={{ fontSize: "12px" }}>
                      (Handling charges:
                      {userData?.phone?.substring(0, 3) === "+91" ? "Rs." : "$"}
                      {~~currentBookingPratyaksha?.res?.additionalAmount}.00)
                    </span>
                  </span>
                </div>
              </div>
              {currentBookingPratyaksha.occasion && (
                <div className="sd-booking-details-down ">
                  <div>
                    <label>OCCASION</label>
                    <span> {currentBookingPratyaksha?.occasion}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="clearfix sd-devotee-details mt-30">
            <h4>Devotee Details</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="5%">S.No.</th>
                  <th width="25%">Name</th>
                  <th width="25%">Gothram</th>
                </tr>
              </thead>
              <tbody>{getDetailsFunction()}</tbody>
            </table>
          </div>
          <div className="clearfix mt-50 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment
              <img
                className="sd-arrow-btn ml-5"
                src={sd_dashboard_side_arrow}
              />
            </a>
          </div>
        </div>
      )}
      {paymentPageFlag ? (
        <PaymentPage
          bookingData={{
            responseData: {
              data: {
                productDetails: halfState?.cartItems?.seva[0].productDetails,
                price: halfState?.cartItems.seva[0].sevaBooking.price,
                personDetails:
                  halfState?.cartItems?.seva[0].sevaBooking.personDetails,
              },
              ...halfState,
            },
            bookingData: currentBookingPratyaksha,
            from: "pratyakshaSeva",
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_pratyakshaseva_booking_confirm);
