import React, { useContext, useEffect, useRef, useState } from "react";
import {
  SD_Logo_circle,
  baseBannerBg,
  goLive,
  noRecords,
  play,
  refresh,
} from "../../Assets/imageList";
import { SD_Context } from "../../context/sd-context";
import "../../Utils/sd-styles/temples.scss";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import { toast } from "react-toastify";
toast.configure();
import moment from "moment";
import Timer from "../../Components/body/timer/timer";
import { withRouter } from "../../Utils/with-router/withRouter";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import timeFunc from "../../Utils/time-func/time-func";
const SdDashboardJoinAPooja = ({ router }) => {
  const { setActivePath, setActive, logout } = useContext(SD_Context);
  const [data, setBookingHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [selected, setSelected] = useState({});
  const [streamError, setStreamError] = useState(false);
  const [secTime, setSecTime] = useState(0);
  const [ticketDetails, setTicketDetails] = useState(null);

  const webRTCAdaptor = useRef(null);
  let timeoutId;

  useEffect(() => {
    setLoading(true);
    setActive("Onlinebooking-Join A Pooja");
    setActivePath("Onlinebooking-Join A Pooja");
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getBookingHistory();
    return () => {
      console.log("disconnected socket");
      webRTCAdaptor?.current?.closeWebSocket();
    };
  }, []);

  // const milliSec = (apiTime, flag) => {
  //   const currentDate = flag
  //     ? moment().format("YYYY-MM-DD")
  //     : moment(selected?.bookingDate).format("YYYY-MM-DD");
  //   const combinedDateTime = `${currentDate}T${apiTime.split("+")[0]}`;
  //   const apiMoment = moment(combinedDateTime);
  //   const currentTime = moment();
  //   const duration = moment.duration(apiMoment.diff(currentTime));
  //   setSecTime(duration.asSeconds());
  //   return duration.asMilliseconds();
  // };

  const connectSocket = async (slot) => {
    return new Promise((res, rej) => {
      if (
        webRTCAdaptor.current === undefined ||
        webRTCAdaptor.current === null
      ) {
        webRTCAdaptor.current = new WebRTCAdaptor({
          websocket_url: `wss://ant-stream.devasthanam.co.in:5443/${slot.online_seva.locationDetails.cameraConfigurations[0].streamConfig.appName}/websocket`,
          mediaConstraints: {
            video: true,
            audio: true,
          },
          peerconnection_config: {
            iceServers: [{ urls: "stun:stun1.l.google.com:19302" }],
          },

          remoteVideoId: "remoteVideo",
          isPlayMode: true,
          callback: (info, obj) => {
            if (info == "initialized") {
              setStreamError(false);
              webRTCAdaptor.current.play(
                slot.online_seva.locationDetails.cameraConfigurations[0]
                  .streamConfig.streamId
              );
              res(true);
            }
            if (info == "play_started") {
              setStreamError(false);
            } else if (info == "play_finished") {
              setStreamError(false);
              disconnect(
                slot.online_seva.locationDetails.cameraConfigurations[0]
                  .streamConfig.streamId,
                "Stream has ended. If your slot is pre closed or closed accidentenly try after 1-2 mins."
              );
            } else if (info === "highResourceUsage") {
              disconnect(
                slot.online_seva.locationDetails.cameraConfigurations[0]
                  .streamConfig.streamId,
                "High Traffic, Cannot connect. Please retry connecting after a few minutes.!!"
              );
            }
          },
          callbackError: function (error, message) {
            console.log(error);
            setStreamError(true);
            if (error === "no_stream_exist") {
              setStreamError(true);
              if (!slot.online_seva.locationDetails.streamEnabled) {
                disconnect(
                  slot.online_seva.locationDetails.cameraConfigurations[0]
                    .streamConfig.streamId,
                  "Priest has not started the stream. Please wait and click on refresh!!"
                );
              }
            }
            if (error === "highResourceUsage") {
              disconnect(
                slot.online_seva.locationDetails.cameraConfigurations[0]
                  .streamConfig.streamId,
                "High server usage.Please retry connecting after a few minutes.!!"
              );
            }
          },
        });
      } else {
        res(false);
      }
    });
  };

  const getBookingHistory = async () => {
    try {
      let res = await Sd_DashboardService.booking_history(
        20,
        1,
        "",
        true,
        "success"
      );
      let filterApi = res?.items?.filter((item) => {
        if (
          moment(item?.bookingDate).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD")
        ) {
          if (moment(item?.slotEndTime, "HH:mm:ssZZ") >= moment()) {
            return item;
          }
        }
      });
      if (filterApi?.length > 0) {
        setBookingHistory(filterApi);
        if (timeFunc(filterApi[0]?.slotStartTime, filterApi[0]?.slotEndTime)) {
          handleSlotChange(filterApi[0]);
        }
      }
      setLoading(false);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        router?.navigate(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
      setLoading(false);
    }
  };

  const handleSlotChange = async (slot) => {
    setSelected(slot);
    await getTicket(slot);
  };

  const getTicket = async (slot) => {
    console.log("------------get ticket API-------------");
    setLoading(true);
    try {
      let res = await Sd_DashboardService.getTicket(
        slot.ticketId,
        slot.ticketId.split("-")[0].slice(1)
      );
      setTicketDetails(res[0]);
      //online_seva.locationDetails.streamEnabled
      //seva enabled check , set timeout, socket set
      startTimeoutForStopStream(res[0]);
      await checkAndConnectSocket(res[0]);
      setLoading(false);
      setStreamError(false);
    } catch (e) {
      toast.error(e);
      if (e.message === "Session Expired") {
        logout();
        router?.navigate(
          `/${selectedLanguage}/devotee/signin?to=devotee/print-a-ticket?id=${slot.ticketId}`
        );
      }
      setLoading(false);
    }
  };

  const checkAndConnectSocket = async (res) => {
    try {
      if (res.online_seva.locationDetails.streamEnabled) {
        await connectSocket(res);
      } else {
        setStreamError(true);
        toast.error(
          "Priest must enable the Live Pooja. Please wait and refresh the stream"
        );
      }
    } catch (e) {
      toast.error(e.message);
      setStreamError(true);
      console.log(e);
    }
  };

  const startTimeoutForStopStream = (item) => {
    const endTime = moment(
      `${moment().format("YYYY-MM-DD")}T${
        item.online_seva.slotEndTime.split("+")[0]
      }`
    );
    const currentTime = moment();
    const timeDifference = endTime.diff(currentTime);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      getBookingHistory();
      if (data?.length > 0) {
        setSelected(data[0]);
      } else {
        router?.navigate(`/${selectedLanguage}/devotee-app/devotee-dashboard`);
      }
    }, timeDifference);
  };

  const refreshSocket = async () => {
    try {
      await getTicket(ticketDetails.ticketId);
      await checkAndConnectSocket(ticketDetails);
      setStreamError(false);
      toast.success("Stream has being refreshed.");
    } catch (e) {
      toast.error(e.message);
      setStreamError(true);
    }
  };

  const disconnect = (streamId, errorMsg) => {
    webRTCAdaptor.current.stop(streamId);
    webRTCAdaptor.current.closeWebSocket();
    toast.error(
      // "High server usage.Please retry connecting after a few minutes.!!"
      errorMsg
    );
  };
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {" "}
      <div className="sd-profile sd-seva-form">
        <h2 className="sd-side-heading fw400">
          <span className="fw700" style={{ marginRight: "5px" }}>
            Join A
          </span>
          Pooja
        </h2>
        {loading ? (
          <Sd_loading />
        ) : data?.length > 0 ? (
          <div className="templesdiv">
            <div className="joinPooja">
              <div className="joinvideoDiv">
                {loading ? (
                  <div
                    className="thumbnail"
                    style={{
                      height: `${window?.innerHeight * 0.45}px`,
                    }}
                  ></div>
                ) : (
                  <video
                    id="remoteVideo"
                    controls
                    autoPlay
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    poster={baseBannerBg}
                  ></video>
                )}
                {selected?.templeName?.length > 0 && (
                  <div className="templeName">
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <img
                        src={SD_Logo_circle}
                        style={{ width: "42px", height: "42px" }}
                      />

                      <p>{selected?.templeName}</p>
                    </div>
                    {streamError && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                          alignItems: "center",
                          width: "10%",
                        }}
                        onClick={() => {
                          refreshSocket();
                        }}
                      >
                        <img
                          src={refresh}
                          alt="M"
                          style={{
                            width: "42px",
                            height: "42px",
                            margin: "0",
                            border: "0",
                            borderRadius: "0%",
                          }}
                        />
                        {"Refresh"}
                      </div>
                    )}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                        alignItems: "center",
                        width: "10%",
                      }}
                      onClick={() => {
                        if (videoRef && videoRef.current) {
                          const player = videoRef.current;
                          player.seekTo(player.getDuration() - 2);
                        }
                      }}
                    >
                      <img
                        src={goLive}
                        alt="M"
                        style={{
                          width: "42px",
                          height: "42px",
                          margin: "0",
                          border: "0",
                          borderRadius: "0%",
                        }}
                      />
                      {"Go Live"}
                    </div> */}
                  </div>
                )}
                {data?.map((item, i) => {
                  console.log(timeFunc(item?.slotStartTime, item?.slotEndTime));
                  return (
                    <div
                      key={i}
                      className={`poojadiv ${
                        selected?.productBookingId === item.productBookingId
                          ? "c-selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (timeFunc(item?.slotStartTime, item?.slotEndTime)) {
                          setSelected(item);
                          getTicket(item?.ticketId);
                        }
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "70%",
                        }}
                      >
                        <img
                          src={SD_Logo_circle}
                          style={{ width: "42px", height: "42px" }}
                        />
                        <div style={{ width: "100%" }}>
                          <p>{item?.serviceName}</p>
                          <span>
                            (
                            {moment(item?.slotStartTime, "HH:mm:ssZZ").format(
                              "hh:mm A"
                            ) ===
                            moment(item?.slotEndTime, "HH:mm:ssZZ").format(
                              "hh:mm A"
                            ) ? (
                              moment(item?.slotStartTime, "HH:mm:ssZZ").format(
                                "hh:mm A"
                              )
                            ) : (
                              <>
                                {" "}
                                {moment(
                                  item?.slotStartTime,
                                  "HH:mm:ssZZ"
                                ).format("hh:mm A")}{" "}
                                -{" "}
                                {moment(item?.slotEndTime, "HH:mm:ssZZ").format(
                                  "hh:mm A"
                                )}
                              </>
                            )}
                            ) - {moment(item.bookingDate).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                      {timeFunc(item?.slotStartTime, item?.slotEndTime) ? (
                        <div className="watchDiv">
                          <div
                            className="watchButton"
                            style={{ float: "right" }}
                            key={i}
                            onClick={() => {
                              setSelected(item);
                              getTicket(item?.ticketId);
                            }}
                          >
                            <img src={play}></img>
                            <p>
                              {selected?.productBookingId ===
                              item.productBookingId
                                ? "Selected"
                                : "Watch"}
                            </p>
                          </div>
                        </div>
                      ) : item?.productBookingId ===
                          selected?.productBookingId &&
                        timeFunc(item?.slotStartTime, item?.slotEndTime) ? (
                        <Timer time={secTime} />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="csoon">
            <img src={noRecords} />
            <br />
            <p className="comingSoon">No bookings found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(SdDashboardJoinAPooja);
