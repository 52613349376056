import React from "react";
import {
  SD_divider,
  vamshi,
  killi,
  siva,
  abhilash,
  aboutUs,
  aboutUsDev,
  Amit_Gupta,
  mother,
  topIdols,
  shiva,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";

const Sd_About_Dwadasa_Jyotirlingas = () => {
  return (
    <div className="sd-about-one" style={{ paddingBottom: "60px" }}>
      <div
        className="sd-about-one-bottom flex-column"
        style={{ alignItems: "center" }}
      >
        <div className="sd-about-one-bottom-top">
          <h1 style={{ fontWeight: "600", fontSize: "30px" }}>
            Dwadasa Jyotirlingas
          </h1>
          <img
            style={{ height: "20px" }}
            src={SD_divider}
            alt={"divider"}
          ></img>
        </div>
        <h3
          style={{
            fontWeight: "600",
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "16px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          Mahadev, also known as Maha Shiva in ancient lore, is depicted as the
          eternal source of cosmic energy that permeates the entire universe.
          This deity manifests in three distinct forms: Lord Brahma, responsible
          for creation; Lord Vishnu, entrusted with preservation; and Lord
          Shiva, tasked with destruction. An intriguing transformation occurs in
          the Shiva Purana, where Lord Shiva transforms into the Lingam, a
          symbol holding profound cosmic significance. The term "Lingam"
          signifies an elliptical form resembling the universe and is believed
          to embody generative potency and the source of universal energy.
        </h3>
        <br />
        <h3
          style={{
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "14px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          The Shiva Purana narrates the origin of the Lingam, known as
          "Lingavirbhavam," rooted in a celestial incident involving Lord Vishnu
          and Lord Brahma. In antiquity, these divine beings participated in a
          conversation to assert their supremacy. Lord Shiva, aiming to teach a
          lesson, metamorphosed into an endless pillar and set forth a challenge
          to Vishnu and Brahma: to comprehend the boundaries of this pillar. The
          one who achieved this task first would be acknowledged as the
          superior.
        </h3>
        <br />
        <h3
          style={{
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "14px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          Enthralled by the challenge, Lord Vishnu embarked on a downward
          journey to decipher the base of the infinite pillar, while
          simultaneously, Lord Brahma ascended atop his peacock in pursuit of
          its summit. After extensive exploration over eons, Lord Vishnu
          recognised the error of his arrogance and sincerely sought forgiveness
          from Lord Shiva, who, in compassion, pardoned him. In contrast, driven
          by hubris, Lord Brahma falsely claimed to have reached the pinnacle of
          the pillar. Lord Shiva, perceiving the deception, became infuriated
          and declared that Brahma would not receive adoration from Earth's
          inhabitants. In response to Shiva's wrath, a radiant aura emanated,
          touching 64 points on Earth's landscape, giving rise to 64 Lingas.
          Notably, 12 of these Lingas, known as Jyothirlingas, are considered
          especially sacred, believed to house the presence of Lord Shiva and
          revered by devotees.
        </h3>
        <br />
        <h3
          style={{
            fontWeight: "600",
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "16px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          The revered Dwadasa Jyothirlingas are as follows:
        </h3>
        <br />
        <img src={shiva} alt="" style={{ maxWidth: "970px" }} /> <br /> <br />
        <h3
          style={{
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "14px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          These Jyothirlingas are venerated for their distinctive attributes,
          and they are believed to be inhabited by Lord Shiva, bestowing
          blessings upon those who seek his divine presence.
        </h3>
        <br />
      </div>
    </div>
  );
};
export default Sd_About_Dwadasa_Jyotirlingas;
