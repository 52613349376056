import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import {
  baseBannerBg,
  c_microphone,
  c_radio,
  c_video,
  refresh,
  SD_Logo_circle,
  SD_Right_Arrow_Blue,
} from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
import "../../../Utils/sd-styles/sd-css/main.css";
import "../../../Utils/sd-styles/temples.scss";
import moment from "moment";
import { toast } from "react-toastify";
toast.configure();
import ConductPoojaService from "../../../services/sd-conduct-pooja-service";
import timeFunc from "../../../Utils/time-func/time-func";
import { withRouter } from "../../../Utils/with-router/withRouter";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

const SdConductAPoojaStart = ({ router }) => {
  const {
    setActivePath,
    setActive,
    removeExpiredSlots,
    userData,
    templeData,
    typeFunc,
  } = useContext(SD_Context);
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [slot, setSlot] = useState(null);
  const [mute, setMute] = useState(false);
  const [record, setRecord] = useState(false);
  const [slotLoading, setSlotLoading] = useState(false);
  const [priestSlotsData, setPriestSlotsData] = useState([]);
  const webRTCAdaptor = useRef(null);
  const [streamError, setStreamError] = useState(false);
  const [playLoading, setPlayLoading] = useState(false);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [videoLoading, setVideoLoading] = useState(false);
  let timeoutId;
  let noStreamCount = 0;

  useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setActive("conduct-a-pooja-stream");
    setActivePath("Conduct A Pooja-Stream");
    setSlotLoading(true);
    userData && getPriestSlots(true);
    return () => {
      disconnectSocket("useffect");
    };
  }, []);

  const getPriestSlots = async (flag, type) => {
    //flag is for startTimeoutForStopStream() function setup.
    setSlotLoading(true);
    const productID = router.params.id;
    try {
      let data = await ConductPoojaService.getPriestSlots(
        moment()?.format("YYYY-MM-DD"),
        templeData?.find((item) => item?.id === userData?.templeId)?.shortName
      );
      data = removeExpiredSlots(data);

      if (data.length !== 0) {
        const filteredArray = data
          .filter(
            (item) =>
              item?.productSlotLocation?.productSlot?.productId.toString() ===
              productID
          )
          ?.map((item) => {
            return {
              name: item.productSlotLocation.productSlot.product.name,
              type: typeFunc(
                item?.productSlotLocation?.productSlot?.product?.sevaType
              ),
              productId: item.productSlotLocation.productSlot.productId,
              id: item.productSlotLocation.productSlot.id,
              date: item.date,
              startTime: item.productSlotLocation.productSlot.startTime,
              endTime: item.productSlotLocation.productSlot.endTime,
              location: item.productSlotLocation.location.name,
              quantity: item.quantity,
              locationId: item.productSlotLocation.location.id,
              isStreamEnabled: item.productSlotLocation.location.streamEnabled,
              productSlotLocationId: item.productSlotLocationId,
              streamID:
                item.productSlotLocation.location.cameraConfigurations[0]
                  .streamConfig.streamId,
              streamUrl: `wss://ant-stream.devasthanam.co.in:5443/${item.productSlotLocation.location.cameraConfigurations[0].streamConfig.appName}/websocket`,
            };
          });

        const querySlotID = new URLSearchParams(router?.location?.search).get(
          "slotID"
        );

        if (querySlotID) {
          const filteredSlot = filteredArray.find(
            (item) => item.id === querySlotID
          );
          if (filteredSlot) {
            handleSlotChange(filteredSlot, flag);
          }
        }
        if (!flag) {
          console.log("toggled entry-----" + type);
          const filteredSlot = filteredArray.find(
            (item) => item.id === slot.id
          );
          if (filteredSlot) {
            if (type === "stop") {
              setSlot(filteredSlot);
              webRTCAdaptor.current.stop(filteredSlot.streamID);
              disconnectSocket("from stop");
            } else handleSlotChange(filteredSlot, false);
          }
        }
        setStreamError(false);
        setPriestSlotsData([...filteredArray]);
        setSlotLoading(false);
        setPlayLoading(false);
      } else {
        setSlotLoading(false);
        router?.navigate(`/${selectedlanguage}/devotee-app/conduct-a-pooja`);
      }
    } catch (error) {
      toast.error(error.message);
      setSlotLoading(false);
      setPlayLoading(false);
    }
  };

  const startTimeoutForStopStream = (item) => {
    console.log("in timeout function");
    const endTime = moment(
      `${moment().format("YYYY-MM-DD")}T${item.endTime.split("+")[0]}`
    );
    const currentTime = moment();
    const timeDifference = endTime.diff(currentTime);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      console.log("timeout function worked");

      toggleStream(false, item.locationId, item.id, item.productSlotLocationId);
      if (priestSlotsData.length > 1) {
        const nextslot = priestSlotsData[1];
        if (timeFunc(nextslot?.startTime, nextslot?.endTime)) {
          setSlot(priestSlotsData[1]);
        } else {
          router?.navigate(`/${selectedlanguage}/devotee-app/conduct-a-pooja`);
        }
      } else {
        router?.navigate(`/${selectedlanguage}/devotee-app/conduct-a-pooja`);
      }
    }, timeDifference);
  };

  const checkForStreamAndConnect = async (filteredSlot) => {
    if (webRTCAdaptor.current === undefined || webRTCAdaptor.current === null) {
      console.log("-----connecting socket-----");
      await connectSocket(filteredSlot);
    }
    if (filteredSlot?.isStreamEnabled) {
      console.log("-----stream is enabled so trying to play-----");
      setPlayLoading(true);
      setTimeout(() => {
        webRTCAdaptor.current.play(filteredSlot.streamID);
        setPlayLoading(false);
      }, 4500);
    } else {
      setStreamError(false);
      return;
    }
  };

  //check

  const toggleStream = async (
    type,
    locationId,
    sevaSlotID,
    sevaSlotLocationId
  ) => {
    setPlayLoading(true);

    try {
      await ConductPoojaService.toggleStream(
        locationId ? locationId : slot?.locationId,
        type === "start" ? true : false,
        sevaSlotID,
        sevaSlotLocationId,
        templeData?.find((item) => item?.id === userData?.templeId)?.shortName
      );
      toast.success({
        message: `Stream has been ${type === "stop" ? "Stopped" : "Started"}`,
      });
      getPriestSlots(false, type);
    } catch (error) {
      if (
        error?.message === "Error starting stream or stream already started"
      ) {
        checkForStreamAndConnect(slot);
      }
      toast.error(error?.message);
      setPlayLoading(false);
    }
  };

  //------------Socket Related-----------//

  const connectSocket = (filteredSlot) => {
    return new Promise((res, rej) => {
      if (
        webRTCAdaptor.current === undefined ||
        webRTCAdaptor.current === null
      ) {
        webRTCAdaptor.current = new WebRTCAdaptor({
          websocket_url: filteredSlot.streamUrl,
          mediaConstraints: {
            video: false,
            audio: false,
          },
          sdp_constraints: {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true,
          },
          peerconnection_config: {
            iceServers: [{ urls: "stun:stun1.l.google.com:19302" }],
          },
          remoteVideoId: "remoteVideo",
          callback: (info, obj) => {
            console.log({ info, obj });
            if (info === "initialized") {
              noStreamCount = 0;
              setWebsocketConnected(true);
              setStreamError(false);
              res(true);
            }
            // if (info === "newTrackAvailable") {
            //   webRTCAdaptor.current.remoteVideo();
            // }
          },
          callbackError: function (error, message) {
            console.log(error);
            if (error === "no_stream_exist") {
              if (noStreamCount > 20) {
                setStreamError(true);
                toast.error(
                  "Stream disconnecting as there is no response from server stream.Please reconnect after few seconds."
                );
                webRTCAdaptor.current.stop(filteredSlot.streamID);
                disconnectSocket("no stream issue.");
                noStreamCount = 0;
              } else {
                noStreamCount += 1;
              }
            } else if (error !== "already_playing") {
              setStreamError(true);
            }

            if (error === "closed") {
              setStreamError(true);
              toast.error({
                message: "Connection as been closed from server.",
              });
            }
          },
        });
      } else return rej(true);
    });
  };

  const disconnectSocket = (from) => {
    if (webRTCAdaptor.current) {
      console.log(webRTCAdaptor.current);
      console.log("-----disconnecting socket-----from:" + from + "----");
      webRTCAdaptor?.current.closeWebSocket();
      webRTCAdaptor.current = null;
    }
  };

  const handleSlotChange = (item, flag) => {
    if (flag && webRTCAdaptor.current) {
      webRTCAdaptor.current.stop(item.streamID);
      //disconnecting due to slot change
      disconnectSocket("slot change");
    }
    setSlot(item);
    console.log(false);
    flag && startTimeoutForStopStream(item);
    checkForStreamAndConnect(item);
  };

  //------------Socket Related-----------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard h-100"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      <div className="sd-profile sd-seva-form h-100">
        <h2 className="sd-side-heading fw400">
          <span className="fw700" style={{ marginRight: "5px" }}>
            Conduct A
          </span>
          Pooja
        </h2>
        <h4>
          Temple: {templeData.name}
          {/* {console.log(
            templeData.find((item) => item.id === userData.templeId).name
          )} */}
        </h4>
        <div className="stream">
          <div className="s-left">
            <div className="flex align-items">
              <h4 style={{ textAlign: "center", width: "100%" }}>
                {slot?.name}
              </h4>
              {priestSlotsData?.length > 1 &&
                moment()?.format("hh:mm A") >
                  moment(slot?.endTime, "HH:mm:ss")
                    .subtract(300, "seconds")
                    .format("hh:mm A") && (
                  <div
                    onClick={() => {
                      toggleStream(
                        "stop",
                        slot.locationId,
                        slot.id,
                        slot.productSlotLocationId
                      );
                      setTimeout(() => {
                        clearTimeout(timeoutId);
                        setSlot(priestSlotsData[1]);
                      }, 300);
                    }}
                  >
                    Next
                    <img
                      src={SD_Right_Arrow_Blue}
                      alt=""
                      className="ml-5 border-c align-items"
                      height={"20px"}
                      style={{ padding: "5px 7px", borderRadius: "3px" }}
                    />
                  </div>
                )}
            </div>

            <>
              {" "}
              <video
                id="remoteVideo"
                controls
                autoPlay
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                poster={baseBannerBg}
              ></video>
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "20px",
                }}
              >
                {streamError
                  ? "Stream not accessible. Please reconect the player after few seconds. if persists contact your administrator."
                  : ""}
              </p>
              <div className="controls" style={{ marginTop: "10px" }}>
                {!slot ? (
                  <p style={{ textAlign: "center" }}>
                    Select a Slot to start a stream
                  </p>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        !streamError && setMute((state) => !state);
                      }}
                    >
                      <img
                        src={c_microphone}
                        alt="M"
                        className={streamError ? "grey-out" : ""}
                      />
                      {mute ? "Un Mute" : "Mute"}
                    </div>
                    <div
                      onClick={() => {
                        !streamError && websocketConnected
                          ? toggleStream(
                              slot?.isStreamEnabled ? "stop" : "start",
                              slot?.locationId,
                              slot?.id,
                              slot?.productSlotLocationId
                            )
                          : "";
                      }}
                    >
                      <img
                        src={c_video}
                        alt="M"
                        className={streamError ? "grey-out" : ""}
                      />
                      {playLoading
                        ? "Loading.."
                        : slot?.isStreamEnabled
                        ? "Stop Video"
                        : "Start Video"}
                    </div>
                    <div
                      onClick={() => {
                        toast.error(
                          "Record services are not enabled in the media server"
                        );
                        !streamError && setRecord((state) => !state);
                      }}
                    >
                      <img
                        src={c_radio}
                        alt="M"
                        className={streamError ? "grey-out" : ""}
                      />
                      {record ? "Recording" : "Record"}
                    </div>
                  </>
                )}
                {streamError && slot ? (
                  <div
                    onClick={() => {
                      setStreamError(false);
                      toast.success({
                        message: "Stream will be reconnected.",
                      });
                      checkForStreamAndConnect(slot);
                    }}
                  >
                    <img
                      src={refresh}
                      alt="M"
                      style={{ height: "30px", margin: "15px 0px" }}
                    />
                    {"Reconnect"}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          </div>
          <div className="s-right">
            <h5
              style={{
                padding: "0px 20px 20px",
                borderBottom: "1px solid #cbcbcb",
              }}
            >
              Upcoming Poojas
            </h5>
            {/* <hr className="clearfix" style={{ margin: "0" }} /> */}
            <div className="pooja-box">
              {slotLoading ? (
                <div
                  style={{ width: "100%", height: "100%", paddingTop: "50px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      margin: "auto",
                      background: "white none repeat scroll 0% 0%",
                      display: "block",
                      shapeRendering: "auto",
                      borderRadius: "50%",
                    }}
                    width={
                      window.innerWidth < 600
                        ? "90px"
                        : window.innerWidth < 900
                        ? "110px"
                        : "140px"
                    }
                    //
                    height={
                      window.innerWidth < 600
                        ? "90px"
                        : window.innerWidth < 900
                        ? "110px"
                        : "140px"
                    }
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <image
                      overflow="visible"
                      width="85"
                      xlinkHref={SD_Logo_circle}
                      transform="matrix(0.6242 0 0 0.6242 25 18.1847)"
                    ></image>
                  </svg>
                </div>
              ) : (
                priestSlotsData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`poojadiv no-wrap hover ${
                        slot?.id === item.id ? "c-selected" : ""
                      }${
                        timeFunc(item?.startTime, item?.endTime)
                          ? ""
                          : "grayscale"
                      } `}
                      style={{ padding: "10px 20px", alignItems: "flex-start" }}
                      tabIndex="-1"
                    >
                      <div className="flex-column w-100">
                        <div className="flex-a">
                          <img
                            src={SD_Logo_circle}
                            style={{ width: "42px", height: "42px" }}
                          />

                          <div style={{ fontSize: "16px" }}>
                            <span>{item?.name}</span>
                            <br />
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "capitalize",
                              }}
                            >
                              ({item?.type})
                            </span>{" "}
                            <br />
                            <span className="mb-5 f-14">
                              Location: {item?.location}
                            </span>
                            <br />
                            <span className="f-13">
                              {moment(item?.startTime, "HH:mm:ss").format(
                                "hh:mm A"
                              ) ===
                              moment(item?.endTime, "HH:mm:ss").format(
                                "hh:mm A"
                              ) ? (
                                moment(item?.startTime, "HH:mm:ss").format(
                                  "hh:mm A"
                                )
                              ) : (
                                <>
                                  {" "}
                                  {moment(item?.startTime, "HH:mm:ss").format(
                                    "hh:mm A"
                                  )}{" "}
                                  -{" "}
                                  {moment(item?.endTime, "HH:mm:ss").format(
                                    "hh:mm A"
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <button
                        className={`sd-btn-conduct ${
                          timeFunc(item?.startTime, item?.endTime)
                            ? ""
                            : "grayscale"
                        }`}
                        onClick={() =>
                          timeFunc(item?.startTime, item?.endTime) &&
                          handleSlotChange(item, true)
                        }
                      >
                        {item.id === slot?.id ? "Joined" : `Join`}
                      </button>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SdConductAPoojaStart);
