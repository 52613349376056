import React, { useState, useEffect } from "react";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import "../../../Utils/sd-styles/sd-css/animate.min.css";
import {
  loader,
  orange_arrow,
  sd_home_banner_1,
  sd_home_banner_2,
  sd_home_banner_3,
  sd_home_banner_4,
  newspaperOrange,
} from "../../../Assets/imageList";
import Slider from "react-slick";
import { withRouter } from "../../../Utils/with-router/withRouter";

const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateOne = ({ router }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const [loading, setLoading] = useState(true);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    setLoading(true);
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  //-------------------------------------------------------------consts--------------------------------------------------------//

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <div />,
    prevArrow: <div />,
    autoplaySpeed: 15000,
  };
  const styles = {
    slide: {
      position: "absolute",
      width: "100vw",
      height: "100vh",
      zIndex: "1000",
      backgroundColor: "white",
      display: "flex",
      top: "0",
    },
    svg: {
      margin: "auto",
      background: "white none repeat scroll 0% 0%",
      display: "block",
      shapeRendering: "auto",
    },
  };

  //-----------------------------------------------------------consts--------------------------------------------------------//

  return (
    <div className="sd-template-one">
      {loading && (
        <div style={styles.slide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={styles.svg}
            width="150px"
            height="150px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <image
              overflow="visible"
              width="80%"
              height="100%"
              xlinkHref={loader}
            ></image>
          </svg>
        </div>
      )}
      <div className="sd-t1-slider-caraousel">
        <Slider {...settings} dots={true}>
          {/* <div className="item">
            <img src={sd_home_banner_1} alt="banner1" width="100%" />
          </div> */}
          <div className="item">
            <img
              src={sd_home_banner_1}
              alt="banner"
              width="100%"
              onLoad={() => {
                setLoading(false);
              }}
            />
          </div>

          <div className="item">
            <img src={sd_home_banner_2} alt="banner6" width="100%" />
          </div>
          <div className="item">
            <img src={sd_home_banner_3} alt="banner4" width="100%" />
          </div>
          <div className="item">
            <img src={sd_home_banner_4} alt="banner5" width="100%" />
          </div>
        </Slider>
      </div>
      <div className="sd-t1-bottom">
        <p className="sd-orange mr-10">
          <img
            src={newspaperOrange}
            alt="newspaper"
            style={{ marginRight: "10px" }}
          />
          Latest News
        </p>
        <marquee
          id="marquee"
          direction="left"
          className="sd-marquee-text"
          onMouseOver={() => {
            document.getElementById("marquee").stop();
          }}
          onMouseOut={() => {
            document.getElementById("marquee").start();
          }}
        >
          {/* Donors of rooms and cottages must book the accommodation by submitting
          a letter to Online Pooja Booking  | Devasthanam Office on or before 10-02-2022 to
          attend the Maha Shivaratri Brahmotsavams (22-02-2022 to 04-03-2022).
          No rooms will be allotted after 10-02-2022 to any donor. Kindly book
          before the respective date.
           Participate in Poojas of Online Pooja Booking  | Devasthanam from your place through */}
          Please join Paroksha Poojas
          {/* <a
            className="ml-3 mr-3 under"
            style={{ textTransform: "none" }}
            onClick={() => {
              window.open(
                "https://youtube.com/c/AnnavaramDevasthanamLive",
                "_blank"
              );
            }}
          >
            https://youtube.com/c/AnnavaramDevasthanamLive{" "}
          </a> */}
        </marquee>
        <div>
          <button
            onClick={() => {
              router.navigate(
                `/${selectedLanguage}/media-room/press/temple-news`
              );
            }}
          >
            View All <img src={orange_arrow} alt=">" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(TemplateOne);
