import React from "react";
import "../../Utils/sd-styles/sd-misc.scss";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import ParokshaSevaOne from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import { SD_divider } from "../../Assets/imageList";
const Sd_terms_and_conditions = () => {
  return (
    <div>
      {/* <Sd_breadcrumb from="Terms and Conditions"></Sd_breadcrumb> */}
      <ParokshaSevaOne from="Terms and Conditions" />
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          {/* <h2></h2> */}
          <div className="top white-bc">
            <h1>Terms and Conditions</h1>
            <img
              src={SD_divider}
              alt={SD_divider}
              style={{ height: "20px" }}
            ></img>
          </div>
          <></>
          <br /> <br />
          <b>1. Your Acceptance</b>: By using or visiting the web portal
          www.devasthanam.co.in and Sarvadevasthanam Hindu Charitable Trust
          mobile application or any of Sarvadevasthanam Hindu Charitable Trust
          mobile applications, products, software, data feeds, and services
          provided to you on, from, or through the www.devasthanam.co.in website
          or application services (collectively the "Service") you signify your
          agreement to these terms and conditions (the Terms of Service). If you
          do not agree to any of these terms, please do not use the Service.
          Although we may attempt to notify you when major changes are made to
          these Terms of Service, you should periodically review the most
          up-to-date version available at www.devasthanam.co.in Sarvadevasthanam
          Hindu Charitable Trust may, in its sole discretion, modify or revise
          these Terms of Service and policies at any time, and you agree to be
          bound by such modifications or revisions. Nothing in these Terms of
          Service shall be deemed to confer any third-party rights or benefits.
          <br />
          <br />
          <b>2. Service</b>: These Terms of Service apply to all Devotees using
          the Services of Sarvadevasthanam Hindu Charitable Trust, including the
          using the services online on website/mobile platforms. The Service
          includes all aspects of Sarvadevasthanam Hindu Charitable Trust,
          including but not limited to all purchase of Pratyaksha Pooja Tickets,
          Paroksha Pooja Tickets, Donations and services offered via the
          www.devasthanam.co.in website or mobile application/s platforms to the
          Devotees.
          <br />
          <br />
          <b>3. Eligibility</b>: Transaction on the www.devasthanam.co.in
          website or mobile application/s platforms is available only to persons
          who can form legally binding contracts under Law. Persons who are
          "incompetent to contract" within the meaning of the respective law of
          the territory including un-discharged insolvent etc. are not eligible
          to use the Platform. If you are a minor (As per jurisdiction of your
          country)/under the age of 18 years, you may use the Platform or access
          content on the Platform only under the supervision and prior consent/
          permission of a parent or legal guardian. As a minor if you wish to
          transact on the Platform, such transaction on the Platform may be made
          by your legal guardian or parents. www.devasthanam.co.in reserves the
          right to refuse to provide you with services on the Platform if it is
          brought to Sarvadevasthanam Hindu Charitable Trust’s notice or if it
          is discovered that You are under the age of 18 years and transacting
          on the www.devasthanam.co.in website or mobile application/s
          platforms.
          <br />
          <br />
          <b>4. Services</b>:<br />
          4.1 Purchase of Pratyaksha Pooja Tickets : Devotees who are unable to
          come to Temple and perform Pooja at Temple can witness the Pooja/Homa
          from their respective location through Live streaming on the website.
          It is performed by one priest to one devotee.
          <br />
          4.2 Purchase of Paroksha Pooja Booking : Devotees who are unable to
          come to Temple and perform Pooja at Temple can witness the Pooja/Homa
          from their respective location through Live streaming on the website.
          It is performed by one priest to many devotees.
          <br />
          4.3 Donations : Devotees can donate to temple hundi or trust through
          the website.
          <br />
          <br />
          <b>5. Media Room</b>: The Website/Mobile Platform exhibit the
          following, Media Kit, Photo Gallery, Video Gallery, Events Information
          including upcoming events, Publications, News, Press Releases, Press
          Coverage, Media Queries and Newsletter, Daily Updates, Temple Diary,
          e-Library (Publications & CDs), e-Tenders / Auctions, RTI ACT,
          Subscribe Newsletter link etc.
          <br />
          <br />
          <b>7. Devotee Responsibilities and Assumption of Risk</b>: Devotee/s
          acknowledge that many activities carry inherent risks and agree that,
          to the maximum extent permitted by applicable law, Devotee/s assume
          the entire risk arising out of your access to and use of the
          Sarvadevasthanam Hindu Charitable Trust website and mobile Platform
          and any Content, including devotee’s participation in any Experience,
          use of any other Service/s.
          <br />
          <br />
          <b>8. Reporting Violations</b>: If Devotee believe that there is an
          imminent risk of harm to a person or property, Devotee should
          immediately contact Sarvadevasthanam Hindu Charitable Trust before
          approaching any other authorities. In addition, if Devotee believe
          that any devotee/other person violated our Standards and or terms and
          conditions, Devotee should report concerns to Sarvadevasthanam Hindu
          Charitable Trust at contact@devasthanam.co.in
          <br />
          <br />
          <b>9. Copyright Notice</b>: This website including the pictures, art
          work, software, 3D photograph etc., included in the media room and
          other places on the website or mobile platform are the copyright
          protected to the Sarvadevasthanam Hindu Charitable Trust. All rights
          are reserved. Sarvadevasthanam Hindu Charitable Trust respects
          devotee/s feedback. Any information, including suggestions, questions,
          comments, creative ideas, graphics, or other materials submitted to
          the Sarvadevasthanam Hindu Charitable Trust, shall be treated as
          non-confidential, unless otherwise stated. Devotee/s agree that
          Devotee/s will not send any copyrighted information to us. Physical
          reprints of material from the site are permitted as long as you
          reference the URL for each original web page and the page title.
          Website content is subject to change without notice and at the sole
          editorial discretion of the Sarvadevasthanam Hindu Charitable Trust.
          Other websites may not copy pages or articles. No artwork or images
          may be copied to another website without prior written permission.
          Sarvadevasthanam Hindu Charitable Trust reserves the right to have any
          website remove a link to Sarvadevasthanam Hindu Charitable Trust site
          for any reason whatsoever. Access to information on the site
          (www.devasthanam.co.in) does not in any manner constitute an offer to
          sell or a solicitation of any offer to buy any of the securities of
          Sarvadevasthanam Hindu Charitable Trust. The information on this site
          is not and is under no circumstances be construed as, an advertisement
          or a public offering of the securities of Sarvadevasthanam Hindu
          Charitable Trust.
          <br />
          <br />
          <b>10. General Obligations</b>: Devotes shall access Sarvadevasthanam
          Hindu Charitable Trust Website/Mobile Platforms only for lawful
          purposes and Devotees shall be responsible for complying with all
          applicable laws, statutes and regulations in connection with the use
          of the Website/Mobile Platform. This Website is for Devotee personal
          and non-commercial use. Devotees shall not modify, copy, distribute,
          transmit, display, perform, reproduce, publish, license, create
          derivative works from, transfer or sell any information, products or
          facilities obtained from this Website. Devotees shall not create a
          hypertext link to the Website/Mobile Platform or "frame" the Website,
          except with the express advance written permission of Sarvadevasthanam
          Hindu Charitable Trust.
          <br />
          <br />
          <b>11. Devotees Must Keep Their Passwords Private</b>: Devotees are
          required to keep their account passwords confidential and not share
          this information with others. Security of Devotee accounts will not be
          the responsibility of Sarvadevasthanam Hindu Charitable Trust in case
          password is not kept confidential by Devotee.
          <br />
          <br />
          <b>12. Corrections / Updating Personal Information</b>: Devotees must
          provide accurate and correct contact information (name, postal
          address, e-mail address, phone number etc). All further communication
          from Sarvadevasthanam Hindu Charitable Trust to Devotees will be on
          the address provided. In case of incorrect information provided by
          Devotee, Sarvadevasthanam Hindu Charitable Trust will not be
          responsible for any failure of communication.
          <br />
          <br />
          <b>13. Use of the Online facilities</b>: Devotees may only use this
          facility to make legitimate bookings/reservations i.e., Devotees may
          not use this facility to book facilities for the purpose of commercial
          resale and profit. Without limitation, any speculative, false or
          fraudulent reservation or any reservation in anticipation of demand is
          prohibited.
          <br />
          <br />
          <b>14. Devotees Responsibility</b>: Devotees confirm that they are the
          authorized holder of the credit card or the original account holder
          used in the transactions Devotees make using the website/platform and
          Devotees alone use the "ID" and "Password" that has been created in
          the website/mobile platform. Sarvadevasthanam Hindu Charitable Trust
          will not be responsible for any financial loss, inconvenience or
          mental agony resulting from misuse of ID/password/credit card
          number/account details number for booking facilities on this
          website/Mobile Platform.
          <br />
          <br />
          <b>15. Limitation of Liability</b>: Sarvadevasthanam Hindu Charitable
          Trust shall not be liable to devotees save as expressly provided for
          in these terms and conditions and shall have no other obligations,
          duties or liabilities whatsoever in contract, tort or otherwise
          arising out of the use of the facility or connection to the
          Website/Mobile Platform. For online payment Sarvadevasthanam Hindu
          Charitable Trust as a merchant shall be under no liability whatsoever
          in respect of any loss or damage arising directly or indirectly out of
          the decline of authorization for any Transaction, on Account of the
          Cardholder having exceeded the preset limit.
          <br />
          <br />
          <b>16. Disclaimer</b>: The content of this website is for information
          purposes only. Every effort is made to ensure the site is up to date
          and accurate, no legal liability or other responsibility is accepted
          by or on behalf of the Sarvadevasthanam Hindu Charitable Trust for any
          errors, omissions, or statements on this site, or any site to which
          these pages connect. The Sarvadevasthanam Hindu Charitable Trust
          accepts no responsibility for any loss, damage or inconvenience caused
          as a result of reliance on such information. The Sarvadevasthanam
          Hindu Charitable Trust cannot control the content or take
          responsibility for pages maintained by external providers. Where we
          provide links to sites, we do not by doing so endorse any information
          or opinions appearing in them. We accept no liability whatsoever over
          the availability of linked pages. The Sarvadevasthanam Hindu
          Charitable Trust reserves the right to refuse the provision of links
          to any external content, for whatever reason deemed fit. Every effort
          is made to keep the website up and running smoothly. However, the
          Sarvadevasthanam Hindu Charitable Trust takes no responsibility for,
          and will not be liable for, the website being temporarily unavailable
          due to technical or other issues. The website contents are subject to
          change.
          <br />
          <br />
          <b>17. Force Majeure</b>: Sarvadevasthanam Hindu Charitable Trust
          shall not be liable for any delay or failure to perform resulting from
          causes outside its reasonable control, including, but not limited to,
          acts of God, natural disasters, war, terrorism, riots, embargoes, acts
          of civil or military authorities, fire, floods, accidents, pandemics,
          epidemics or disease, strikes or shortages of transportation
          facilities, fuel, energy, labor or materials.
          <br />
          <br />
          <b>18. Emails and SMS</b>: Devotees will receive administrative
          communications from Sarvadevasthanam Hindu Charitable Trust using the
          email address or other contact information Devotees provide.
          Enrollment in additional email subscription programs will not affect
          the frequency of these administrative emails, though devotees should
          expect to receive additional emails specific to the program(s) to
          which devotees have opted. Devotees may also receive promotional
          emails from Sarvadevasthanam Hindu Charitable Trust.
          <br />
          <br />
          <b>19. Cautionary Notice</b>: This is to notify that Sarvadevasthanam
          Hindu Charitable Trust is a well-reputed shrine that offers devotional
          services to worshippers globally. We do not solicit or mislead the
          public at any stage of the services processes we offer nor have we
          authorized any agencies/partners to do the same on our behalf.
          <br />
          <br />
          If any potential service/business partner/Individual receives any
          unsolicited or fraudulent communication in the name of
          Sarvadevasthanam Hindu Charitable Trust for fraudulent payment of
          money to obtain special services or any fraudulent contact for other
          services, they are advised not to respond to the same. It has been
          brought to our notice that fake job offers in our administration panel
          and trust in the name of Sarvadevasthanam Hindu Charitable Trust have
          been circulated by some dishonest persons posing themselves as
          employees/representatives/agents of Sarvadevasthanam Hindu Charitable
          Trust with an ulterior motive to earn wrongful gain and/or cheat the
          public at large.
          <br />
          <br />
          Please be advised that anyone corresponding or transacting with such
          individuals and/or agencies in any manner will be doing so at their
          own risks and consequences. Sarvadevasthanam Hindu Charitable Trust
          strongly recommends you should not respond to such solicitations. In
          case you receive any such offer in the name of Sarvadevasthanam Hindu
          Charitable Trust, you are advised to reach out to us at (email) We
          will not be held responsible for any loss or damage suffered by such
          persons, directly or indirectly dealing with such fraudulent
          offers/advertisements(fake email IDs, fraudulent websites, telephone
          calls, SMS, Whatsapp communication, etc). Kindly note that
          Sarvadevasthanam Hindu Charitable Trust will not accept any
          liability/claims for any loss or damage that may be suffered or
          incurred directly or indirectly through correspondence with fraudulent
          individuals or agencies and that such communication should not be
          treated as an offer or representation from Sarvadevasthanam Hindu
          Charitable Trust.
          <br />
          <br />
          <b>20. Contact Us</b>: If you have any questions about these Terms and
          Conditions please email us at contact@devasthanam.co.in
        </div>
      </div>
    </div>
  );
};
export default Sd_terms_and_conditions;
