import React from "react";
import { Helmet } from "react-helmet";
import Sd_About_Shakthi_Pettas from "../../Components/body/sd-about/sd-about-shakthi-pettas";
import SdBreadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
const Sd_Shakthi_Pettas = () => {
  return (
    <div className="w-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title> About Us | Online Pooja Booking | Devasthanam </title>
        <meta
          name="description"
          content="Shakthi Pettas Page of Online Pooja Booking  | Devasthanam"
        />
        <meta
          name="keywords"
          content="Online Pooja Booking  | Devasthanam , Shakthi Pettas"
        />
      </Helmet>
      <SdBreadcrumb from="Shakthi Peethas" />
      <Sd_About_Shakthi_Pettas />
    </div>
  );
};
export default Sd_Shakthi_Pettas;
