import React, { useContext, useEffect, useState } from "react";
import {
  orange_arrow,
  sd_rupee,
  sd_script,
  sd_share,
  sd_wishlist_heart_unactive,
} from "../../../../Assets/imageList";
import { SD_Context } from "../../../../context/sd-context";
// import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
import "../../../../Utils/sd-styles/sd-css/main.css";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import { withRouter } from "../../../../Utils/with-router/withRouter";

const Sd_Dashboard_parokshaseva_online_booking = ({ router }) => {
  //-------------------------------------------------------------useeffect-----------------------------------------------------------//
  //-------------------------------------------------------------states-----------------------------------------------------------//

  const {
    setActive,
    setActivePath,
    parokshaSevaData,
    setCurrentBooking,
    setParokshaSevaData,
    templeData,
  } = useContext(SD_Context);
  const [loading, setLoading] = useState(false);
  const [selectedTemple, setSelectedTemple] = useState({});

  useEffect(() => {
    setActive("online-booking-parokshapooja");
    setActivePath("Onlinebooking-Paroksha Pooja");
  }, []);

  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  React.useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setCurrentBooking(null);
  }, []);

  useEffect(() => {
    setLoading(true);

    if (localStorage.getItem("selected-temple")) {
      setSelectedTemple(localStorage.getItem("selected-temple"));
      getData(localStorage.getItem("selected-temple"));
    } else {
      setSelectedTemple(templeData[0].shortName);
      getData(templeData[0].shortName);
    }
  }, [templeData]);
  //-------------------------------------------------------------useeffect-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const getData = async (templeId) => {
    let data = await PratyakshaSevaService.getAllvalidOnline(templeId);
    if (data) {
      setParokshaSevaData(
        data.filter(
          (item) => item.sevaType === "parokshaSeva" && item.onlineEnabled
        ) ?? []
      );
    }
    setLoading(false);
  };

  const displayFunction = () => {
    if (parokshaSevaData.length === 0) {
      return (
        <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
          <img
            src={sd_script}
            alt="#"
            height="80px"
            width="80px"
            className="mr-5 mb-5 opace"
          />
          We are sorry to say that there are no paroksha poojas available right
          now!. Sorry for the inconvenience.
        </p>
      );
    }
    let array = parokshaSevaData.map((item, i) => {
      if (item?.maxOnlineBookings === 0) {
        return;
      }
      return (
        <div className="seva-div-booking" key={i + "booking-ps"}>
          <img
            className="head-image-booking"
            src={item.image_url}
            alt={"image"}
          />
          <h4>{item?.name}</h4>
          <h5>{item?.description}</h5>

          <div className="sd-timings-booking">
            <div>
              <img
                className="padding-r"
                src={sd_share}
                alt="share"
                onClick={() => {
                  // onShareFunction(
                  //   `https://annavaramdevasthanam.org/${selectedlanguage}/sevas-and-darshanam/paroksha-seva/${data?.name
                  //     ?.replace?.(/\s/g, "-")
                  //     ?.replace?.(/[{()}]/g, "")
                  //     .toLowerCase()}`
                  // );
                }}
              />
              {/* {getImage()} */}
              <img
                className="border-l"
                src={sd_wishlist_heart_unactive}
                alt="active"
                onClick={() => {
                  // removeFavorite(res.id);
                }}
              />
            </div>
          </div>
          <div>
            <p>
              <img src={sd_rupee} alt="Rs." /> {item.price}
            </p>
            <button
              onClick={() => {
                localStorage.setItem("selected-temple", selectedTemple);
                router?.navigate(
                  `/${selectedlanguage}/devotee-app/online-booking/paroksha-pooja/booking/${item?.name
                    ?.replace?.(/\s/g, "-")
                    .toLowerCase()}`
                );
              }}
            >
              Book Now{" "}
              <img className="imgs-booking" src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>
      );
    });
    return array;
    // console.log(array);
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-profile pr-0">
          <h2 className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Paroksha</span> Pooja
            </span>
          </h2>
          <div className="sd-form">
            <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 pr-0">
              <label>
                Select Temple<span className="sd-imp">*</span>
              </label>
              <select
                className={"form-control sd-voilet"}
                style={{ height: "42px" }}
                value={selectedTemple}
                onChange={(e) => {
                  setSelectedTemple(e.target.value);
                  getData(e.target.value);
                }}
              >
                <option style={{ display: "none" }}>Select a Temple</option>

                {templeData?.map((item) => {
                  return (
                    <option key={item.shortName} value={item.shortName}>
                      {item.name}-({item.shortName}){" "}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="sd-seva-booking">{displayFunction()}</div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Sd_Dashboard_parokshaseva_online_booking);
