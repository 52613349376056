import React, { createContext, useState } from "react";
import Sd_loading from "../Components/sd-common-components/sd-loading";
import Sd_DashboardService from "../services/sd-dashboard.service";
export const SD_Context = createContext();
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import UserService from "../services/sd-user.service";
import { API_URL } from "../Utils/sd-axios/sd-api-url";
import CommsService from "../services/sd-comms.service";
import moment from "moment";
import { withRouter } from "../Utils/with-router/withRouter";

const SD_Context_Provider = ({ children, router }) => {
  const AddressRegex = /^[#.0-9a-zA-Z\s,-/]+$/;

  const [activeTopHeader, setActiveTopHeader] = useState("SrisailaTV");
  const [activeDownHeader, setActiveDownHeader] = useState("");
  const [currentDashboard, setCurrentDashboard] = useState("Srisailam");
  const [userData, setUserData] = useState({});
  const [registerDetails, setRegisterdetails] = useState({});
  const [parokshaSevaData, setParokshaSevaData] = useState([]);
  const [pratyakshaSevaData, setPratyakshaSevaData] = useState([]);
  const [donationData, setDonationData] = useState([]);
  const [currentBookingDonation, setcurrentBookingDonation] = useState(null);
  const [currentDashbaordLower, setCurrentDashboardLower] = useState("");
  const [fav, setFav] = useState([]);
  const [cbSrisailaPrabha, setCbSrisailaPrabha] = useState(null);
  const [currentBookingPratyaksha, setcurrentBookingPratyaksha] =
    useState(null);
  const templeID = "APEDP";
  const [currentBookingDarshananam, setcurrentBookingDarshananam] =
    useState(null);
  const [currentBookingAccomadations, setcurrentBookingAccomdations] =
    useState(null);
  const [display, setDisplay] = useState(true);
  const [currentBooking, setCurrentBooking] = React.useState(null);
  const [currentBookingDetails, setCurrentBookingDetails] =
    React.useState(null);
  const [devoteeList, setDevoteeList] = useState(null);
  const [publicationData, setPublicationData] = useState([]);
  const [visitorCount, setVisitorCount] = useState({});
  const [language, setlanguage] = useState({ desc: "ENGLISH", code: "en-in" });
  const [active, setActive] = useState("devotee-dashboard");
  const [activePath, setActivePath] = useState("Devotee-dashboard");
  const [darshanamData, setDrashanamData] = useState([]);
  const api_url = API_URL;
  const [loading, setLoading] = useState(false);
  const [templeData, setTempleData] = useState([]);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentPageFlag, setPaymentPageFlag] = useState(false);
  const [priestSlotsData, setPriestSlotsData] = useState([]);

  const logout = async () => {
    try {
      setLoading(true);
      await Sd_DashboardService.logout();
      localStorage.clear();
      setCurrentBooking(null);
      setCurrentBookingDetails(null);
      setCbSrisailaPrabha(null);
      setcurrentBookingAccomdations(null);
      setDevoteeList(null);
      setcurrentBookingPratyaksha(null);
      setcurrentBookingDonation(null);
    } catch (e) {
      setLoading(false);
      // console.log(object);
    }
    setLoading(false);
  };

  function removeExpiredSlots(slots) {
    const currentTime = moment();

    // Filter out the slots whose end time has not passed
    const filteredSlots = slots.filter((slot) => {
      const endTime = moment(
        `${moment().format("YYYY-MM-DD")}T${
          slot.productSlotLocation.productSlot.endTime.split("+")[0]
        }`
      );
      if (slot.date === moment().format("YYYY-MM-DD")) {
        return endTime.isAfter(currentTime);
      } else {
        return true;
      }
    });

    return filteredSlots;
  }

  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return (num / 1000).toFixed(2) + "K"; // convert to K for number from > 1000 < 1 million
    }
    if (num > 100000 && num < 10000000) {
      return (num / 100000).toFixed(2) + "L"; // convert to K for number from > 1000 < 1 million
    } else if (num > 10000000) {
      return (num / 10000000).toFixed(2) + "Cr"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }
  const typeFunc = (type) => {
    return type?.replace("Seva", " Pooja");
  };
  const getFavorities = async () => {
    if (localStorage.getItem("accessToken")) {
      // setLoading(true);
      try {
        let res = await Sd_DashboardService.get_favorities("APEDP");
        setFav(res?.items ?? []);
        return;
      } catch (e) {
        // console.log(e);
        // return new Error();
      }
    } else {
      return;
    }
  };

  const addFavorite = async (data, type) => {
    if (localStorage.getItem("accessToken")) {
      try {
        let datas = {
          type: type,
          itemId: data.id,
        };
        let res = await Sd_DashboardService.add_Favorite(datas, "APEDP");
        toast.success(`Added ${data.name} Favourites updated!!`);
        await getFavorities();
        // console.log(res);
        // return "ok";
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
      }
    } else {
      return "redirect";
      // toast.error("Please login to add favorities");
    }
  };

  const getVisitors = async () => {
    try {
      let res = await UserService.getVisitors(templeID);
      setVisitorCount(res);
    } catch (e) {
      // toast.error(e?.message);
    }
    // console.log(res);
  };

  const removeFavorite = async (id) => {
    if (localStorage.getItem("accessToken")) {
      try {
        await Sd_DashboardService.remove_Favorite(id, "APEDP");
        toast.success(`Removed item from Favourites!`);

        await getFavorities();
        // console.log(res);
      } catch (e) {
        if (e.message === "Session Expired") {
          logout();
          router?.navigate(`/`);
        }
      }
      // console.log(e);      }}
    } else {
      toast.error("Please login to add favorities");
    }
  };
  const getTempleData = async () => {
    try {
      let data = await CommsService.getAllTemples();
      setTempleData(data);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  React.useEffect(() => {
    // getFavorities();
  }, [localStorage.getItem("accessToken")]);
  React.useEffect(() => {
    getTempleData();
  }, []);

  return (
    <SD_Context.Provider
      value={{
        getTempleData,
        templeData,
        templeID,
        cbSrisailaPrabha,
        numFormatter,
        setCbSrisailaPrabha,
        getVisitors,
        publicationData,
        setPublicationData,
        visitorCount,
        setVisitorCount,
        darshanamData,
        setDrashanamData,
        donationData,
        setDonationData,
        addFavorite,
        removeFavorite,
        getFavorities,
        loading,
        setLoading,
        devoteeList,
        setDevoteeList,
        currentBookingPratyaksha,
        setcurrentBookingPratyaksha,
        currentBookingAccomadations,
        setcurrentBookingAccomdations,
        currentBookingDarshananam,
        setcurrentBookingDarshananam,
        display,
        setDisplay,
        currentBookingDonation,
        setcurrentBookingDonation,
        activePath,
        setActivePath,
        active,
        setActive,
        currentBooking,
        setCurrentBooking,
        currentBookingDetails,
        setCurrentBookingDetails,
        currentDashbaordLower,
        setCurrentDashboardLower,
        parokshaSevaData,
        setParokshaSevaData,
        paymentData,
        setPaymentData,
        language,
        setlanguage,
        registerDetails,
        setRegisterdetails,
        activeTopHeader,
        setActiveTopHeader,
        activeDownHeader,
        setActiveDownHeader,
        currentDashboard,
        setCurrentDashboard,
        userData,
        setUserData,
        api_url,
        pratyakshaSevaData,
        setPratyakshaSevaData,
        fav,
        setFav,
        logout,
        AddressRegex,
        paymentPageFlag,
        setPaymentPageFlag,
        priestSlotsData,
        setPriestSlotsData,
        removeExpiredSlots,
        typeFunc,
      }}
    >
      {loading ? <Sd_loading /> : children}
    </SD_Context.Provider>
  );
};

export default withRouter(SD_Context_Provider);
