import React from "react";
import { Helmet } from "react-helmet";
import SdBreadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import SdDonorMainComponent from "./sd-donors-main-component";
const Sd_donors = () => {
  return (
    <div className="w-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title> Donors | Online Pooja Booking | Devasthanam </title>
        <meta
          name="description"
          content="Donors Page of Online Pooja Booking  | Devasthanam"
        />
        <meta
          name="keywords"
          content="Online Pooja Booking  | Devasthanam , Donors"
        />
      </Helmet>
      <SdBreadcrumb from="Donors" />
      <SdDonorMainComponent />
    </div>
  );
};
export default Sd_donors;
