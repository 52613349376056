import React, { useEffect, useState } from "react";
import {
  SD_divider,
  vamshi,
  killi,
  siva,
  abhilash,
  aboutUs,
  aboutUsDev,
  Amit_Gupta,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";
import { withRouter } from "../../../Utils/with-router/withRouter";

const Sd_about_main_component = ({ router }) => {
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  return (
    <div className="sd-about-one">
      <div
        className="sd-about-one-bottom flex-column"
        style={{ alignItems: "center" }}
      >
        <div className="sd-about-one-bottom-top">
          <h1 style={{ fontWeight: "600", fontSize: "30px" }}>About Us</h1>
          <img
            style={{ height: "20px" }}
            src={SD_divider}
            alt={"divider"}
          ></img>
        </div>
        <h3
          style={{
            fontWeight: "600",
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "16px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          SarvaDevasthanam Hindu Charitable Trust embodies a profound commitment
          to serving the spiritual needs of Hindus worldwide. It offers a
          gateway to over 12 Jyotirlingas, 18 Sakthi Peethas, 4 Chardhams and
          other sacred Devasthanams in India, accessible through live streaming
          and e-worship, ensuring that devotees, regardless of their physical
          presence, can connect with the divine and partake in religious
          rituals.
        </h3>
        <h3 style={{ fontSize: "14px", maxWidth: "800px" }}>
          With a vision of uniting Hindus globally and simplifying holy
          pilgrimages, the platform strives to keep the Hindu faith relevant in
          the lives of present and future generations.
        </h3>
        <br />
        <br />
        <img src={aboutUs} alt="" style={{ maxWidth: "970px" }} /> <br /> <br />
        <div className="sd-about-one-bottom-top">
          <h1 style={{ fontWeight: "600", fontSize: "30px" }}>
            Why Devasthanam
          </h1>
          <img
            style={{ height: "20px" }}
            src={SD_divider}
            alt={"divider"}
          ></img>
        </div>
        <div className="sd-about-one-bottom-bottom">
          <div className="sd-abb-one">
            <div className="top">
              <img src={aboutUsDev} alt="" />
            </div>
            <div className="bottom">
              <div>
                <b>Global Unity :</b> We believe that faith knows no boundaries.
                Our platform is designed to bring Hindus from all corners of the
                globe together, fostering unity through shared devotion.
              </div>
              <div>
                <b>Access and Convenience :</b> Devasthanam eliminates the
                limitations of distance. Whether you’re miles away or right next
                to a temple, our services ensure that you can engage in
                spiritual practices with ease.
              </div>
              <div>
                <b>Spiritual Enrichment :</b> Our aim is not just to provide
                services but to enrich your spiritual journey. Each interaction
                with our platform is intended to deepen your connection with the
                divine and enhance your understanding of the rituals.
              </div>
            </div>
          </div>
          <div className="sd-abb-two">
            <h2>Join Us in the Spiritual Journey</h2>
            <p>
              Embark on a path of devotion and unity by becoming a part of
              Devasthanam. Whether you choose to virtually witness the rituals
              or immerse yourself in the physical experience, we're here to
              guide you towards a deeper connection with the divine and a
              stronger bond with the global Hindu community.
            </p>
          </div>
          <div className="sd-about-one-bottom-top mt-60 mb-30">
            <h1 style={{ fontWeight: "600", fontSize: "30px" }}>Our Team</h1>
            <img
              style={{ height: "20px" }}
              src={SD_divider}
              alt={"divider"}
            ></img>
          </div>
          <div className="sd-abb-three">
            <div>
              <img src={Amit_Gupta} alt="" />
              <h2>Amit</h2>
              <p>Director</p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                console.log("first");
                router?.navigate(`/${selectedLanguage}/about/CMD`);
              }}
            >
              <img src={killi} alt="" />
              <h2>Satya Prasad Killi</h2>
              <p>CMD</p>
            </div>
          </div>
          <div className="sd-about-one-bottom-top mt-60 mb-30">
            <h1 style={{ fontWeight: "600", fontSize: "30px" }}>
              Overseas Team
            </h1>
            <img
              style={{ height: "20px" }}
              src={SD_divider}
              alt={"divider"}
            ></img>
          </div>
          <div className="sd-abb-three mb-60">
            <div>
              <img src={siva} alt="" />
              <h2>Siva Rama Murthy Chemudupaty</h2>
              <p>Country Head, USA</p>
            </div>
            <div>
              <img src={killi} alt="" />
              <h2>Satya Prasad Killi</h2>
              <p>Country Head, UK & Europe</p>
            </div>
            <div>
              <img src={abhilash} alt="" />
              <h2>Abhilash Konatala</h2>
              <p>Country Head, Canada</p>
            </div>
          </div>
          {/* <div className="sd-about-seva-div">
            <div className="sd-top-image">
              <img src={sd_temple_icon} alt="" />
              <h4>
                The <br />
                Temple
              </h4>
            </div>
            {renderLinks("the-temple", "about/the-temple")}
          </div>
          <div className="sd-about-seva-div">
            <div className="sd-top-image">
              <img className="sd-spcl" src={sd_temple_book} alt="" />
              <h4>
                Temple <br /> Story
              </h4>
            </div>
            {renderLinks("temple-story", "about/the-temple-history")}
          </div>
          <div className="sd-about-seva-div">
            <div className="sd-top-image">
              <img src={sd_general_indo} alt="" />
              <h4>
                General <br /> Information
              </h4>
            </div>

            {renderLinks("general-information", "about/general-information")}
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default withRouter(Sd_about_main_component);
