import React, { useState } from "react";
import { useContext } from "react";
import { SD_Context } from "../../context/sd-context";
import "../../Utils/sd-styles/templeOverlay.scss";
import { withRouter } from "../../Utils/with-router/withRouter";
// import { createEvent } from "@testing-library/react";

const TmsTempleOverlay = ({
  searchtemple,
  router,
  selectedLanguage,
  setTempleSearch,
}) => {
  const { templeData } = useContext(SD_Context);
  // const [page, setPage] = useState(1);
  const [alpaFilter, setAlphaFilter] = useState("");
  const [category, setCategory] = useState("All");
  const filter = [
    "All",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const catgry = ["6A", "6B", "6C"];
  return (
    <div id="tms-overlay">
      {/* <SdHomeComponentSlider
        grayscale={true}
        onClick={() =>
          (document.getElementById("tms-overlay").style.display = "none")
        }
      /> */}
      <div className="temple-list" style={{ marginTop: "40px" }}>
        <div className="temples">
          <h2>All Temples</h2>{" "}
          <button
            onClick={() => {
              setTempleSearch("");
              document.getElementById("tms-overlay").style.display = "none";
            }}
          >
            Close
          </button>
        </div>
        <div className="filterAlpha">
          {filter?.map((item, i) => {
            return (
              <div
                key={i}
                className={
                  item === alpaFilter
                    ? "alpahabetsActive"
                    : item === "All"
                    ? alpaFilter === ""
                      ? "alpahabetsActive"
                      : "alpahabets"
                    : "alpahabets"
                }
                onClick={() => {
                  setAlphaFilter(item === "All" ? "" : item);
                  setCategory(item === "All" ? "All" : category);
                }}
              >
                <p>{item}</p>
              </div>
            );
          })}
        </div>
        <div className="filterAlpha">
          {catgry?.map((item, i) => {
            return (
              <div
                key={i}
                className={
                  item === category
                    ? "alpahabetsActive"
                    : item === "All"
                    ? category === ""
                      ? "alpahabetsActive"
                      : "alpahabets"
                    : "alpahabets"
                }
                onClick={() => {
                  setCategory(item === "All" ? "All" : item);
                }}
              >
                <p>{item}</p>
              </div>
            );
          })}
        </div>
        <hr />
        <div className="temple-list-names">
          {templeData
            ?.filter((item) =>
              item?.name?.toLowerCase().includes(searchtemple?.toLowerCase())
            )
            ?.filter((item) =>
              item?.name[0]?.toLowerCase().includes(alpaFilter?.toLowerCase())
            )
            ?.map((item, i) => {
              let element = (
                <p
                  key={i + "temple-list"}
                  style={{ cursor: "pointer", color: "#181818" }}
                  onClick={() => {
                    setTempleSearch("");
                    document.getElementById("tms-overlay").style.display =
                      "none";
                    router?.navigate(
                      `/${selectedLanguage}/temples/${item?.shortName}/templeinfo`
                    );
                  }}
                >
                  <span></span>
                  {item.name}-({item.shortName})
                </p>
              );
              if (category !== "All") {
                if (item?.templeCategory === category) {
                  return element;
                }
              } else {
                return element;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default withRouter(TmsTempleOverlay);
