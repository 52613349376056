import React from "react";
import { Helmet } from "react-helmet";
import SD_Paroksha_Seva_One from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import Sd_about_main_component from "../../Components/body/sd-about/sd-about-main-component";
import Sd_About_Char_Dham from "../../Components/body/sd-about/sd-about-char-dham";
import SdBreadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
const Sd_Char_Dham = () => {
  return (
    <div className="w-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title> About Us | Online Pooja Booking | Devasthanam </title>
        <meta
          name="description"
          content="Char Dham Page of Online Pooja Booking  | Devasthanam"
        />
        <meta
          name="keywords"
          content="Online Pooja Booking  | Devasthanam , Char Dham"
        />
      </Helmet>
      <SdBreadcrumb from="Char Dham" />
      <Sd_About_Char_Dham />
    </div>
  );
};
export default Sd_Char_Dham;
