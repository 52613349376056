import React from "react";
import { SD_Logo_circle } from "../../Assets/imageList";
import { withRouter } from "../../Utils/with-router/withRouter";
const Sd_Reload = ({ router }) => {
  return (
    <div
      style={{
        textAlignLast: "center",
        padding: "5vw",
      }}
    >
      <img
        src={SD_Logo_circle}
        alt="logo"
        height={window.innerWidth < 650 ? "70px" : "100px"}
        style={{
          border: "2px solid orange",
          borderRadius: "50%",
        }}
      />
      <br />

      <p style={{ marginTop: "10px" }}>
        {" "}
        Oops!! Looks like something went wrong
      </p>

      <p style={{ color: "#181818" }}>
        Click here to{" "}
        <a
          href="/"
          style={{ color: "#2f3193", fontWeight: "600" }}
          onClick={() => router?.navigate(`/`)}
        >
          Reload
        </a>
      </p>
    </div>
  );
};
export default withRouter(Sd_Reload);
