import React, { useState, useContext, useEffect, useRef } from "react";
import {
  sd_btn_loading,
  sd_dashboard_dob,
  SD_Dropdown_Arrow_icon,
  SD_Right_Arrow_white,
  sd_script,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-css/main.css";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";

import "../../../../Utils/sd-styles/sd-dashboard.scss";
import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";

import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import DatePicker from "react-datepicker";
import moment from "moment";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import PhoneInput from "react-phone-input-2";
import { fnCalculateAge } from "../../../../Utils/sd-utility-functions/utility-functions";
import { bookingTimeIsWithinProductTime } from "../../../../Utils/sd-slot-management/slot-management";
import { withRouter } from "../../../../Utils/with-router/withRouter";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
// import PhoneInput from "react-phone-input-2";

const Sd_dashboard_parokshaseva_seva_details = ({ router }) => {
  const [loading, setLoading] = useState(true);
  const {
    setActivePath,
    setActive,
    setCurrentBooking,
    currentBooking,
    userData,
    devoteeList,
    logout,
    // AddressRegex,
    templeData,
  } = useContext(SD_Context);

  const ref = useRef(null);
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [noPersons, setNoperons] = useState(1);
  const [countArray, setCountArray] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [autoFill, setAutoFill] = useState("none");
  const [parokshaSevaData, setParokshaSevaData] = useState([]);
  const [personStates, setPersonStates] = useState([
    {
      id: 1,
      name: "",
    },
  ]);
  const [err, setErr] = useState([]);
  const [totalCost, setTotalCost] = useState("");
  const [show, setShow] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [gothram, setGothram] = useState("");
  // const [abhishekamType, setAbhishekamType] = useState("single");
  const [slotsData, setSlotsData] = useState([]);
  const [highlightDates, setHighlightDates] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);
  // const [address1, setAddress1] = useState("");
  // const [address2, setAddress2] = useState("");
  // const [city, setCity] = useState("");
  // const [country, setCountry] = useState("India");
  // const [state, setState] = useState("");
  // const [zip, setZip] = useState("");
  const [selectedTemple, setSelectedTemple] = useState("");

  const [mobile, setMobile] = useState("91");
  const termsList = [
    `Paroksha Pooja will be performed at Devasthanam with Devotee Gothra Namam read out in the name of those who booked the Pooja online.`,
    `After offering Pooja, Prasadham will be sent to Devotee’s Postal Address, which was filled out while online booking by the devotee.`,
    `Paroksha Pooja is solely an online Pooja and pilgrims are not allowed to attend Pratyaksha Pooja at the templewith Paroksha Pooja tickets.`,
    `Devotees can witness the live telecast of Puja/Homa from their respective location through Srisaila Tv via YouTube, Facebook.`,
    `Bank Charges or Handling charges if any shall be payable by devotee only.`,
  ];

  useEffect(() => {
    setActive("online-booking-parokshapooja-enter-details");
    setActivePath("Onlinebooking-Paroksha Pooja-Enter Details");
    setLoading(true);
    if (
      localStorage.getItem("selected-temple") ||
      currentBooking?.selectedTemple
    ) {
      setSelectedTemple(
        localStorage.getItem("selected-temple") ??
          currentBooking?.selectedTemple
      );
      getData(
        localStorage.getItem("selected-temple") ??
          currentBooking?.selectedTemple
      );
    } else {
      setSelectedTemple(templeData[0].shortName);
      getData(templeData[0].shortName);
    }
    localStorage.removeItem("payment-context");
  }, []);

  useEffect(() => {
    listFunction();
  }, [noPersons]);

  useEffect(() => {
    if (selectedArray && selectedArray[0]?.id) {
      getQuota(selectedArray[0]);
    }
  }, [selectedArray]);
  //----------------------------------------------------------------useeeffect------------------------------------------------------------------//

  //----------------------------------------------------------------functions------------------------------------------------------------------//

  const getData = async (templeID) => {
    if (!router.params.type) {
      router?.navigate(
        `/${selectedlanguage}/devotee-app/online-booking/paroksha-pooja`
      );
    }
    try {
      let data = await PratyakshaSevaService.getAllvalidOnline(templeID);
      data = data?.filter(
        (item) => item?.onlineEnabled && item?.sevaType === "parokshaSeva"
      );
      setParokshaSevaData(data);
      if (currentBooking) {
        setNoperons(currentBooking?.noPersons);
        setPersonStates(currentBooking?.personStates);
        setStartDate(currentBooking?.startDate);
        setSelectedArray(currentBooking?.selectedArray);
        currentBooking?.slot && setSlot(currentBooking?.slot);
        setSlot(currentBooking?.slot);
        setSlotsData(currentBooking?.slotsData);
        setGothram(currentBooking?.gothram);
        setMobile(currentBooking?.mobile);
        // setAddress1(currentBooking?.address1);
        // setAddress2(currentBooking?.address2);
        // setCountry(currentBooking?.country);
        // setState(currentBooking?.state);
        // setCity(currentBooking?.city);
        // setZip(currentBooking?.zip);
        let count = [];
        for (let i = 1; i <= currentBooking?.selectedArray[0].noOfPeople; i++) {
          count.push(i);
        }
        setCountArray(count);
      } else {
        if (data) {
          let finder = data?.find(
            (item) =>
              item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
              router.params.type
          );
          if (finder) {
            if (finder?.noOfPeople) {
              const count = [];
              for (var i = 1; i <= finder?.noOfPeople; i++) {
                count.push(i);
              }
              setCountArray(count);
            }
            setSelectedArray([finder]);
          }
        }
      }

      setLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        router?.navigate(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-pooja`
        );
      }
      setLoading(false);

      return;
    }
  };

  const buttonsArrayFunction = () => {
    return selectedArray?.map((item, i) => {
      return (
        <div
          className="button-div"
          style={{ display: "flex" }}
          key={i + "buttons-prs"}
        >
          <p>
            {item?.name}
            <span className="mr-3">-</span>Rs.
            {item?.price}
          </p>
        </div>
      );
    });
  };

  const listFunctionSevas = () => {
    let temp = parokshaSevaData.filter((item) => item.onlineEnabled);
    let array = temp.map((item, i) => {
      if (!item?.onlineEnabled || item?.maxOnlineBookings === 0) {
        return;
      } else {
        let finder = null;
        for (let i = 0; i < selectedArray.length; i++) {
          if (selectedArray[i].name === item?.name) {
            finder = true;
          }
        }
        return (
          <div
            key={i + "list-of-sevas"}
            className={finder ? "option-class sd-cyan" : "option-class"}
            onClick={() => {
              setTotalCost(item.price);
              setSelectedArray([item]);
              setSlot("");
              setSlotsData([]);
              setStartDate("");
              setShow(false);
            }}
          >
            {item?.name}
            {window.innerWidth < 600 ? <br /> : <span className="mr-3">-</span>}
            <span className="mr-3">-</span> Rs.
            {item.price}
          </div>
        );
      }
    });
    return array;
  };

  const handleChange = (index, event, name) => {
    let data = [...personStates];
    data[index][`${name}`] = event.target.value;
    setPersonStates(data);
  };

  const listFunction = () => {
    if (noPersons === personStates.length) {
      return;
    }
    if (noPersons < personStates.length) {
      let arr = [...personStates];
      let b = personStates.length - noPersons;
      let total = personStates.length;
      while (b !== 0) {
        let data = err.filter((e) => {
          if (e.type !== `${personStates.length}-name`) {
            return e;
          }
        });
        setErr(data);
        arr.pop();
        total--;
        b--;
      }
      setPersonStates([...arr]);
      return;
    }
    if (noPersons > personStates.length) {
      let arr = [...personStates];
      for (let i = personStates.length; i < noPersons; i++) {
        arr.push({
          id: personStates.length + 1,
          name: "",
        });
      }

      setPersonStates([...arr]);
      return;
    }
  };

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  const autoFillFunction = (name, i) => {
    let array = [];
    let userOnClick = () => {
      let data = [...personStates];
      data[i]["name"] =
        userData?.firstName || userData?.lastName
          ? `${userData?.firstName} ${userData?.lastName}`
          : "";
      setGothram(userData?.gothram ?? "");
      setMobile(userData?.phone?.replace("+", "") ?? "");
      // setAddress1(userData?.address1 ?? "");
      // setAddress2(userData?.address2 ?? "");
      // setCity(userData?.city ?? "");
      // setCountry(userData?.country ?? "");
      // setZip(userData?.pinCode ?? "");
      // setState(userData.state ?? "");
      setPersonStates(data);
      let datas = err.filter((e) => {
        if (e.type !== `${i + 1}-name`) {
          return e;
        }
      });
      setErr(datas);
      setAutoFill("none");
    };
    let a = personStates.filter((e) => {
      if (
        e?.name?.toLowerCase() ===
        `${userData?.firstName?.toLowerCase()} ${userData?.lastName?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {/* {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )} */}
            {/* {userData?.gender && <p>Gender: {userData?.gender}</p>} */}
          </p>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = personStates.filter((e) => {
            if (e?.name === `${items?.firstName} ${items?.lastName}`) {
              return e;
            }
          });
          if (a?.length === 0) {
            let onCLick = () => {
              let data = [...personStates];
              data[i]["name"] =
                items?.firstName || items?.lastName
                  ? `${items?.firstName} ${items?.lastName}`
                  : "";
              setPersonStates(data);
              let datas = err.filter((e) => {
                if (e.type !== `${i + 1}-name`) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <p
                key={items.id + "autofill-prs"}
                className="sd-autofill-div"
                onClick={() => {
                  onCLick();
                }}
              >
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Name: {items?.firstName}
                  <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
                </p>
                {/* <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                <p>Gender: {items?.gender}</p> */}
              </p>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };

  const getSlotsDetails = () => {
    if (slotsData?.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData
      ?.filter((item) => moment(startDate).format("YYYY-MM-DD") === item?.date)
      ?.map((item, i) => {
        if (item?.availableQuantity < 1) {
          return;
        }
        return (
          <div
            key={i + "slots-prs"}
            onClick={() => {
              if (item?.availableQuantity < 1) {
                return;
              }
              console.log(item);
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <div
              className="sd-darshanam-box text-center"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "row-reverse",
                borderRadius: "6px",
                border:
                  err.find((e) => e.type === "slot") ||
                  item?.availableQuantity < 1
                    ? "1px solid red"
                    : "2px solid #D0D0D0;",
              }}
              onClick={() => {
                console.log(item);

                if (personStates?.length > item?.availableQuantity) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
            >
              <div
                className="sd-heading-2 "
                style={{
                  color: "#2f3193 !important",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  borderBottom: "0px !important",
                  fontSize: "17px",
                }}
              >
                {window.innerWidth < 650 ? (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot <br /> (
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ===
                    moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ? (
                      moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )
                    ) : (
                      <>
                        {moment(
                          item?.productSlot?.startTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")}{" "}
                        to
                        <span style={{ marginLeft: "5px" }}>
                          {moment(
                            item?.productSlot?.endTime,
                            "HH:mm:ss"
                          ).format("hh:mm A")}
                          )
                        </span>
                      </>
                    )}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot - (
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ===
                    moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                      "hh:mm A"
                    ) ? (
                      moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )
                    ) : (
                      <>
                        {" "}
                        {moment(
                          item?.productSlot?.startTime,
                          "HH:mm:ss"
                        ).format("hh:mm A")}{" "}
                        -
                        {moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                          "hh:mm A"
                        )}
                        )
                      </>
                    )}
                  </span>
                )}
                <br />
                <span
                  style={{
                    textAlign: "left",
                    fontWeight: "500",
                    color: "#171717",
                  }}
                >
                  {" "}
                  Available: {item?.availableQuantity}
                </span>
                <br />
                <span
                  style={{
                    textAlign: "left",
                    fontWeight: "500",
                    color: "#171717",
                  }}
                >
                  ( {item?.productSlot?.location} )
                </span>
              </div>
              <input
                className="sd-darshanam-radio"
                type="radio"
                id={"slot" + 11}
                name=""
                value={slot}
                onClick={() => {
                  if (personStates?.length > item?.availableQuantity) {
                    return;
                  }
                  setSlot(item);
                  if (err?.find((e) => e.type === `slot`)) {
                    let data = err.filter((e) => e.type !== "slot");
                    setErr(data);
                  }
                }}
                style={{ marginTop: "2px" }}
                checked={slot.id === item?.id}
              />
            </div>
          </div>
        );
      });
  };

  const getDates = (startDate, endDate, quotaDates, notAvailableDates) => {
    let date = startDate;
    let dates = [];
    while (date <= endDate) {
      if (
        !quotaDates?.includes(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))
      ) {
        dates.push(new Date(date));
      }
      date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }
    setExcludeDates([...notAvailableDates, ...dates]);
  };

  const getQuota = async (data) => {
    setSlotLoading(true);
    let quotaDates = [];
    let availableDates = [];
    let availableDatesString = [];
    let almostBookedDates = [];
    let almostBookedDatesString = [];
    let notAvailableDates = [];
    let notAvailableDatesString = [];
    try {
      let res = await PratyakshaSevaService.getDailyQuota(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        Number(data?.id),
        data?.onlineEnabled,
        selectedTemple
      );
      const filteredData = res?.filter((item) =>
        bookingTimeIsWithinProductTime(
          item?.date,
          item?.productSlotLocation.productSlot?.startTime,
          data?.minOnlineHours || 1,
          data?.maxOnlineHours
        )
      );
      setSlotsData(filteredData);

      filteredData?.map((item) => {
        quotaDates.push(item?.date);
        if (item?.quantity > 0) {
          if (Number(item?.availableQuantity / item?.quantity) * 100 > 50) {
            console.log(item);
            availableDates.push(new Date(item?.date));
            availableDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              notAvailableDates.splice(
                notAvailableDates.indexOf(new Date(item?.date).toString()),
                1
              );
              notAvailableDatesString.splice(
                notAvailableDatesString.indexOf(
                  new Date(item?.date).toString()
                ),
                1
              );
            }
          } else if (
            Number(item?.availableQuantity / item?.quantity) * 100 <= 50 &&
            Number(item?.availableQuantity / item?.quantity) * 100 > 0
          ) {
            almostBookedDates.push(new Date(item?.date));
            almostBookedDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              notAvailableDates.splice(
                notAvailableDates.indexOf(new Date(item?.date).toString()),
                1
              );
              notAvailableDatesString.splice(
                notAvailableDatesString.indexOf(
                  new Date(item?.date).toString()
                ),
                1
              );
            }
          } else {
            if (
              !availableDatesString.includes(new Date(item?.date).toString())
            ) {
              if (
                !almostBookedDatesString.includes(
                  new Date(item?.date).toString()
                )
              ) {
                notAvailableDates.push(new Date(item?.date));
                notAvailableDatesString.push(new Date(item?.date).toString());
              }
            }
          }
        }
      });
      notAvailableDates?.map((date) => {
        if (
          availableDatesString.includes(date.toString()) ||
          almostBookedDatesString.includes(date.toString())
        ) {
          delete notAvailableDates[notAvailableDates.indexOf(date)];
        }
      });
      setHighlightDates([
        { "react-datepicker__day--highlighted-custom-1": availableDates },
        { "react-datepicker__day--highlighted-custom-2": almostBookedDates },
      ]);
      // console.log({
      //   availableDates,
      //   notAvailableDates,
      //   almostBookedDates,
      // });
      getDates(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        quotaDates,
        notAvailableDates?.filter((item) => item)
      );
      setSlotLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        router?.navigate(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-pooja`
        );
      }
      setLoading(false);

      return;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];

      if (!checkbox) {
        data.push({
          type: "check",
          msg: "Please agree to terms and conditions",
        });
      }
      if (startDate === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (slot === "") {
        data.push({ type: "slot", msg: "Please select a slot" });
      }
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length < 10) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 10) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      }
      // if (city === "") {
      //   data.push({ type: "city", msg: "Please enter a city" });
      // } else if (city.length < 3) {
      //   data.push({ type: "city", msg: "Minimum 3 characters" });
      // }
      // if (address1 === "") {
      //   data.push({ type: "address1", msg: "Please enter a valid address" });
      // } else if (address1.length < 3) {
      //   data.push({ type: "address1", msg: "Minimum 3 characters" });
      // }
      // if (state === "") {
      //   data.push({ type: "state", msg: "Please select your state" });
      // }

      // if (zip.length === "") {
      //   data.push({ type: "zip", msg: "Invalid zipcode! Try again" });
      // }
      // if (country === "") {
      //   data.push({ type: "country", msg: "Please select a country" });
      // }
      personStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            data.push({
              type: `${item["id"]}-${key}`,
              msg: `Invalid ${key}`,
            });
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }
          }
        }
      });
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }

    try {
      setLoading(true);
      let dup = [...personStates];
      let arrayEx = dup.map((e) => {
        return {
          personName: e?.name,
        };
      });
      console.log(slot);
      let dto = {
        sevaSlotId: slot?.productSlotLocation.productSlotId,
        bookingDate: moment(startDate).format("YYYY-MM-DD"),
        phoneNumber: `+${mobile}`,
        personDetails: arrayEx,
        gothram: gothram,
        quotaType: "slot_location_quota",

        // address: capitalize(address1),
        // city: capitalize(city),
        // state: state,
        // country: country,
        // pinCode: Number(zip),
      };
      // if (address2 !== "") {
      //   dto.address2 = capitalize(address2);
      // }

      let schema = {
        cartContains: ["seva"],
        sevaBookings: [dto],
        returnPriceStats: true,
      };

      let res = await ParokshaSevaService.bookings(schema, selectedTemple);
      if (res) {
        let data = {
          selectedArray,
          startDate,
          noPersons,
          personStates,
          slot,
          slotsData,
          mobile,
          res,
          gothram,
          // state,
          // country,
          // address1,
          // address2,
          // zip,
          // city,
          selectedTemple,
        };

        setCurrentBooking(data);
        router?.navigate(
          `/${selectedlanguage}/devotee-app/online-booking/paroksha-pooja/confirm-details`
        );
      }
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        router?.navigate(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-pooja`
        );
      }
      setLoading(false);

      return;
    }
  };

  //----------------------------------------------------------------useeeffect------------------------------------------------------------------//

  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 xs-pr-0 sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700" ref={ref}>
                Paroksha
              </span>{" "}
              Pooja
            </span>
          </h2>
          <div className="clearfix">
            <form className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 pr-0 col-lg-12  xssm-pr-0 pl-0 ">
                <label>
                  Select Temple<span className="sd-imp">*</span>
                </label>
                <select
                  className={
                    err?.find((e) => e.type === `temple`)
                      ? "form-control sd-red"
                      : selectedTemple !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  value={selectedTemple}
                  onChange={(e) => {
                    if (err.find((e) => e.type === `temple`)) {
                      let data = err.filter((e) => e.type !== `$temple`);
                      setErr(data);
                    }
                    setSelectedTemple(e.target.value);
                    getData(e.target.value);
                  }}
                >
                  <option style={{ display: "none" }}>Select a Temple</option>

                  {templeData?.map((item) => {
                    return (
                      <option key={item.shortName} value={item.shortName}>
                        {item.name}-({item.shortName}){" "}
                      </option>
                    );
                  })}
                </select>
                <p className="sd-EM mr-15">
                  {err.find((e) => e.type === `temple`)?.msg}
                </p>
              </div>
              {parokshaSevaData.length !== 0 ? (
                <>
                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                    <label>
                      Select a Pooja<span className="sd-imp">*</span>
                    </label>

                    <div className="select-div">
                      <div
                        className={
                          selectedArray.length > 0
                            ? "constant-top sd-white sd-voilet-border"
                            : "constant-top "
                        }
                      >
                        <div className="sd-buttonArray">
                          {buttonsArrayFunction()}
                        </div>

                        <div className="sd-input-image">
                          <input
                            className={
                              selectedArray.length > 0 ? "sd-white" : " "
                            }
                            type="text"
                            onClick={() => {
                              setShow(!show);
                            }}
                          />
                          <img
                            className={show ? "sd-image-invert" : ""}
                            src={SD_Dropdown_Arrow_icon}
                            alt=""
                            onClick={() => {
                              setShow(!show);
                            }}
                          />
                        </div>
                      </div>

                      {show && (
                        <div
                          className="option-div"
                          style={{ paddingTop: "1vh" }}
                        >
                          {listFunctionSevas()}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr className="clearfix"></hr>
                  <div className="form-group col-xs-12 pl-0 col-sm-12 col-md-12 col-lg-12 mb-0 xssm-pl-0 xssm-pr-0 pr-0 sd-acc-main">
                    <label>
                      Select Date<span className="sd-imp">*</span>
                    </label>

                    <div className={"sd-dob sd-acc-calender"}>
                      <DatePicker
                        allowSameDay={false}
                        style={{
                          width: "90%",
                          border: "none",
                          backgroundColor: "blue",
                        }}
                        inline
                        monthsShown={
                          window.outerWidth < 600
                            ? 1
                            : window.outerWidth > 1100
                            ? 3
                            : 2
                        }
                        highlightDates={highlightDates}
                        excludeDates={excludeDates}
                        minDate={addDays(new Date(), 1)}
                        maxDate={addDays(new Date(), 30)}
                        calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                        clearIcon={null}
                        selected={startDate}
                        onChange={(date) => {
                          if (
                            err.find(
                              (e) => e.type === "date" || e.type === "server"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "date" && e.type !== "server") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setStartDate(date);
                          // setData([]);
                        }}
                      />

                      <div className=" sd-quota">
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "#1d5d90",
                              color: "#1d5d90",
                            }}
                          ></p>
                          Selected
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(0, 207, 0)",
                              color: "rgb(0, 207, 0)",
                            }}
                          ></p>
                          Available
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "orange",
                              color: "orange",
                            }}
                          ></p>
                          Almost Booked
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(201, 0, 0)",
                              color: "rgb(201, 0, 0)",
                            }}
                          ></p>
                          Not Available
                        </div>
                      </div>
                    </div>
                    <p className="sd-EM mr-15">
                      {err.find((e) => e.type === "date")?.msg}
                    </p>
                  </div>
                  <hr className="clearfix"></hr>
                  <div className="sd-profile flex">
                    <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4 xssm-pl-0 xssm-pr-0 pl-0  mb-0">
                      <label>
                        Gothram<span className="sd-imp">*</span>
                      </label>
                      <input
                        maxLength="30"
                        type="text"
                        placeholder="Enter gothram."
                        className={
                          err?.find((e) => e.type === `gothram`)
                            ? "form-control sd-red"
                            : gothram !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={gothram}
                        onChange={(e) => {
                          if (
                            /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                            e.target.value === ""
                          ) {
                            if (err.find((e) => e.type === `gothram`)) {
                              let data = err.filter(
                                (e) => e.type !== `gothram`
                              );
                              setErr(data);
                            }
                            setGothram(e.target.value);
                          }
                        }}
                      />
                      <p className="sd-EM ">
                        {err.find((e) => e.type === `gothram`)?.msg}
                      </p>
                    </div>
                    <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0  ">
                      <label>
                        Number of Person(s)<span className="sd-imp">*</span>
                      </label>
                      <select
                        className={
                          noPersons !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={noPersons}
                        onChange={(e) => {
                          setNoperons(e.target.value);
                        }}
                      >
                        {countArray.map((num) => {
                          return (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pr-0 pr-0">
                      <label>
                        Mobile Number<span className="sd-imp">*</span>
                      </label>

                      <div className={"sd-profile-mobile"}>
                        <PhoneInput
                          inputExtraProps={{
                            autoFocus: true,
                            enableSearch: true,
                            countryCodeEditable: false,
                          }}
                          onlyCountries={["in"]}
                          placeholder="Enter Your 10 digit mobile number"
                          maxLength="15"
                          countryCodeEditable={false}
                          containerClass={
                            err?.find((e) => e.type === "phone")
                              ? "sd-phone-voilet sd-red"
                              : mobile !== ""
                              ? "sd-phone-voilet"
                              : ""
                          }
                          country="in"
                          value={mobile}
                          onChange={(e) => {
                            if (
                              err?.find(
                                (e) => e.type === "phone" || e.type === "common"
                              )
                            ) {
                              let array = err.filter((e) => {
                                if (e.type !== "phone" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(array);
                            }
                            setMobile(e);
                          }}
                        />
                        <p className="d-EM ">
                          {err.find((e) => e.type === "phone")
                            ? err.find((e) => e.type === "phone")?.msg
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  {slotsData?.length !== 0 && startDate ? (
                    <>
                      <hr className="clearfix"></hr>

                      <div
                        className="clearfix sd-darshanam-content"
                        style={{ position: "relative" }}
                      >
                        <br />
                        {getSlotsDetails()}
                        <p
                          style={{
                            position: "absolute",
                            top: "-12px",
                            left: "0px",
                            textAlign: "right",
                            color: "red",
                          }}
                        >
                          {err?.find((e) => e.type === `slot`)?.msg}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      {startDate && !slotLoading ? (
                        <>
                          <hr className="clearfix"></hr>{" "}
                          <div
                            className="clearfix sd-darshanam-content"
                            style={{ position: "relative", color: "red" }}
                          >
                            No Slots available on{" "}
                            {moment(startDate).format("DD-MM-YYYY")}
                          </div>
                        </>
                      ) : (
                        startDate && (
                          <>
                            <hr className="clearfix"></hr>{" "}
                            <div
                              className="clearfix sd-darshanam-content"
                              style={{ position: "relative" }}
                            >
                              Fetching slots...Please wait{" "}
                            </div>
                          </>
                        )
                      )}
                    </>
                  )}
                  <div className="clearfix">
                    <hr className="clearfix"></hr>
                  </div>{" "}
                  <div className="clearfix" key={"personstates-prs"}>
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pl-0 pr-0">
                      {personStates.map((item, i) => {
                        return (
                          <div
                            className={`form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0  ${
                              i % 2 === 0 && "pl-0"
                            }`}
                            key={i + "paroksha_namess"}
                          >
                            <label>
                              {i + 1}.Name<span className="sd-imp">*</span>
                            </label>
                            <input
                              maxLength="20"
                              autoComplete="new-password"
                              onFocus={() => {
                                if (autoFill === `${i}autofill`) {
                                  setAutoFill("none");
                                } else {
                                  setAutoFill(`${i}autofill`);
                                }
                              }}
                              onBlur={() => {
                                if (autoFill === `${i}autofill`) {
                                  setTimeout(() => {
                                    setAutoFill("none");
                                  }, 250);
                                }
                              }}
                              placeholder="Please enter your name"
                              type="text"
                              className={
                                err?.find((e) => e.type === `${i + 1}-name`)
                                  ? "form-control sd-red"
                                  : item?.name !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              value={item?.name}
                              onChange={(e) => {
                                if (
                                  /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                                  e.target.value === ""
                                ) {
                                  if (
                                    err.find((e) => e.type === `${i + 1}-name`)
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-name`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, e, "name");
                                }
                              }}
                            />
                            <p className="sd-EM mr-15 ">
                              {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                            </p>
                            {autoFill === `${i}autofill` &&
                              autoFillFunction(item?.name, i)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div className="clearfix">
                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                      <label>
                        Address Lane 1<span className="sd-imp">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength="50"
                        placeholder="House/building number, street number"
                        className={
                          err.find((e) => e.type === "address1")
                            ? "form-control sd-red"
                            : address1 !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={address1}
                        onChange={(e) => {
                          if (
                            AddressRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) =>
                                  e.type === "address1" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (
                                  e.type !== "address1" &&
                                  e.type !== "common"
                                ) {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setAddress1(e.target.value);
                          }
                        }}
                      />
                      <p className="d-EM mr-15">
                        {err.find((e) => e.type === "address1")
                          ? err.find((e) => e.type === "address1")?.msg
                          : ""}
                      </p>
                    </div>
                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  xssm-pr-0 pr-0 ">
                      <label>Address Lane 2</label>
                      <input
                        type="text"
                        maxLength="50"
                        className={
                          err.find((e) => e.type === "address2")
                            ? "form-control sd-red"
                            : address2 !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        placeholder="building name, street name, etc"
                        value={address2}
                        onChange={(e) => {
                          if (
                            AddressRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) =>
                                  e.type === "address2" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (
                                  e.type !== "address2" &&
                                  e.type !== "common"
                                ) {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setAddress2(e.target.value);
                          }
                        }}
                      />
                      <p className="d-EM mr-15">
                        {err.find((e) => e.type === "address2")
                          ? err.find((e) => e.type === "address2")?.msg
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pr-0 pl-0">
                      <label>
                        City<span className="sd-imp">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength="30"
                        className={
                          err.find((e) => e.type === "city")
                            ? "form-control sd-red"
                            : city !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={city}
                        placeholder="Please enter your city"
                        onChange={(e) => {
                          if (
                            AddressRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) => e.type === "city" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (e.type !== "city" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setCity(e.target.value);
                          }
                        }}
                      />
                      <p className="d-EM mr-15">
                        {err.find((e) => e.type === "city")
                          ? err.find((e) => e.type === "city")?.msg
                          : ""}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pr-0 ">
                      <label>
                        Zipcode<span className="sd-imp">*</span>
                      </label>
                      <input
                        type="text"
                        onWheel={(event) => event.currentTarget.blur()}
                        className={
                          err.find((e) => e.type === "zip")
                            ? "form-control sd-red"
                            : zip !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={zip}
                        maxLength={12}
                        placeholder="Enter your zipcode"
                        onChange={(e) => {
                          if (
                            AddressRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) => e.type === "zip" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (e.type !== "zip" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            if (e.target.value.length < 12) {
                              setZip(e.target.value.toUpperCase());
                            }
                          }
                        }}
                      />
                      <p className="d-EM mr-15">
                        {err.find((e) => e.type === "zip")
                          ? err.find((e) => e.type === "zip")?.msg
                          : ""}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0 ">
                      <label>
                        State<span className="sd-imp">*</span>
                      </label>
                      <RegionDropdown
                        country={country}
                        classes={
                          err.find((e) => e.type === "state")
                            ? "sd-red w-100 sd-border-none"
                            : state !== ""
                            ? " sd-voilet w-100 sd-border-none"
                            : "w-100 sd-border-none"
                        }
                        value={state}
                        showDefaultOption={true}
                        blankOptionLabel="Select a State"
                        onChange={(val) => {
                          if (
                            err.find(
                              (e) => e.type === "state" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "state" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setState(val);
                        }}
                      />
                      <p className="d-EM ">
                        {err.find((e) => e.type === "state")
                          ? err.find((e) => e.type === "state")?.msg
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pl-0 xssm-pr-0 ">
                      <label>
                        Country<span className="sd-imp">*</span>
                      </label>
                      <CountryDropdown
                        value={country}
                        classes={
                          err.find((e) => e.type === "country")
                            ? "sd-red w-100 "
                            : country !== ""
                            ? " sd-voilet w-100 sd-border-none"
                            : "w-100 sd-border-none"
                        }
                        showDefaultOption={true}
                        defaultValue="India"
                        defaultOptionLabel="Select a Country"
                        onChange={(val) => {
                          if (
                            err.find(
                              (e) => e.type === "country" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "country" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setCountry(val);
                        }}
                      />
                      <p className="d-EM mr-15">
                        {err.find((e) => e.type === "country")
                          ? err.find((e) => e.type === "country")?.msg
                          : ""}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0">
                      <label>
                        Mobile Number<span className="sd-imp">*</span>
                      </label>

                      <div className={"sd-profile-mobile"}>
                        <PhoneInput
                          inputExtraProps={{
                            autoFocus: true,
                            enableSearch: true,
                            countryCodeEditable: false,
                          }}
                          onlyCountries={["in"]}
                          placeholder="Enter Your 10 digit mobile number"
                          maxLength="15"
                          countryCodeEditable={false}
                          containerClass={
                            err?.find((e) => e.type === "phone")
                              ? "sd-phone-voilet sd-red"
                              : mobile !== ""
                              ? "sd-phone-voilet"
                              : ""
                          }
                          country="in"
                          value={mobile}
                          onChange={(e) => {
                            if (
                              err?.find(
                                (e) => e.type === "phone" || e.type === "common"
                              )
                            ) {
                              let array = err.filter((e) => {
                                if (e.type !== "phone" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(array);
                            }
                            setMobile(e);
                          }}
                        />
                        <p className="d-EM ">
                          {err.find((e) => e.type === "phone")
                            ? err.find((e) => e.type === "phone")?.msg
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <hr className="clearfix"></hr>
                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                    <p
                      className="ml--15"
                      style={{
                        color: "#181818",
                        fontWeight: "600",

                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Note: Not all Temples ship Prasadham overseas.
                      NRIs(Non-Resident Indians) and Overseas Citizens who wish
                      to book Paroksha Pooja are advised to fill their Indian
                      Address(if any) while booking the Pooja to receive the
                      Prasadham via post.
                    </p>
                    <ol className="sd-terms-list">
                      {selectedArray.find(
                        (item) => item?.live_telecast === false
                      ) ? (
                        <li className="active">
                          <a>
                            Access to live service of{" "}
                            {
                              selectedArray.find(
                                (item) => item?.live_telecast === false
                              )?.name
                            }{" "}
                            is currently not available. However, devotees who
                            have booked the said service may witness their names
                            read out during live Veedasirvachanam.
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>{termsList[0]}</li>
                      {selectedArray.find(
                        (e) =>
                          e?.name === "Nandeeshwara Swamy vari Visesharchana" ||
                          e?.name === "Laksha Kumkumarchana" ||
                          e?.name ===
                            "Sri Bayalu Veerabhadra Swamy Vari Visesharchana" ||
                          e?.name === "Sri Ganapathi Pooja"
                      ) ? (
                        <li>
                          Each Pooja is individually priced. Paroksha Pooja is
                          performed by reciting 2 Gothra Namas per one booking.
                        </li>
                      ) : (
                        <li>
                          Each Pooja is individually priced. Paroksha Pooja is
                          performed by reciting 4 Gothra Namas per one booking.
                        </li>
                      )}

                      {termsList.map((item, i) => {
                        if (i === 0) {
                          return;
                        }
                        return (
                          <li className="active" key={i + "terms-lists"}>
                            <a>{item}</a>
                          </li>
                        );
                      })}
                    </ol>

                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox ml--15 items-center">
                      <input
                        className="mr-5"
                        type="checkbox"
                        name=""
                        value={checkbox}
                        onChange={() => {
                          if (
                            err.find(
                              (e) =>
                                e.type === `checkbox` || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (
                                e.type !== `checkbox` &&
                                e.type !== "common"
                              ) {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setCheckbox(!checkbox);
                        }}
                        checked={checkbox === true ? true : false}
                        style={{ position: "relative" }}
                      />
                      <label
                        className="ml-5"
                        htmlFor=""
                        style={{
                          marginBottom: "0px",
                          position: "relative",
                        }}
                      >
                        I agree to the{" "}
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedlanguage}/legalities/terms-and-conditions`
                            );
                          }}
                        >
                          Terms & Conditions
                        </a>{" "}
                        and the
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedlanguage}/legalities/privacy-policy`
                            );
                          }}
                        >
                          {" "}
                          Privacy Policy
                        </a>
                      </label>
                      <p className="sd-accept">
                        {err?.find((e) => e.type === `checkbox`)?.msg}
                      </p>
                    </div>
                  </div>
                  <hr className="clearfix"></hr>
                  <div className="clearfix mt-50">
                    <a
                      className="sd-btn-orange"
                      onClick={() => {
                        if (!loading) {
                          handleSubmit();
                        }
                      }}
                    >
                      Confirm Booking{" "}
                      {loading ? (
                        <img
                          src={sd_btn_loading}
                          alt=".."
                          className="sd-arrow-btn ml-5 h-20"
                        />
                      ) : (
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      )}
                    </a>
                  </div>
                </>
              ) : (
                <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
                  <img
                    src={sd_script}
                    alt="#"
                    height="80px"
                    width="80px"
                    className="mr-5 mb-5 opace"
                  />
                  We are sorry to say that there are no paroksha poojas
                  available for booking. We regret the inconvenience.
                </p>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_parokshaseva_seva_details);
