const fnCalculateAge = (userDateinput) => {
  let birthDate = new Date(userDateinput);
  let difference = Date.now() - birthDate.getTime();
  let ageDate = new Date(difference);
  let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
  return calculatedAge;
};
const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
};
export { fnCalculateAge, addDays };
