import React, { useEffect, useContext } from "react";
import LoadingContent from "../../../Components/sd-common-components/sd-content-loading";
import { SD_Context } from "../../../context/sd-context";
const Sd_dashboard_ma_settings = () => {
  const { setActive, setActivePath } = useContext(SD_Context);
  useEffect(() => {
    setActive("settings");
    setActivePath("My Account-Settings");
  }, []);
  return (
    <div>
      <LoadingContent />
    </div>
  );
};
export default Sd_dashboard_ma_settings;
