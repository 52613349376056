/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
  // banner2,
  bannerAlt,
  // sd_donation_banner,
  // SD_ganapathi_homam_banner_img,
  // SD_parokshaseva_banner_img,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-parokshaseva.scss";
import Sd_breadcrumb from "../../sd-common-components/sd-breadcrumb";

const SD_Paroksha_Seva_One = ({ from, data }) => {
  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const donationArray = [
    "Annaprasadam",
    "Aagama Patasala",
    "GoSamrakshana",
    "E-Hundi",
    "Pranadana Trust",
  ];

  //-------------------------------------------------------------consts-----------------------------------------------------------//

  return (
    <div className={from !== "Char Dham" ? "sd-Paroksha-one" : ""}>
      {from !== "Char Dham" && (
        <div className="banner" style={{ background: "#D01B22" }}>
          <div className="sd-banner-top">
            <h1>
              {from === "Paroksha-seva" ? (
                <span>
                  Paroksha<span>Pooja</span>
                </span>
              ) : from === "darshanam" ? (
                <span>Darshanam</span>
              ) : from === "donations" ? (
                <span>Donations</span>
              ) : from === "Saswata Seva" ? (
                <span>
                  Saswatha<span>Pooja</span>
                </span>
              ) : from === "Support-Contact Us" ? (
                <span>Contact Us</span>
              ) : from === "faqs" ? (
                "FAQs"
              ) : from === "Support-Facilities to Pilgrims" ? (
                "Facilities to Pilgrims"
              ) : from === "Support-Connectivity" ? (
                "Connectivity"
              ) : from === "Support-Privacy Policy" ? (
                "Privacy Policy"
              ) : from === "Support-Refund Policy" ? (
                "Refund Policy"
              ) : (
                <span>{from} </span>
              )}

              {/* {pratyakshasevaArray.includes(from) ? (
              <span>{from.split("-")[1]}</span>
            ) : (
              "asd"
            )} */}
            </h1>
            <h4>
              {from === "Paroksha-seva"
                ? "Dhamro Rakshathi Rakshithah"
                : from === "darshanam" ||
                  from === "donations" ||
                  donationArray.includes(from)
                ? "Dhamro Rakshathi Rakshithah"
                : "Dhamro Rakshathi Rakshithah"}
            </h4>
          </div>

          <div className="sd-banner-bottom">
            <img src={bannerAlt} alt="icon" />
          </div>
        </div>
      )}
      <Sd_breadcrumb
        data={data}
        from={from === "Paroksha-seva" ? "Paroksha-seva" : from}
      />
    </div>
  );
};
export default SD_Paroksha_Seva_One;
