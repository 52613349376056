import React from "react";
import {
  orange_arrow,
  red_arrow,
  SD_Main_offering,
  SD_pooja_logo,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import { withRouter } from "../../../Utils/with-router/withRouter";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplatwTwo = ({ router }) => {
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  React.useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  return (
    <div className="sd-template-four">
      <div className="sd-t4-box-left">
        <div className="sd-t4-box-left-one">
          <h4>About</h4>
          <p> Devasthanam</p>
        </div>
        <div className="sd-t4-box-left-two">
          <p>
            A Devasthanam is a historically and mythologically significant
            sacred place serving as an important centre of worship, community,
            and spiritual practice.It is the sacred means to connect closely
            with the supreme power through worship. India, the land of
            Devasthanams has 38 most sacred spaces where devotees can go to
            connect with the divine and participate in religious ceremonies.
            <br />
            <br />
            <span style={{ fontWeight: "400", fontSize: "16px" }}>
              Devasthanams are now easy to access and reach for all, including
              those who are unable to attend services in person through live
              streaming services and e-worship.
            </span>
          </p>
        </div>

        <div className="sd-t4-box-left-four">
          <button
            // onClick={() => {
            //   router.navigate(
            //     `/${selectedlanguage}/about/the-temple-history/about-srisailam`
            //   );
            // }}
            style={{ color: "#181818" }}
          >
            Read More{" "}
            <img
              src={red_arrow}
              alt="readmore"
              style={{ filter: "brightness(0)" }}
            />
          </button>
        </div>
      </div>
      <div className="sd-t4-box-right">
        <div className="sd-t4-box-right-two"></div>{" "}
        <div className="sd-t4-box-right-one">
          <img src={SD_pooja_logo} alt="pooja" />
          <h3>Join a Pooja</h3>
          <button
            onClick={() => {
              router.navigate(`/${selectedlanguage}/devotee-app/join-a-pooja`);
            }}
          >
            View <img src={orange_arrow} alt=">" />
          </button>
        </div>
        <div className="sd-t4-box-right-four">
          <img src={SD_Main_offering} alt="last" />
          <h3>Book a Pooja</h3>
          <button
            onClick={() => {
              router.navigate(
                `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja`
              );
            }}
          >
            View <img src={orange_arrow} alt=">" />
          </button>
        </div>
        <div className="sd-t4-box-right-three"></div>
      </div>
    </div>
  );
};
export default withRouter(TemplatwTwo);
