import React, { useState, useEffect, useRef } from "react";
import {
  SD_cart_icon,
  // SD_Print_a_ticket_icon,
  SD_Dropdown_Arrow_icon,
  SD_Logo_circle,
  SD_Sign_In_icon,
  sd_minus_icon,
  sd_plus_icon,
  sd_default_profile_pic,
  sd_devotee_dashboard,
  sd_logout_icon,
  sd_booking_history_icon,
  sd_online_booking_icon,
  SD_search_icon,
  twitter,
  facebook,
  youtube,
  insta,
  aptemplesLogo,
  mainLogo,
  calendar,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-navbar.scss";
import moment from "moment";
import { SD_Context } from "../../context/sd-context";
import Sd_mobile_left_sider from "./sd-mobile-left-sider";
import NavBarData from "../../Utils/data/navbar.data";
import TmsTempleOverlay from "./tms-temple-overlay";
import { withRouter } from "../../Utils/with-router/withRouter";
// import {toast} from "react-toastify";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const New_Nav = React.memo(({ router }) => {
  const {
    setActiveTopHeader,
    activeTopHeader,
    userData,
    language,
    setlanguage,
    setUserData,
    setCurrentDashboardLower,
    currentDashbaordLower,
    logout,
  } = React.useContext(SD_Context);

  //-----------------------------------states-------------------------------------------//
  const [templeSearch, setTempleSearch] = useState("");
  const [sider, setSider] = useState(false);
  const [rightsider, setrightSider] = useState(false);
  const [drop1, setDropOne] = useState(false);
  const [drop2, setDropTwo] = useState(false);
  const [drop3, setDropThree] = useState(false);
  const [visibleBut, setVisibleBut] = useState(false);
  const [drop4, setDropFour] = useState(false);
  const [drop5, setDropFive] = useState(false);
  const [drop6, setDropSix] = useState(false);
  const [drop7, setDropSeven] = useState(false);
  const [drop8, setDropEight] = useState(false);
  const [drop9, setDropNine] = useState(false);
  const [drop10, setDropTen] = useState(false);
  const [drop11, setDrop11] = useState(false);
  const [drop12, setDrop12] = useState(false);
  const [drop13, setDrop13] = useState(false);
  const [drop14, setDrop14] = useState(false);
  const [drop15, setDrop15] = useState(false);

  const [donationDrop, setDonationDrop] = useState("none");

  const refmediaroom = useRef(null);
  const refsupport = useRef(null);
  const [dropRightone, setDropRightOne] = useState(false);
  const [dropRightTwo, setDropRightTwo] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const refContainer = useRef();
  const [time, setTime] = React.useState(
    moment().utcOffset("+5:30").format(`dddd, DD MMMM YYYY - hh:mm A `)
  );

  //-----------------------------------states-------------------------------------------//

  //-----------------------------------useeffects---------------------------------------//

  useEffect(() => {
    const id = setInterval(timer, 60000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    if (document.getElementById("myDIV").scrollTop > 1000) {
      setVisibleBut(true);
    } else {
      setVisibleBut(false);
    }
  });

  useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  useEffect(() => {
    if (router?.location?.pathname?.split?.("/")?.[2] === "temples") {
      setCurrentDashboardLower("about");
    } else if (router?.location?.pathname?.split?.("/")?.[2] === "home") {
      setCurrentDashboardLower("home");
    } else if (
      router?.location?.pathname?.split?.("/")?.[2] === "sevas-and-darshanam"
    ) {
      setCurrentDashboardLower("book-a-pooja");
    } else if (router?.location?.pathname?.split?.("/")?.[2] === "donations") {
      setCurrentDashboardLower("Donations");
    } else if (
      router?.location?.pathname?.split?.("/")?.[2] === "online-booking"
    ) {
      setCurrentDashboardLower("online-booking");
    } else if (router?.location?.pathname?.split?.("/")?.[2] === "support") {
      setCurrentDashboardLower("Support");
    } else if (router?.location?.pathname?.split?.("/")?.[2] === "about-us") {
      setCurrentDashboardLower("About");
    } else if (router?.location?.pathname?.split?.("/")?.[2] === "donors") {
      setCurrentDashboardLower("Donors");
    } else {
      setCurrentDashboardLower("");
    }
  }, [router?.location?.pathname]);

  useEffect(() => {
    refContainer.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [router?.location.pathname]);

  //-----------------------------------useeffects-------------------------------------------//

  const timer = () => {
    let frist = moment()
      .utcOffset("+5:30")
      .format(`dddd, DD MMMM YYYY - hh:mm A `);
    // console.log(frist);
    setTime(frist + "IST");
  };
  const closeModal = () => {
    let documents = document.getElementById("tms-overlay");
    if (documents.style.display === "block") documents.style.display = "none";
  };
  return (
    <div className="sd-navbar-custom" ref={refContainer}>
      <div className="sd-top-nav">
        <div className="sd-top-nav-left">
          <img
            src={mainLogo}
            height="100%"
            onClick={() => {
              router?.navigate(`/${selectedLanguage}/home`);
            }}
          />
        </div>
        <div className="sd-top-nav-right">
          <div className="sd-tnl-top">
            <div className="sd-time-date">
              <img src={calendar} />
              <div className="text-date">
                <p>{moment().format("dddd, D MMM, YYYY - h:mm A")}</p>
                {/* <b>{moment().format("D MMM, YYYY")}</b>
                <b>{moment().format("h:mm")}</b>
                <p>{moment().format("A")}</p> */}
              </div>
            </div>
            <div className="social-handles">
              <img
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/devasthanam.co.in/",
                    "_blank"
                  );
                }}
                src={facebook}
                alt="icon"
              />
              <img
                onClick={() => {
                  window.open("https://twitter.com/DevasthanamCo", "_blank");
                }}
                src={twitter}
                alt="icon"
              />

              <img
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UCBBTjPJ0TLCaLskvFXC7QBQ",

                    "_blank"
                  );
                }}
                src={youtube}
                alt="icon"
              />

              <img
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/devasthanam.co.in/",
                    "_blank"
                  );
                }}
                src={insta}
                alt="icon"
              />
            </div>
            <div className="sd-dropdown-language">
              <button className="sd-dropbtn-language" type="button">
                {language.desc}
              </button>
              <div
                className="sd-dropdown-content-language right-0"
                aria-labelledby="dropdownMenuButton1"
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    setlanguage({ desc: "ENGLISH", code: "en-in" });
                    closeModal();
                  }}
                >
                  ENGLISH
                </a>
              </div>
            </div>
            {/* <div
              className={
                activeTopHeader !== "public-dashboard"
                  ? "sd-inner-div sd-w-24"
                  : "sd-inner-div sd-w-24 sd-selected"
              }
              onClick={() => {
                router?.navigate(`/${selectedLanguage}/devotee/print-a-ticket`);
                setActiveTopHeader("public-dashboard");
              }}
            >
              <img src={public_dashboard_icon} alt="icon" />
              PUBLIC DASHBOARD
            </div> */}
          </div>
          <div className="sd-tnl-bottom">
            <div className="sd-tnlb-top">
              <input
                placeholder="Search Temple..."
                value={templeSearch}
                onFocus={() => {
                  document.getElementById("tms-overlay").style.display =
                    "block";
                }}
                onChange={(e) => setTempleSearch(e.target.value)}
                onBlur={() => {
                  if (templeSearch !== "") {
                    return;
                  } else {
                    document.getElementById("tms-overlay").style.display =
                      "none";
                  }
                }}
              />{" "}
              <img src={SD_search_icon} />
            </div>
            <button
              className={
                activeTopHeader !== "login"
                  ? "sd-tnlb-button"
                  : "sd-tnlb-button sd-selected"
              }
            >
              {" "}
              <img
                src={SD_Sign_In_icon}
                alt="icon"
                height={"18px"}
                style={{ marginRight: "6px" }}
              />{" "}
              {localStorage.getItem("accessToken") && userData?.displayName ? (
                <div className="sd-dropdown-signin ">
                  <span style={{ marginBottom: "2px" }}>
                    {" "}
                    {userData?.displayName !== "" ||
                    userData?.displayName !== null
                      ? userData?.displayName
                      : "User"}
                  </span>
                  <div
                    className="sd-dropdown-content-signin"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <a
                      onClick={() => {
                        router?.navigate(
                          `/${selectedLanguage}/devotee-app/devotee-dashboard`
                        );
                        closeModal();
                      }}
                    >
                      Dashboard
                    </a>
                    <a
                      onClick={() => {
                        logout();
                        // document.getElementById("tms-overlay").style.display =
                        //   "none";
                        router?.navigate(`/${selectedLanguage}/home`);
                      }}
                    >
                      Logout
                    </a>
                  </div>
                  <img
                    src={SD_Dropdown_Arrow_icon}
                    alt="^"
                    style={{ height: "7px", paddingLeft: "5px" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    color: "white",
                    fontWeight: "600",
                    fontSize: "13px",
                  }}
                  onClick={() => {
                    closeModal();
                    router?.navigate(
                      `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                    setActiveTopHeader("login");
                  }}
                >
                  SIGN IN
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="sd-bottom-nav">
        <nav className="sd-inner-navbar">
          <div
            className="sd-hamburger-icon"
            onClick={() => {
              if (rightsider) {
                setrightSider(false);
              }
              setSider(true);
            }}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          <img
            className="mobile-logo"
            src={aptemplesLogo}
            height="40px"
            onClick={() => {
              router?.navigate(`/${selectedLanguage}`);
            }}
          />
          <div className={sider ? "sd-left-sider block" : "sd-left-sider none"}>
            <Sd_mobile_left_sider
              selectedLanguage={selectedLanguage}
              drop1={drop1}
              drop2={drop2}
              setSider={setSider}
              setDropTwo={setDropTwo}
              setDropOne={setDropOne}
              drop3={drop3}
              setDropThree={setDropThree}
              drop4={drop4}
              setDropFour={setDropFour}
              drop5={drop5}
              setDropFive={setDropFive}
              drop6={drop6}
              setDropSix={setDropSix}
              drop7={drop7}
              setDropSeven={setDropSeven}
              drop8={drop8}
              setDropEight={setDropEight}
              drop9={drop9}
              setDropNine={setDropNine}
              drop10={drop10}
              setDropTen={setDropTen}
              drop11={drop11}
              drop12={drop12}
              setDrop11={setDrop11}
              setDrop12={setDrop12}
              drop13={drop13}
              setDrop13={setDrop13}
              drop14={drop14}
              setDrop14={setDrop14}
              drop15={drop15}
              setDrop15={setDrop15}
            />
          </div>

          <div className="sd-inner-right">
            <ul className="sd-top-list">
              <li
                className={
                  currentDashbaordLower === "About"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("about-us");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "About" ? "btn active" : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About
                </button>
                {donationDrop === "about-us" && (
                  <div
                    className="dropdown-menu sd-media"
                    aria-labelledby="dropdownMenuButton"
                    ref={refmediaroom}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        closeModal();

                        setDonationDrop("none");
                        setCurrentDashboardLower("about-us");
                        // router?.navigate(`/${selectedLanguage}/about`);
                      }}
                    >
                      Overview
                      <div className="side-hover sd-left-hover">
                        {NavBarData("aboutOverview")?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                closeModal();

                                setDonationDrop("none");

                                router?.navigate(
                                  `/${selectedLanguage}/about/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        router?.navigate(
                          `/${selectedLanguage}/about/char-dham`
                        );
                        closeModal();

                        setDonationDrop("none");
                      }}
                    >
                      Char Dham
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        router?.navigate(
                          `/${selectedLanguage}/about/dwadasa-jyotirlingas`
                        );
                        closeModal();
                        setDonationDrop("none");
                      }}
                    >
                      Dwadasa Jyotirlingas
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        router?.navigate(
                          `/${selectedLanguage}/about/shakthi-peethas`
                        );
                        closeModal();
                        setDonationDrop("none");
                      }}
                    >
                      Shakthi Peethas
                    </a>
                  </div>
                )}
              </li>
              <li
                className={
                  currentDashbaordLower === "Donors"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
              >
                <button
                  className={
                    currentDashbaordLower === "Donors" ? "btn active" : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    router?.navigate(`/${selectedLanguage}/donors`);
                    setCurrentDashboardLower("Donors");
                  }}
                >
                  Donors
                </button>
              </li>
              <li
                className={
                  currentDashbaordLower === "Media-room"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("media-room");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Media-room"
                      ? "btn active"
                      : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    router?.navigate(`/${selectedLanguage}/media-room`);
                    setCurrentDashboardLower("media-room");
                  }}
                >
                  Media Room
                </button>
                {donationDrop === "media-room" && (
                  <div
                    className="dropdown-menu sd-media"
                    aria-labelledby="dropdownMenuButton"
                    ref={refmediaroom}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        closeModal();

                        setDonationDrop("none");
                        setCurrentDashboardLower("Media-room");
                        router?.navigate(`/${selectedLanguage}/media-room`);
                      }}
                    >
                      Overview
                    </a>

                    {/* <a
                      className="dropdown-item"
                      onClick={() => {
                        router?.navigate(
                          `/${selectedLanguage}/media-room/media-kit`
                        );
                        closeModal();

                        setDonationDrop("none");
                      }}
                    >
                      Media Kit
                    </a> */}
                    <a className="dropdown-item">
                      <a style={{ color: "black", paddingLeft: "0px" }}>
                        Gallery
                      </a>
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["gallery"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                closeModal();

                                setDonationDrop("none");

                                router?.navigate(
                                  `/${selectedLanguage}/media-room/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>
                    {/* <a
                      className="dropdown-item"

                      // href={`/${selectedLanguage}/media-room/whats-new`}
                    >
                      Whats New{" "}
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["whats-new"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                closeModal();

                                setDonationDrop("none");

                                router?.navigate(
                                  `/${selectedLanguage}/media-room/whats-new/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>
                    <a className="dropdown-item">
                      Press
                      <div className="side-hover sd-left-hover">
                        {NavBarData("media")?.["press"]?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="dropdown-item"
                              onClick={() => {
                                closeModal();

                                setDonationDrop("none");

                                router?.navigate(
                                  `/${selectedLanguage}/media-room/press/${data?.path}`
                                );
                              }}
                            >
                              {data?.name}
                            </div>
                          );
                        })}
                      </div>
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        router?.navigate(
                          `/${selectedLanguage}/media-room/rti-act`
                        );

                        closeModal();

                        setDonationDrop("none");
                      }}
                    >
                      RTI Act
                    </a> */}
                  </div>
                )}
              </li>

              <li
                className={
                  currentDashbaordLower === "Support"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("support");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "Support" ? "btn active" : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    router?.navigate(`/${selectedLanguage}/support/contact-us`);
                    setCurrentDashboardLower("Support");
                  }}
                >
                  Support
                </button>
                {donationDrop === "support" && (
                  <div
                    className="dropdown-menu sd-left"
                    ref={refsupport}
                    // aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        router?.navigate(`/${selectedLanguage}/support`);
                        setCurrentDashboardLower;
                        closeModal();
                        ("Support");
                        setDonationDrop("none");
                      }}
                    >
                      Overview{" "}
                    </a>
                    {NavBarData("support")?.map((data, i) => {
                      return (
                        <a
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/support/${data?.path}`
                            );

                            closeModal();

                            setDonationDrop("none");
                          }}
                        >
                          {data?.name}
                        </a>
                      );
                    })}
                  </div>
                )}
              </li>
            </ul>
            <ul className="sd-bottom-list">
              <li
                className={
                  currentDashbaordLower === "about"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("about");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "about"
                      ? "temples btn active"
                      : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    closeModal();
                    setCurrentDashboardLower("about");
                    router?.navigate(`/${selectedLanguage}/temples`);
                  }}
                >
                  Temples
                </button>
              </li>

              <li
                className={
                  currentDashbaordLower === "book-a-pooja"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r"
                }
                onClick={() => {
                  if (localStorage.getItem("accessToken")) {
                    router?.navigate(
                      `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-pooja`
                    );
                  } else {
                    router?.navigate(
                      `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-pooja`
                    );
                  }
                  setCurrentDashboardLower("book-a-pooja");
                }}
                onMouseEnter={() => {
                  setDonationDrop("book-a-pooja");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className={
                    currentDashbaordLower === "book-a-pooja"
                      ? "btn active"
                      : "btn"
                  }
                >
                  Book a Pooja
                </button>
              </li>

              <li
                className={
                  currentDashbaordLower === "join-a-pooja"
                    ? "dropdown sd-first-button border-r active"
                    : "dropdown sd-first-button border-r "
                }
                onMouseEnter={() => {
                  setDonationDrop("donation");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "join-a-pooja"
                      ? "btn active"
                      : "btn"
                  }
                  onClick={() => {
                    closeModal();
                    if (localStorage.getItem("accessToken")) {
                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/join-a-pooja`
                      );
                    } else {
                      router?.navigate(
                        `/${selectedLanguage}/devotee/signin?to=devotee-app/join-a-pooja`
                      );
                    }
                    setCurrentDashboardLower("join-a-pooja");
                  }}
                >
                  Join a Pooja
                </button>
              </li>

              {/* <li
                className={
                  currentDashbaordLower === "online-booking"
                    ? "dropdown sd-first-button border-r active "
                    : "dropdown sd-first-button border-r"
                }
                onMouseEnter={() => {
                  setDonationDrop("online-booking");
                }}
                onMouseLeave={() => setDonationDrop("none")}
              >
                <button
                  className={
                    currentDashbaordLower === "online-booking"
                      ? "btn active"
                      : "btn"
                  }
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    closeModal();
                    setDonationDrop("none");
                    router?.navigate(`/${selectedLanguage}/online-booking`);
                  }}
                >
                  Online Booking
                </button>
                {donationDrop === "online-booking" && (
                  <div
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton"
                    ref={refonlinebooking}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        closeModal();

                        setDonationDrop("none");
                        router?.navigate(`/${selectedLanguage}/online-booking`);
                      }}
                    >
                      Overview
                    </a>

                    {NavBarData("online-data")?.map((data, i) => {
                      return (
                        <a
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            closeModal();

                            setDonationDrop("none");

                            if (localStorage.getItem("accessToken")) {
                              router?.navigate(
                                `/${selectedLanguage}/devotee-app/online-booking/${data?.path}`
                              );
                            } else {
                              router?.navigate(
                                `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                              );
                            }
                          }}
                        >
                          {data?.name}
                        </a>
                      );
                    })}
                  </div>
                )}
              </li> */}
            </ul>
          </div>
          <div className="sd-user-slider">
            <img
              src={SD_Sign_In_icon}
              alt="icon"
              onClick={() => {
                if (userData) {
                  if (userData.displayName) {
                    if (sider) {
                      setSider(false);
                    }
                    setrightSider(true);
                  } else {
                    router?.navigate(
                      `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                    );
                  }
                } else {
                  router?.navigate(
                    `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                  );
                }
              }}
            />
          </div>
          <div
            className={
              rightsider ? "sd-right-sider block" : "sd-right-sider none"
            }
          >
            <div className="inner">
              <div className="inner-nav-top">
                <div className="left">
                  <img
                    className="logo"
                    src={SD_Logo_circle}
                    alt="icon"
                    onClick={() => {
                      router?.navigate(`/${selectedLanguage}/home`);
                    }}
                  />
                </div>
                <div className="right">
                  <img src={SD_cart_icon} alt={SD_cart_icon} />
                  <button
                    type="button"
                    onClick={() => {
                      setrightSider(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="inner-bottom">
                <div className="inner-bottom-t">
                  <div className="sd-dashboard-profile ml-0">
                    <div className={"profile white-bg"}>
                      <p style={{ marginBottom: "0px" }}>
                        {userData?.displayName === "" ||
                        userData?.displayName === null ? (
                          "User"
                        ) : (
                          <>
                            <span>
                              {" "}
                              {userData.displayName?.split(" ")[1] ? (
                                userData.displayName
                                  ?.split(" ")[0]
                                  ?.charAt(0)
                                  .toUpperCase()
                              ) : (
                                <>
                                  <span>
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(0)
                                      .toUpperCase()}
                                    {userData.displayName
                                      ?.split(" ")[0]
                                      ?.charAt(1)}
                                  </span>
                                </>
                              )}
                            </span>
                            <span style={{ marginLeft: "-2px" }}>
                              {userData.displayName?.split(" ")[1]?.charAt(0)}
                            </span>
                          </>
                        )}
                      </p>
                    </div>

                    {/* <img
                        className="sd-dashboard-icons"
                        src={sd_profile_icon_dashboard}
                      /> */}
                    <h2
                      className="sd-heading-18 float-right-ellipse c-voilet "
                      style={{ padding: "5px !important" }}
                    >
                      {userData?.displayName}
                    </h2>
                  </div>
                </div>
                <div className="inner-bottom-b">
                  <div
                    onClick={() => {
                      setrightSider(false);

                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/devotee-dashboard`
                      );
                    }}
                  >
                    <img src={sd_devotee_dashboard} alt="dashboard" />
                    Devotee Dashboard
                  </div>
                  {localStorage.getItem("accessToken") ? (
                    userData?.displayName ? (
                      <div className="sd-dropdown-signin ">
                        <button className="sd-dropbtn-signin" type="button">
                          <img
                            className="spcl"
                            src={
                              userData?.profilePic !== null
                                ? userData?.profilePic
                                : SD_Sign_In_icon
                            }
                            alt={SD_Sign_In_icon}
                          />{" "}
                          {userData?.displayName !== "" ||
                          userData?.displayName !== null
                            ? userData?.displayName
                            : "User"}
                        </button>
                        <div
                          className="sd-dropdown-content-signin"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <a
                            onClick={() => {
                              router?.navigate(
                                `/${selectedLanguage}/devotee-app/devotee-dashboard`
                              );
                            }}
                          >
                            Dashboard
                          </a>
                          {/* {userData?.role === "admin" && (
                      <a
                        onClick={() => {
                          router?.navigate(
                            `/${selectedLanguage}/devotee-app/userview`
                          );
                        }}
                      >
                        User view
                      </a>
                    )} */}
                          <a
                            onClick={() => {
                              logout();
                              router?.navigate(`/${selectedLanguage}/home`);
                            }}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ color: "white", fontWeight: "600" }}
                        onClick={() => {
                          router?.navigate(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                          );
                        }}
                      >
                        <img src={SD_Sign_In_icon} alt="icon" /> DEVOTEE SIGNIN
                      </div>
                    )
                  ) : (
                    <div
                      style={{ color: "white", fontWeight: "600" }}
                      onClick={() => {
                        router?.navigate(
                          `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
                        );
                      }}
                    >
                      <img src={SD_Sign_In_icon} alt="icon" /> DEVOTEE SIGNIN
                    </div>
                  )}

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightone) {
                        setDropRightOne(false);
                      }
                      setDropRightTwo(!dropRightTwo);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_online_booking_icon} alt="online" /> Online
                      Booking
                    </div>
                    {dropRightTwo ? (
                      <img
                        src={sd_minus_icon}
                        alt="-"
                        onClick={() => {
                          setDropRightTwo(false);
                        }}
                      ></img>
                    ) : (
                      <img
                        src={sd_plus_icon}
                        onClick={() => {
                          setDropRightTwo(true);
                        }}
                      />
                    )}
                  </div>

                  <div
                    className={
                      dropRightTwo
                        ? "drop-right-one block"
                        : "drop-right-one none"
                    }
                  >
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          router?.navigate(
                            `/${selectedLanguage}/devotee-app/online-booking/darshanam`
                          );
                        } else {
                          router?.navigate(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam`
                          );
                        }
                      }}
                    >
                      Darshanam
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          router?.navigate(
                            `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-pooja`
                          );
                        } else {
                          router?.navigate(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-pooja`
                          );
                        }
                      }}
                    >
                      Pratyaksha Pooja Booking
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        if (localStorage.getItem("accessToken")) {
                          router?.navigate(
                            `/${selectedLanguage}/devotee-app/online-booking/paroksha-pooja`
                          );
                        } else {
                          router?.navigate(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-pooja`
                          );
                        }
                      }}
                    >
                      Paroksha Pooja Booking
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          router?.navigate(
                            `/${selectedLanguage}/devotee-app/online-booking/donations`
                          );
                        } else {
                          router?.navigate(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/donations`
                          );
                        }
                      }}
                    >
                      Donations
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);

                        if (localStorage.getItem("accessToken")) {
                          router?.navigate(
                            `/${selectedLanguage}/devotee-app/online-booking/accommodations`
                          );
                        } else {
                          router?.navigate(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
                          );
                        }
                      }}
                    >
                      Accommodation
                    </div>{" "}
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setrightSider(false);
                        router?.navigate(
                          `/${selectedLanguage}/devotee-app/online-booking/publications`
                        );
                      }}
                    >
                      Publications
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setrightSider(false);

                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/booking-history`
                      );
                    }}
                  >
                    <img src={sd_booking_history_icon} alt="booking" /> Booking
                    History
                  </div>

                  <div
                    className="between"
                    onClick={() => {
                      if (dropRightTwo) {
                        setDropRightTwo(false);
                      }
                      setDropRightOne(!dropRightone);
                    }}
                  >
                    <div className="sd-padding-none">
                      <img src={sd_default_profile_pic}></img> My Account
                    </div>
                    {dropRightone ? (
                      <img src={sd_minus_icon} alt="-" />
                    ) : (
                      <img src={sd_plus_icon} alt="+" />
                    )}
                  </div>

                  <div
                    className={
                      dropRightone ? "drop-one block" : "drop-one none"
                    }
                  >
                    <div
                      onClick={() => {
                        setrightSider(false);

                        router?.navigate(
                          `/${selectedLanguage}/devotee-app/my-account/profile`
                        );
                      }}
                    >
                      My Profile
                    </div>
                    <div
                      onClick={() => {
                        setrightSider(false);

                        router?.navigate(
                          `/${selectedLanguage}/devotee-app/my-account/change-password`
                        );
                      }}
                    >
                      Change Password
                    </div>
                    {/* <div
                      onClick={() => {
                        setrightSider(false);

                        router?.navigate(
                          `/${selectedLanguage}/devotee-app/my-account/devotee-masterlist`
                        );
                      }}
                    >
                      Devotee Master List
                    </div> */}
                    {/* <div
                      onClick={() => {
                        setrightSider(false);

                        router?.navigate(
                          `/${selectedLanguage}/devotee-app/my-account/favorites`
                        );
                      }}
                    >
                      My Favorites
                    </div> */}
                  </div>

                  {/* <div
                    style={{ paddingBottom: "50px" }}
                    onClick={() => {
                      setrightSider(false);

                      router?.navigate(`/${selectedLanguage}/support`);
                    }}
                  >
                    <img src={sd_support} alt="support" />
                    Support
                  </div> */}
                </div>
              </div>
              <div
                className="inner-footer"
                onClick={() => {
                  logout();
                  setUserData({});
                  setDropOne(false);
                  setDropTwo(false);
                  setDropRightOne(false);
                  setDropRightTwo(false);
                  setrightSider(false);
                  router?.navigate("/");
                }}
              >
                <img src={sd_logout_icon} alt="Logout" height="15px" />
                <span style={{ marginLeft: "5px", color: "#752F28" }}>
                  Logout
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <button
        className={visibleBut ? "active-r" : "hide-r"}
        onClick={() => {
          setVisibleBut(false);
          refContainer.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }}
      >
        <img src={SD_Dropdown_Arrow_icon} />
      </button>
      <TmsTempleOverlay
        searchtemple={templeSearch}
        selectedLanguage={selectedLanguage}
        setTempleSearch={setTempleSearch}
      />
    </div>
  );
});

New_Nav.displayName = "navbar";
export default withRouter(New_Nav);
