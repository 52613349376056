import React, { useState } from "react";
import {
  SD_divider,
  kedarnath_01,
  kedarnath_02,
  kedarnath_03,
  badrinath_01,
  badrinath_02,
  gangothri_01,
  gangothri_02,
  yamunothri_01,
  yamunothri_02,
  kedarnath,
  gan,
  badrinath,
  gangothri,
  yamunothri,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";

const Sd_About_Char_Dham = () => {
  const [selected, setSelected] = useState("kedarnath");
  const DhamArray = [
    { img: kedarnath, title: "Kedarnath Dham Uttarakhand", link: "kedarnath" },
    { img: badrinath, title: "Badrinath Dham Uttarakhand", link: "badrinath" },
    { img: gangothri, title: "Gangotri Dham Uttarakhand", link: "gangotri" },
    { img: yamunothri, title: "Yamunotri Dham Uttarakhand", link: "yamunotri" },
  ];

  const content = [
    {
      kedarnath: [
        {
          desc: (
            <>
              {" "}
              The Kedarnath Temple, dedicated to Hindu deity Lord Shiva, is
              highly revered, with its current structure built by Adi
              Shankaracharya in the 8th century A.D. It stands adjacent to the
              earlier temple constructed by the Pandavas, and Adi Shankaracharya
              restored its glory as one of the 12 Jyotirlingas of Lord Shiva,
              making the temple over 1200 years old. Kedarnath Dham, situated
              amidst towering mountain peaks and snow-covered landscapes, is a
              sacred pilgrimage site in Northern India. Serving as a base camp
              for treks to the holy shrine at an elevation of 11,755 ft, Gauri
              Kund facilitates the journey, accessible on foot or through
              Palki/Pony services.
            </>
          ),
          img: kedarnath_01,
        },
        {
          desc: (
            <>
              In winter, the deity's statue is moved to Omkareshwar Temple in
              Ukhimath for seekers of Lord Shiva's blessings. According to
              legend, the Pandavas, after the Kurukshetra battle, sought Lord
              Shiva's forgiveness during their journey to Varanasi. Lord Shiva,
              taking the form of a buffalo, eluded them and disappeared
              underground, leaving his hump as the revered object of worship at
              Kedarnath Temple.
            </>
          ),
          img: kedarnath_02,
        },
        {
          desc: (
            <>
              Kedarnath, one of the Panch Kedar in the Garhwal Himalayan range,
              holds great sanctity. The temple, credited to the Pandavas and
              later restored by Adi Shankaracharya, is a sacred destination.
              Gauri Kund, situated at an elevation of 1982 m, emanates a
              spiritual atmosphere.
            </>
          ),
          img: kedarnath_03,
        },
      ],
      badrinath: [
        {
          desc: (
            <>
              The Badrinath Temple, a significant Hindu sanctuary dedicated to
              Lord Vishnu, holds a prominent position in the religious landscape
              of India. It is a key site in both the Chota Char Dham and Bada
              Char Dham pilgrimage circuits. The temple enshrines a meditative
              idol of Lord Badrinarayan. This one-metre-tall black stone idol of
              Lord Vishnu holds a special status among the eight Swayam Vyakta
              kshetras, representing self-manifested forms. During the winter
              months, it receives provisional reverence at Vasu Dev Temple
              situated in Joshimath.
            </>
          ),
          img: badrinath_01,
        },
        {
          desc: (
            <>
              According to legend, the origins of Badrinath Dham can be traced
              back to Dharma’s sons, Nar and Narayan, who sought refuge in the
              Himalayas. Their exploration led them to discover the Panch Badri
              sites, including Yog Badri, Dhyan Badri, Bridha Badri, and
              Bhavisya Badri. Eventually, they established their hermitage along
              the Alaknanda River's hot and cold springs, naming the place Badri
              Vishal. Notably, Badrinath Dham is unique for its inclusion in
              both Dham circuits, making it a pinnacle among the Panch Badri and
              one of the distinguished 108 Divya Desams of Lord Vishnu. Nearby
              attractions include Mata Murti Temple, dedicated to Lord Badri’s
              mother, and Tapt Kund, a warm spring known for its healing
              properties.
            </>
          ),
          img: badrinath_02,
        },
      ],
      gangotri: [
        {
          desc: (
            <>
              Nestled in awe-inspiring natural beauty, Gangotri Dham is a serene
              and enchanting city that seamlessly combines tranquillity and
              adventure. Positioned along the Bhagirathi River in Uttarakhand,
              Gangotri draws its significance from the sacred Ganga River. The
              temple remains closed during the harsh winter months.
              <br /> <br /> The sanctity of Gangotri is deeply rooted in ancient
              Hindu mythology, recounting the tale of King Bhagirath's devoted
              meditation, which brought the divine Ganga from heaven, guided by
              Lord Shiva. Ganga was harboured within Lord Shiva's hair and
              released in three streams due to Bhagirath's unwavering
              dedication. The spot where Bhagirath meditated is called
              'Bhagirath Shila,' situated close to the Ganga shrine.
            </>
          ),
          img: gangothri_01,
        },
        {
          desc: (
            <>
              Gangotri seamlessly blends religious importance with
              archaeological charm, inviting adventure enthusiasts to partake in
              activities such as camping. At an altitude of 4460 m, Tapovan not
              only serves as a camping site but also offers an exhilarating
              trekking route, appealing to both pilgrims and adventure seekers.
              The region's captivating views include the majestic Shivling peaks
              and lush landscapes.
              <br /> <br /> Memorable experiences unfold against the backdrop of
              Gangotri, starting with the Submerged Shivling—a natural rock form
              where Lord Shiva is believed to have held Ganga in his hair.
              Gomukh, the origin of the sacred Ganga River, provides a serene
              atmosphere that enthrals visitors, making it a must-visit
              destination on the Gangotri pilgrimage. Gangotri's charm extends
              beyond spirituality, embracing adventure and offering a refreshing
              experience for those seeking its blessings.
            </>
          ),
          img: gangothri_02,
        },
      ],
      yamunotri: [
        {
          desc: (
            <>
              Yamunotri Dham, an integral part of Uttarakhand's Chota Char Dham,
              is dedicated to venerating the Yamuna River, India's second most
              sacred river, and its source. Situated at an elevation of 3293 m,
              this revered location attracts pilgrims seeking divine blessings.
              Initially constructed by Maharani Guleria of Jaipur and later
              reconstructed by Maharana Pratap Shah of Tehri Garhwal, the temple
              enshrines a silver idol of Goddess Yamuna. Opening its doors on
              Akshaya Tritiya, the temple becomes a focal point for thousands of
              devotees on their Char Dham pilgrimage. Goddess Yamuna, the twin
              sister of Yama and daughter of Lord Sun, holds a significant place
              in this sacred abode.
            </>
          ),
          img: yamunothri_01,
        },
        {
          desc: (
            <>
              The history of the temple is intertwined with Asit Muni, who faced
              challenges in ritually revering the Ganga in his old age. In
              response, Goddess Ganga assumed the form of Yamuna to stand by the
              sage's side. According to Hindu mythology, Yamunotri Dham carries
              special importance. Bathing in the pure waters of the divine
              Yamuna River is believed to cleanse sins and protect from untimely
              deaths. The trek from Janki Chatti to the temple offers a unique
              experience, with legends recounting Lord Hanuman's visit after
              extinguishing the fire on his tail in the Yamuna River's waters.
              This sacred place captivates nature lovers with its glaciers,
              thermal springs, and picturesque streams. While embarking on the
              Char Dham Yatra, there are additional tourist spots that
              seamlessly blend spirituality with joyful moments. Each
              destination along this divine journey holds its own significance.
            </>
          ),
          img: yamunothri_02,
        },
      ],
    },
  ];
  return (
    <>
      <div className="sd-about-one">
        <div
          className="sd-about-one-bottom flex-column"
          style={{ alignItems: "center" }}
        >
          <div className="sd-about-one-bottom-top">
            <h1 style={{ fontWeight: "600", fontSize: "30px" }}>Char Dham</h1>
            <img
              style={{ height: "20px" }}
              src={SD_divider}
              alt={"divider"}
            ></img>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {DhamArray?.map((item, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: "25%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    padding: "0px 30px",
                    cursor: "pointer",
                    filter:
                      selected === item?.link ? "grayscale(0)" : "grayscale(1)",
                  }}
                  onClick={() => setSelected(item?.link)}
                >
                  <img src={item?.img} alt={item?.title} />
                </div>
              );
            })}
          </div>
          <br />
          <br />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          background: "#EFEFEF",
          padding: "80px 20px",
        }}
      >
        <div style={{ width: "100%", maxWidth: "998px" }}>
          {content[0][selected]?.map((item, i) => {
            return (
              <div
                className={`content ${
                  (i + 1) % 2 === 0 ? "row-reverse" : "row"
                }`}
                key={i}
              >
                <div className="w-2">
                  {i === 0 && (
                    <h2
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      {selected}

                      <br />
                      <br />
                    </h2>
                  )}
                  <div
                    style={{
                      padding: (i + 1) % 2 === 0 ? "50px" : "0px 50px 0px 0px",
                      display: "flex",
                      alignItems: i === 0 ? "" : "center",
                      flexWrap: "wrap",
                      height: "100%",
                    }}
                  >
                    <p
                      style={{
                        color: "#313131",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                      {item?.desc}
                    </p>
                  </div>
                </div>
                <div className="w-2">
                  <img
                    alt="img"
                    src={item?.img}
                    style={{ width: "100%" }}
                  ></img>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Sd_About_Char_Dham;
