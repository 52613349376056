/* eslint-disable react/jsx-pascal-case */
import React, { useState, useContext, useEffect } from "react";
import Sd_breadcrumb from "../Components/sd-common-components/sd-breadcrumb";

import {
  sd_booking_history_icon,
  sd_devotee_dashboard,
  sd_logout_icon,
  sd_myaccount_icon,
  sd_online_booking_icon,
  sd_dashboard_phone_ico,
  sd_support,
} from "../Assets/imageList";

import Sd_faqs_semi_view from "../Components/sd-common-components/sd-faqs-semi-view";
import Sd_dashboard_devotee_dashboard from "./sd-dashboard-inner-pages/sd-dashboard-devotee-dashboard";
import Sd_dashboard_parokshaseva_booking_confirm_details from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-paroksha-seva/sd-dashboard-parokshaseva-booking-confirm-details";
import Sd_Dashboard_parokshaseva_online_booking from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-paroksha-seva/sd-dashboard-parokshaseva-online-booking";
import Sd_dashboard_parokshaseva_seva_details from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-paroksha-seva/sd-dashboard-parokshaseva-booking-details";
import Sd_dashboard_ma_change_password from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-change-password";
import Sd_dashboard_ma_myprofile from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-myprofile";
import Sd_loading from "../Components/sd-common-components/sd-loading";
import Sd_dashboard_ma_devotee_master from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-devotee-master";
import Sd_dashboard_ma_favorites from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-favorites";
import Sd_dashboard_booking_history from "./sd-dashboard-inner-pages/sd-dashboard-booking-history";
import Sd_dashboard_ma_settings from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-settings";
import Sd_dashboard_online_booking_pratyaksha_seva from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashbaord-online-booking-pratyaksha-seva/sd-dashboard-online-booking-pratyaksha-seva";
import { SD_Context } from "../context/sd-context";
import Sd_dashboard_pratyakshaseva_booking_confirm from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashbaord-online-booking-pratyaksha-seva/sd-dashboard-online-booking-pratyaksha-seva-confirm";
import Sd_DashboardService from "../services/sd-dashboard.service";
import Sd_token_fectcher from "./sd-misc/sd-admin-token-fetcher";
import { toast } from "react-toastify";
toast.configure();
import "../Utils/sd-styles/sd-css/main.css";
import SdDashboardJoinAPooja from "./sd-dashboard-inner-pages/sd-dashboard-join-a-pooja";
import SdDashboardConductAPooja from "./sd-dashboard-inner-pages/sd-conduct-a-pooja/sd-conduct-a-pooja";
import SdConductAPoojaStart from "./sd-dashboard-inner-pages/sd-conduct-a-pooja/sd-conduct-a-pooja-start";
import Sd_dashboard_pratyakshaseva_seva_details from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashbaord-online-booking-pratyaksha-seva/sd-dashboard-online-booking-pratyaksha-seva-edit";
import { Route, Routes } from "react-router-dom";
import { withRouter } from "../Utils/with-router/withRouter";
const SD_Dashboard = ({ router }) => {
  const {
    activePath,
    active,
    userData,
    setCurrentDashboardLower,
    devoteeList,
    setDevoteeList,
    logout,
  } = useContext(SD_Context);
  //---------------------------------------------------------------states--------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [loading, setloading] = useState(true);
  const [dropOne, setDropOne] = useState(false);
  const [dropTwo, setDropTwo] = useState(false);
  //---------------------------------------------------------------states--------------------------------------------------------//

  //---------------------------------------------------------------consts--------------------------------------------------------//
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const data = [
    "my-profile",
    "change-password",
    "devotee-master-list",
    "my-favorites",
    "settings",
  ];
  const online_booking = [
    "online-booking-darshanam",
    "onlinebooking-darshanam-confirm-details",
    "online-booking-pratyakshaseva",
    "online-booking-pratyaksha-enter-details",
    "onlinebooking-pratyaksha-seva-confirm-details",
    "online-booking-parokshaseva",
    "online-booking-parokshaseva-enter-details",
    "onlinebooking-paroksha-seva-confirm-details",
    "online-booking-donations",
    "online-booking-donations-confirm",
    "publications",
    "publications-confirm",
  ];
  const myaccount = [
    "my-profile",
    "change-password",
    "devotee-master-list",
    "my-favorites",
  ];
  //---------------------------------------------------------------consts--------------------------------------------------------//

  //--------------------------------------------------------------useEffects--------------------------------------------------------//
  useEffect(() => {
    initFunction();
  }, []);

  const initFunction = async () => {
    setCurrentDashboardLower("");

    if (!localStorage.getItem("accessToken")) {
      router?.navigate(
        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations`
      );
    }
    setloading(true);
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    try {
      if (devoteeList === null) {
        let res = await Sd_DashboardService.get_master_list({
          page: 1,
          limit: 100,
          id: "APEDP",
        });
        setDevoteeList(res.items);
      }
    } catch (e) {
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        router?.navigate(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
    }
    setloading(false);
  };

  useEffect(() => {
    if (data.includes(active)) {
      setDropTwo(true);
    }
    if (
      active === "devotee-dashboard" ||
      active === "booking-history" ||
      active === "support"
    ) {
      setDropOne(false);
      setDropTwo(false);
    }
    if (online_booking.includes(active)) {
      setDropOne(true);
    }
    if (myaccount.includes(active)) {
      setDropTwo(true);
    }
  }, [active]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//
  return (
    <>
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="wrapper">
          <Sd_breadcrumb from={activePath} />
          <div id="sd-content">
            <section className="mt-30">
              <div className="container-fluid">
                <div className="row mlr-30">
                  <div
                    className="col-xs-12 col-sm-12 col-md-3 col-lg-3 sd-side-menu visible-md visible-lg"
                    id="side-menu"
                  >
                    <div className="sd-dashboard-profile">
                      <div className={"profile"}>
                        <p style={{ marginBottom: "0px" }}>
                          {userData?.displayName === "" ||
                          userData?.displayName === null ? (
                            "User"
                          ) : (
                            <>
                              <span>
                                {" "}
                                {userData?.displayName?.split(" ")[1] ? (
                                  userData?.displayName
                                    ?.split(" ")[0]
                                    ?.charAt(0)
                                    .toUpperCase()
                                ) : (
                                  <>
                                    <span>
                                      {userData?.displayName
                                        ?.split(" ")[0]
                                        ?.charAt(0)
                                        .toUpperCase()}
                                      {userData?.displayName
                                        ?.split(" ")[0]
                                        ?.charAt(1)}
                                    </span>
                                  </>
                                )}
                              </span>
                              <span style={{ marginLeft: "-2px" }}>
                                {userData?.displayName
                                  ?.split(" ")[1]
                                  ?.charAt(0)}
                              </span>
                            </>
                          )}
                        </p>
                      </div>

                      {/* <img
                        className="sd-dashboard-icons"
                        src={sd_profile_icon_dashboard}
                      /> */}
                      <h2
                        className="sd-heading-18 float-right-ellipse"
                        style={{ padding: "5px !important" }}
                      >
                        {userData?.displayName}
                      </h2>
                    </div>
                    <hr className="clearfix"></hr>
                    <nav>
                      <ul className="sd-dashboard-side-menu">
                        <li
                          className={
                            active === "devotee-dashboard" ? "active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              router?.navigate(
                                `/${selectedlanguage}/devotee-app/devotee-dashboard`
                              );
                            }}
                          >
                            <img
                              alt="Devotee Dashboard"
                              title="Devotee Dashboard"
                              src={sd_devotee_dashboard}
                            />
                            Dashboard
                          </a>
                        </li>
                        {!userData?.userPermissions?.includes("PRIEST") && (
                          <li>
                            <button
                              className="collapsible"
                              onClick={() => {
                                if (dropTwo) {
                                  setDropTwo(false);
                                }
                                setDropOne(!dropOne);
                              }}
                            >
                              <img
                                alt="Online Booking"
                                title="Online Booking"
                                src={sd_online_booking_icon}
                              />
                              Bookings{" "}
                              {dropOne ? (
                                <div className="sd-plus-icon">-</div>
                              ) : (
                                <div className="sd-plus-icon">+</div>
                              )}
                            </button>

                            {dropOne ? (
                              <div className="content">
                                <ul className="sd-dashboard-side-menu-sub">
                                  {/* <li
                                  className={
                                    active === "online-booking-darshanam" ||
                                    active ===
                                      "onlinebooking-darshanam-confirm-details"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      router?.navigate(
                                        `/${selectedlanguage}/devotee-app/online-booking/darshanam?state=clear`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Darshanam{" "}
                                  </a>
                                </li> */}
                                  <li
                                    className={
                                      active ===
                                        "online-booking-pratyakshaseva" ||
                                      active ===
                                        "online-booking-pratyaksha-enter-details" ||
                                      active === "" ||
                                      active ===
                                        "onlinebooking-pratyaksha-seva-confirm-details"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <a
                                      onClick={() => {
                                        router?.navigate(
                                          `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja`
                                        );
                                      }}
                                    >
                                      {" "}
                                      Pratyaksha Pooja{" "}
                                    </a>
                                  </li>
                                  {/* <li
                                  className={
                                    active ===
                                      "online-booking-accommodations" ||
                                    active ===
                                      "online-booking-accommodation-confirm"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      router?.navigate(
                                        `/${selectedlanguage}/devotee-app/online-booking/accommodations?state=clear`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Accommodations{" "}
                                  </a>
                                </li>*/}
                                  <li
                                    className={
                                      active ===
                                        "online-booking-parokshaseva" ||
                                      active ===
                                        "online-booking-parokshaseva-enter-details" ||
                                      active ===
                                        "onlinebooking-paroksha-seva-confirm-details"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <a
                                      onClick={() => {
                                        router?.navigate(
                                          `/${selectedlanguage}/devotee-app/online-booking/paroksha-pooja`
                                        );
                                      }}
                                    >
                                      {" "}
                                      Paroksha Pooja{" "}
                                    </a>
                                  </li>
                                  {/* <li
                                className={
                                  active === "online-booking-saswataseva"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/online-booking/saswata-seva`
                                    );
                                  }}
                                >
                                  {" "}
                                  Saswata Seva{" "}
                                </a>
                              </li> */}

                                  {/* <li
                                  className={
                                    active === "online-booking-donations" ||
                                    active ===
                                      "online-booking-donations-confirm"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      router?.navigate(
                                        `/${selectedlanguage}/devotee-app/online-booking/donations?state=clear`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Donations{" "}
                                  </a>
                                </li> */}
                                  {/* <li
                                  className={
                                    active === "publications" ||
                                    active === "publications-confirm"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      router?.navigate(
                                        `/${selectedlanguage}/devotee-app/online-booking/publications`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Publications
                                  </a>
                                </li> */}

                                  {/* <li
                                className={
                                  active === "online-booking-kalyanakatta"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/online-booking/kalyana-katta`
                                    );
                                  }}
                                >
                                  {" "}
                                  Kalyana Katta Tickets{" "}
                                </a>
                              </li> */}
                                  {/* <li
                                className={
                                  active === "online-booking-prasadam"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/online-booking/prasadam`
                                    );
                                  }}
                                >
                                  {" "}
                                  Prasadam{" "}
                                </a>
                              </li> */}

                                  {/* <li
                                className={
                                  active === "online-booking-kalayanamandapam"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/online-booking/kalayana-mandapam`
                                    );
                                  }}
                                >
                                  {" "}
                                  Kalyana Mandapam{" "}
                                </a>
                              </li> */}
                                  {/* <li
                                  className={
                                    active === "online-booking-publications"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    
                                    onClick={() => {
                                      router?.navigate(
                                        `/${selectedlanguage}/devotee-app/online-booking/publications`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Publications{" "}
                                  </a>
                                </li> */}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </li>
                        )}

                        {!userData?.userPermissions?.includes("PRIEST") && (
                          <li
                            className={
                              active === "join_a_pooja" ? "active" : ""
                            }
                            onClick={() => {
                              router?.navigate(
                                `/${selectedlanguage}/devotee-app/join-a-pooja`
                              );
                            }}
                          >
                            <a>
                              <img
                                alt="Join a Pooja"
                                title="Join a Pooja"
                                src={sd_support}
                              />
                              Join a Pooja
                            </a>
                          </li>
                        )}
                        {userData?.userPermissions?.includes("PRIEST") && (
                          <li
                            className={
                              active === "conduct-a-pooja" ||
                              active === "conduct-a-pooja-stream"
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              router?.navigate(
                                `/${selectedlanguage}/devotee-app/conduct-a-pooja`
                              );
                            }}
                          >
                            <a>
                              <img
                                alt="Conduct a Pooja"
                                title="Conduct a Pooja"
                                src={sd_support}
                              />
                              Conduct a Pooja
                            </a>
                          </li>
                        )}

                        <li
                          className={
                            active === "booking-history" ? "active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              router?.navigate(
                                `/${selectedlanguage}/devotee-app/booking-history`
                              );
                            }}
                          >
                            <img
                              alt="Booking History"
                              title="Booking History"
                              src={sd_booking_history_icon}
                            />
                            Booking History
                          </a>
                        </li>

                        <li>
                          <button
                            className="collapsible"
                            onClick={() => {
                              if (dropOne) {
                                setDropOne(false);
                              }
                              setDropTwo(!dropTwo);
                            }}
                          >
                            <img
                              alt="My Account"
                              title="My Account"
                              src={sd_myaccount_icon}
                            />
                            My Account{" "}
                            {dropTwo ? (
                              <div className="sd-plus-icon">-</div>
                            ) : (
                              <div className="sd-plus-icon">+</div>
                            )}
                          </button>

                          {dropTwo ? (
                            <div className="content">
                              <ul className="sd-dashboard-side-menu-sub">
                                <li
                                  className={
                                    active === "my-profile" ? "active" : ""
                                  }
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/my-account/profile`
                                    );
                                  }}
                                >
                                  <a> My Profile</a>
                                </li>
                                <li
                                  className={
                                    active === "change-password" ? "active" : ""
                                  }
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/my-account/change-password`
                                    );
                                  }}
                                >
                                  <a> Change Password</a>
                                </li>
                                {/* <li
                                  className={
                                    active === "devotee-master-list"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/my-account/devotee-masterlist`
                                    );
                                  }}
                                >
                                  <a> Devotee Master List</a>
                                </li>
                                <li
                                  className={
                                    active === "my-favorites" ? "active" : ""
                                  }
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/my-account/favorites`
                                    );
                                  }}
                                >
                                  <a> My Favorites</a>
                                </li> */}
                                {/* <li
                                  className={
                                    active === "settings" ? "active" : ""
                                  }
                                  onClick={() => {
                                    router?.navigate(
                                      `/${selectedlanguage}/devotee-app/my-account/settings`
                                    );
                                  }}
                                >
                                  <a > Settings</a>
                                </li> */}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>

                        {/* <li
                          className={active === "support" ? "active" : ""}
                          onClick={() => {
                            router?.navigate(`/${selectedlanguage}/support`);
                          }}
                        >
                          <a>
                            <img
                              alt="Support"
                              title="Support"
                              src={sd_support}
                            />
                            Support
                          </a>
                        </li> */}

                        <li
                          onClick={() => {
                            logout();

                            router?.navigate(`/${selectedlanguage}/home`);
                          }}
                        >
                          <a>
                            <img
                              className="sd-logout-img"
                              alt="Logout"
                              title="Logout"
                              src={sd_logout_icon}
                            />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <div className="sd-dashboard-contact-details">
                      <p>
                        For any queries, please contact Devasthanam Information
                        Centre on
                      </p>
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <img
                          src={sd_dashboard_phone_ico}
                          height="fit-content"
                        />

                        <p
                          className="sd-phone"
                          style={{
                            paddingTop: "0px !important",
                            paddingLeft: "5px",
                            color: "#2F3193",
                          }}
                        >
                          <span>+91 95821 40591</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="route switch ">
                    <Routes>
                      <Route
                        exact
                        path="userview"
                        element={<Sd_token_fectcher />}
                      />
                      <Route
                        exact
                        path="conduct-a-pooja/stream/:id"
                        element={<SdConductAPoojaStart />}
                      />
                      {/*------------------------------------------------------------ Devotee Dashboard-----------------------------------------------------*/}
                      <Route
                        exact
                        path="devotee-dashboard"
                        element={<Sd_dashboard_devotee_dashboard />}
                      />
                      {/*------------------------------------------------------------ Online Booking-----------------------------------------------------*/}
                      <Route
                        exact
                        path="online-booking/paroksha-pooja"
                        element={<Sd_Dashboard_parokshaseva_online_booking />}
                      />
                      <Route
                        exact
                        path="online-booking/paroksha-pooja/confirm-details"
                        element={
                          <Sd_dashboard_parokshaseva_booking_confirm_details />
                        }
                      />
                      <Route
                        exact
                        path="online-booking/paroksha-pooja/booking/:type"
                        element={<Sd_dashboard_parokshaseva_seva_details />}
                      />
                      <Route
                        exact
                        path="online-booking/pratyaksha-pooja"
                        element={
                          <Sd_dashboard_online_booking_pratyaksha_seva />
                        }
                      />
                      <Route
                        exact
                        path="online-booking/pratyaksha-pooja/confirm-details"
                        element={
                          <Sd_dashboard_pratyakshaseva_booking_confirm />
                        }
                      />
                      <Route
                        exact
                        path="online-booking/pratyaksha-pooja/booking/:type"
                        element={<Sd_dashboard_pratyakshaseva_seva_details />}
                      />
                      {/*------------------------------------------------------------ Booking History-----------------------------------------------------*/}
                      <Route
                        exact
                        path="booking-history"
                        element={<Sd_dashboard_booking_history />}
                      />
                      {/*------------------------------------------------------------ My Account-----------------------------------------------------*/}
                      <Route
                        exact
                        path="my-account/change-password"
                        element={<Sd_dashboard_ma_change_password />}
                      />
                      <Route
                        exact
                        path="my-account/favorites"
                        element={<Sd_dashboard_ma_favorites />}
                      />
                      <Route
                        exact
                        path="my-account/settings"
                        element={<Sd_dashboard_ma_settings />}
                      />
                      <Route
                        exact
                        path="my-account/devotee-masterlist"
                        element={<Sd_dashboard_ma_devotee_master />}
                      />
                      <Route
                        exact
                        path="my-account/profile"
                        element={<Sd_dashboard_ma_myprofile />}
                      />
                      <Route
                        exact
                        path="join-a-pooja"
                        element={<SdDashboardJoinAPooja />}
                      />{" "}
                      <Route
                        exact
                        path="conduct-a-pooja"
                        element={<SdDashboardConductAPooja />}
                      />
                      {/*------------------------------------------------------------ Support-----------------------------------------------------*/}
                    </Routes>
                  </div>
                  {/* {displayFunction()} */}
                </div>
              </div>
            </section>
          </div>
          <br />

          <Sd_faqs_semi_view from="pratyaksha_seva" />
        </div>
      )}
    </>
  );
};
export default withRouter(SD_Dashboard);
