import Axios from "../Utils/sd-axios/Axios";

const ConductPoojaService = {
  getPriestSlots: async (date, templeID) => {
    try {
      const resp = await Axios.get(
        `/seva-slot-location-daily-quota/priest-sevas?date=${date}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-code": templeID,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.message
            ? error?.response?.message
            : "something is wrong"
        );
      }
    }
  },
  toggleStream: async (
    locationId,
    status,
    sevaSlotId,
    sevaSlotLocationId,
    templeID
  ) => {
    try {
      const resp = await Axios.patch(
        `/locations/stream_status/${locationId}/${status}?sevaSlotLocationId=${sevaSlotLocationId}&sevaSlotId=${sevaSlotId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-code": templeID,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.message
            ? error?.response?.message
            : "something is wrong"
        );
      }
    }
  },
};

export default ConductPoojaService;
