import React, { useContext, useEffect, useState } from "react";
import {
  orange_arrow,
  sd_rupee,
  sd_script,
  sd_share,
  sd_wishlist_heart_unactive,
} from "../../../../Assets/imageList";
import { SD_Context } from "../../../../context/sd-context";
// import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
import "../../../../Utils/sd-styles/sd-css/main.css";
import { withRouter } from "../../../../Utils/with-router/withRouter";

const Sd_dashboard_online_booking_pratyaksha_seva = ({ router }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//

  const { templeData, setcurrentBookingPratyaksha } = useContext(SD_Context);
  const { setActive, setActivePath } = useContext(SD_Context);
  const [loading, setLoading] = useState(false);
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [selectedTemple, setSelectedTemple] = useState({});
  const [pratyakshaSevaData, setPratyakshaSevaData] = useState([]);

  //-------------------------------------------------------------states---------------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  React.useEffect(() => {
    setActive("online-booking-pratyakshapooja");
    setActivePath("Onlinebooking-Pratyaksha Pooja");
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setcurrentBookingPratyaksha(null);
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    setLoading(true);
    if (localStorage.getItem("selected-temple")) {
      setSelectedTemple(localStorage.getItem("selected-temple"));
      getData(localStorage.getItem("selected-temple"));
    } else {
      setSelectedTemple(templeData[0].shortName);
      getData(templeData[0].shortName);
    }
  }, [templeData]);

  //-------------------------------------------------------------useefects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getData = async (templeID) => {
    let data = await PratyakshaSevaService.getAllvalidOnline(templeID);
    if (data) {
      setPratyakshaSevaData(
        data.filter(
          (item) => item.sevaType === "pratyakshaSeva" && item.onlineEnabled
        )
      );
    }
    setLoading(false);
  };

  const displayFunction = () => {
    if (pratyakshaSevaData.length === 0) {
      return (
        <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
          <img
            src={sd_script}
            alt="#"
            height="80px"
            width="80px"
            className="mr-5 mb-5 opace"
          />
          We are sorry to say that there are no pratyaksha poojas available
          right now!. Sorry for the inconvenience.
        </p>
      );
    }
    let array = pratyakshaSevaData.map((item, i) => {
      if (item?.maxOnlineBookings === 0) {
        return;
      }
      return (
        <div className="seva-div-booking" key={i}>
          <img
            className="head-image-booking"
            src={item.imageUrl}
            alt={"image"}
          />
          <h4>{item?.name}</h4>
          <h5>{item.description}</h5>
          {/* <h6
            onClick={() => {
              router?.navigate(
                `/${selectedlanguage}/sevas-and-darshanam/pratyaksha-seva/${item?.name
                  ?.replace?.(/\s/g, "-")
                  ?.replace?.(/[{()}]/g, "")
                  ?.toLowerCase()}`
              );
            }}
          >
            More Info <img src={SD_Right_Arrow_white} alt=">" />
          </h6> */}
          <div className="sd-timings-booking">
            <div>
              <img
                className="padding-r"
                src={sd_share}
                alt="share"
                onClick={() => {
                  // onShareFunction(
                  //   `https://annavaramdevasthanam.org/${selectedlanguage}/sevas-and-darshanam/paroksha-seva/${data?.name
                  //     ?.replace?.(/\s/g, "-")
                  //     ?.replace?.(/[{()}]/g, "")
                  //     .toLowerCase()}`
                  // );
                }}
              />
              {/* {getImage()} */}
              <img
                className="border-l"
                src={sd_wishlist_heart_unactive}
                alt="active"
                onClick={() => {
                  // removeFavorite(res.id);
                }}
              />
            </div>
          </div>
          <div>
            <p>
              <img src={sd_rupee} alt="Rs." /> {item.price}
            </p>
            <button
              onClick={() => {
                localStorage.setItem("selected-temple", selectedTemple);
                router?.navigate(
                  `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja/booking/${item?.name
                    ?.replace?.(/\s/g, "-")
                    .toLowerCase()}`
                );
              }}
            >
              Book Now{" "}
              <img className="imgs-booking" src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>
      );
    });
    return array;
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-profile pr-0">
          <h2 className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Pratyaksha</span> Seva
            </span>
          </h2>
          <div className="sd-form">
            <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 pr-0">
              <label>
                Select Temple<span className="sd-imp">*</span>
              </label>
              <select
                className={"form-control sd-voilet"}
                style={{ height: "42px" }}
                value={selectedTemple}
                onChange={(e) => {
                  setSelectedTemple(e.target.value);
                  getData(e.target.value);
                }}
              >
                <option style={{ display: "none" }}>Select a Temple</option>

                {templeData?.map((item) => {
                  return (
                    <option key={item.shortName} value={item.shortName}>
                      {item.name}-({item.shortName}){" "}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="sd-seva-booking">{displayFunction()}</div>
        </div>
      )}{" "}
    </div>
  );
};

export default withRouter(Sd_dashboard_online_booking_pratyaksha_seva);
