/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useState, useEffect } from "react";
import { Route, Routes as SwitchRouter, Navigate } from "react-router";
import { SD_Context } from "./context/sd-context";
import New_Nav from "./Components/sd-navbar/sd-new-nav";
import Footer from "./Components/sd-footer/sd-footer";
import Sd_Routes from "./sd-routes";
import UserService from "./services/sd-user.service";
import Sd_error_page from "./pages/sd-error-pages/sd-error-response";
import ErrorBoundary from "./context/sd-error-boundary";
import "react-toastify/dist/ReactToastify.css";

// import VideoPlayer from "./videoplayer";

const App = (props) => {
  const { setUserData, refContainer, getTempleData } = useContext(SD_Context);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("---------app useefect---------");
    AuthFunction();
  }, []);

  const AuthFunction = async () => {
    await getTempleData();
    if (localStorage.getItem("accessToken")) {
      try {
        if (localStorage.getItem("accessToken")) {
          let data = await UserService.checkToken("APEDP");
          setUserData(data);
        }
      } catch (error) {
        localStorage.clear();
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <div className="sd-main" id="myDIV" name="myDIV">
          <New_Nav />
          <div ref={refContainer}></div>
          <ErrorBoundary>
            <SwitchRouter>
              <Route path="/:lang/*" element={<Sd_Routes />} />

              <Route path="*" element={<Navigate to={`/en-in/home`} />} />
            </SwitchRouter>
            <Footer />
          </ErrorBoundary>
        </div>
      )}
      <div className="sd-error">
        <Sd_error_page />
      </div>
    </>
  );
};

App.displayName = "Apps";

export default App;
