import React from "react";
import { Helmet } from "react-helmet";
import Sd_about_main_component from "../../Components/body/sd-about/sd-about-main-component";
import SdBreadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
const Sd_about = () => {
  return (
    <div className="w-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title> About Us | Online Pooja Booking | Devasthanam </title>
        <meta
          name="description"
          content="About Us Page of Online Pooja Booking  | Devasthanam"
        />
        <meta
          name="keywords"
          content="Online Pooja Booking  | Devasthanam , About"
        />
      </Helmet>
      <SdBreadcrumb from="About" />
      <Sd_about_main_component />
    </div>
  );
};
export default Sd_about;
