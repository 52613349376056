import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import {
  baseBannerBg,
  sd_move_left,
  sd_move_right,
  SD_Print_Icon,
  sd_script,
  // SD_Right_Arrow_white,
  sd_view,
} from "../../Assets/imageList";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../context/sd-context";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import IndiaTime from "../../Utils/sd-moment/IndiaTime";
import "../../Utils/sd-styles/sd-css/main.css";
import timeFunc from "../../Utils/time-func/time-func";
import { withRouter } from "../../Utils/with-router/withRouter";

const Sd_dashboard_booking_history = ({ router }) => {
  //-------------------------------------------------------------states----------------------------------------------------------//
  const { setActive, setActivePath, logout, templeData } =
    useContext(SD_Context);
  const [loading, setLoading] = useState(false);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [act, setAct] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("success");
  const [selectedTemple, setSelectedTemple] = useState("");
  const [selectedVod, setSelectedVod] = useState(null);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect--------------------------------------------------------//
  useEffect(() => {
    setLoading(true);
    setActive("booking-history");
    setActivePath("Booking History");
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (paymentStatus !== "") {
      getData(10, 1);
      return () => {};
    }
  }, [paymentStatus, selectedTemple]);
  //-------------------------------------------------------------useefects--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//
  const getData = async (item, page) => {
    try {
      let res = await Sd_DashboardService.booking_history(
        item,
        page,
        templeData.find((item) => item.shortName === selectedTemple)?.id,
        false,
        paymentStatus
      );
      setData(res.items);
      setMeta(res.meta);
      setLoading(false);
      setAct(page);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        router?.navigate(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
      setLoading(false);
    }
  };

  const getTotal = () => {
    let array = [];
    for (let i = 1; i <= meta?.totalPages; i++) {
      array.push(
        <li
          className={act ? "sd-background" : ""}
          onClick={() => {
            if (act === i) {
              return;
            }
            getData(10, i);
          }}
        >
          <a>{i}</a>
        </li>
      );
    }
    return array;
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <>
      {" "}
      <div
        className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
        style={{
          minHeight: document.getElementById("side-menu")?.offsetHeight,
        }}
      >
        {loading ? (
          <Sd_loading />
        ) : (
          <div className="sd-confirm-details" style={{ border: "none" }}>
            <h2 className="sd-side-heading fw400">
              <span className="fw400">
                <span className="fw700">Booking </span> History
              </span>
              <span className="float-right">
                <span className="sd-view-all"></span>
              </span>
            </h2>

            <div className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 pr-0 ">
                <label>
                  Select Temple<span className="sd-imp">*</span>
                </label>
                <select
                  className={"form-control sd-voilet"}
                  value={selectedTemple}
                  onChange={(e) => {
                    setSelectedTemple(e.target.value);
                    // getData(e.target.value);
                  }}
                >
                  <option style={{ display: "none" }}>Select a Temple</option>

                  {templeData?.map((item) => {
                    return (
                      <option key={item.shortName} value={item.shortName}>
                        {item.name}-({item.shortName}){" "}
                      </option>
                    );
                  })}
                  <option key={""} value={""}>
                    All
                  </option>
                </select>
              </div>
            </div>
            <div className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 pr-0 ">
                <select
                  className={
                    paymentStatus !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  style={{ height: "42px" }}
                  value={paymentStatus}
                  onChange={(e) => {
                    setPaymentStatus(e.target.value);
                  }}
                >
                  <option key={"success"} value={"success"}>
                    Success
                  </option>
                  <option key={"failure"} value={"failure"}>
                    Failure
                  </option>
                  <option key={"created"} value={"created"}>
                    Created
                  </option>
                  <option key={"canceled"} value={"canceled"}>
                    Canceled
                  </option>
                </select>
              </div>
            </div>
            <br />
            <div
              className="clearfix sd-booking-history sd-devotee-details nan-border-none sd-toggle-table"
              style={{ border: "none" }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th width="25%">Ticket ID </th>
                    <th width="25%">service Name / Category</th>
                    <th width="15%">Person(S)</th>
                    <th width="15%">date / Time slot</th>
                    <th width="10%">Amount(₹)</th>
                    <th width="20%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i + "body"}>
                        <td>
                          <span className="fw600">{item?.ticketId}</span>

                          <br />
                          <span style={{ fontWeight: "400" }}>
                            {IndiaTime(item?.paymentDate).format(
                              "DD - MM - YYYY"
                            )}{" "}
                            <span style={{ marginLeft: "5px" }}>
                              {" "}
                              {IndiaTime(item?.paymentDate).format("hh:mm A")}
                            </span>
                            <br />({item?.templeDisplayName ?? item?.templeName}
                            )
                          </span>
                        </td>
                        <td>
                          <span className="fw600 TC">{item?.serviceName}</span>
                          <br></br>
                          <span className="TC fw400">
                            ({item?.serviceType?.replace("_", " ")})
                          </span>
                        </td>
                        <td>
                          <span className="TC">
                            {item?.noOfTickets
                              ? item?.noOfTickets !== 0
                                ? `Persons - ${item?.noOfTickets}`
                                : "-"
                              : "-"}
                          </span>
                        </td>
                        <td>
                          <span className="fw600">
                            {" "}
                            {IndiaTime(item?.bookingDate).format(
                              "DD - MM - YYYY"
                            )}
                          </span>
                          <br />
                          <span>
                            {item?.serviceType === "online_seva" ||
                            item?.serviceType === "online_darshanam" ? (
                              `${moment(item?.slotStartTime, "HH:mm:ss").format(
                                "hh:mm A"
                              )} - ${moment(
                                item?.slotEndTime,
                                "HH:mm:ss"
                              ).format("hh:mm A")}`
                            ) : (
                              <>
                                {moment(item?.slotStartTime).format(
                                  "hh:mm A"
                                ) ===
                                  moment(item?.slotEndTime).format(
                                    "hh:mm A"
                                  ) && (
                                  <>
                                    {item?.slotStartTime &&
                                      IndiaTime(item?.slotStartTime).format(
                                        "hh:mm A"
                                      )}
                                  </>
                                )}
                              </>
                            )}
                          </span>
                        </td>
                        <td className="sd-actions-icons-main">
                          <span>{item?.onlineCartItemAmount}/-</span>
                        </td>
                        <td className="sd-actions-icons-main">
                          {paymentStatus === "success" ? (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {" "}
                              <a
                                onClick={() => {
                                  router?.navigate(
                                    `/${selectedLanguage}/devotee/print-a-ticket?id=${item?.ticketId}&display=view`
                                  );
                                }}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                <img src={sd_view} className="sd-view-icon" />
                              </a>
                              {IndiaTime(item.bookingDate)?.format(
                                "YYYY-MM-DD"
                              ) === IndiaTime()?.format("YYYY-MM-DD") ? (
                                timeFunc(
                                  item?.slotStartTime,
                                  item?.slotEndTime
                                ) && (
                                  <button
                                    className="sd-btn-orange"
                                    onClick={() => {
                                      router?.navigate(
                                        `/${selectedLanguage}/devotee-app/join-a-pooja`
                                      );
                                    }}
                                    style={{
                                      marginRight: "5px",
                                      cursor: "pointer",
                                      whiteSpace: "pre",
                                    }}
                                  >
                                    Join Pooja
                                  </button>
                                )
                              ) : (
                                <></>
                              )}
                              {item?.vodDetails?.antMediaVODConfig && (
                                <a
                                  className="sd-btn-orange"
                                  onClick={() => {
                                    setSelectedVod(
                                      item?.vodDetails?.antMediaVODConfig
                                    );
                                  }}
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  Play
                                </a>
                              )}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {data.length === 0 && (
                    <td
                      colSpan="24"
                      className="pt-20 pb-30"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <br />
                      <img
                        src={sd_script}
                        alt="#"
                        height="80px"
                        width="80px"
                        style={{ marginRight: "5px", opacity: "0.3" }}
                      />
                      <p className="w-100 p-5">
                        Looks like you haven't made a booking yet.
                        <br />
                        <span
                          className="mr-5"
                          style={{ color: "#752F28", fontWeight: "600" }}
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/online-booking`
                            );
                          }}
                        >
                          {" "}
                          Click here
                        </span>
                        to make a booking.
                      </p>
                      <br />
                    </td>
                  )}
                </tbody>
              </table>
            </div>
            <div className="sd-table-detoggler">
              {data.map((item, i) => {
                return (
                  <div key={i + "tabledetoggler"} className="sd-b-d-inner">
                    <div className="sd-booking-details-up">
                      <div className="sd-actions-icons-main">
                        <span style={{ fontWeight: 600 }}>
                          {item?.ticketId}
                        </span>
                        <br />
                        <span style={{ fontWeight: "500" }}>
                          {IndiaTime(item?.paymentDate).format(
                            "DD - MM - YYYY"
                          )}{" "}
                          <span style={{ marginLeft: "5px" }}>
                            {" "}
                            {IndiaTime(item?.paymentDate).format("hh:mm A")}
                          </span>
                          {/* 6:30 PM */}
                        </span>
                      </div>
                      <div className="sd-actions-icons-main">
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/devotee/print-a-ticket?id=${item?.ticketId}&display=view`
                            );
                          }}
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <img src={sd_view} className="sd-view-icon" />
                        </a>
                        {item?.vodDetails?.antMediaVODConfig && (
                          <a
                            onClick={() => {
                              router?.navigate(
                                `/${selectedLanguage}/devotee/print-a-ticket?id=${item?.ticketId}&display=view`
                              );
                            }}
                            style={{
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            <button>Play</button>
                          </a>
                        )}
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/devotee/print-a-ticket?id=${item?.ticketId}&display=print`
                            );
                          }}
                          style={{
                            paddingRight: "10px !important",
                            borderLeft: "1px solid rgb(156 155 155)",
                          }}
                        >
                          <img
                            src={SD_Print_Icon}
                            className="sd-print-icon"
                            height="15px"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="sd-booking-details-middle">
                      <label style={{ marginBottom: "0pc" }}>
                        SERVICE NAME / CATEGORY
                      </label>
                      <br />
                      <span className="fw600 TC mr-10">
                        {item?.serviceName}
                      </span>

                      <span className="TC fw600">
                        ({item?.serviceType?.replace("_", " ")})
                      </span>
                    </div>
                    <div className="sd-booking-details-down">
                      <div>
                        <label>PERSONS</label>
                        <span className="TC">
                          {item?.personsCount !== 0
                            ? `${item?.noOfTickets}`
                            : "-"}
                        </span>
                      </div>
                      <div>
                        <label>DATE/TIME SLOT</label>
                        <span className="fw600">
                          {" "}
                          {IndiaTime(item?.bookingDate).format(
                            "DD - MM - YYYY"
                          )}
                        </span>

                        <span>
                          {item?.serviceType === "online_seva" ||
                          item?.serviceType === "online_darshanam" ? (
                            `${moment(item?.slotStartTime, "HH:mm:ss").format(
                              "hh:mm A"
                            )} - ${moment(item?.slotEndTime, "HH:mm:ss").format(
                              "hh:mm A"
                            )}`
                          ) : (
                            <>
                              {moment(item?.slotStartTime, "HH:mm:ss").format(
                                "hh:mm A"
                              ) ===
                                moment(item?.slotEndTime, "HH:mm:ss").format(
                                  "hh:mm A"
                                ) && (
                                <>
                                  {item?.slotStartTime &&
                                    IndiaTime(
                                      item?.slotStartTime,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </div>
                      <div>
                        <label>AMOUNT</label>
                        <span>{item?.onlineCartItemAmount}/-</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {data.length !== 0 && (
              <div className="clearfix mt-20">
                <p className="float-left">
                  Showing {data?.length} of {meta?.itemCount}
                </p>
                <ul className="pagination mt-0 float-right">
                  <li>
                    <a>
                      <img src={sd_move_left} alt="<-" />
                    </a>
                  </li>
                  {getTotal()}

                  <li>
                    <a>
                      <img src={sd_move_right} />
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      {selectedVod && (
        <div className="player-pop">
          <button
            className="sd-btn-orange"
            onClick={() => {
              setSelectedVod(null);
            }}
          >
            Close
          </button>
          <br />
          <ReactPlayer
            className="player-css"
            url={`https://ant-stream.devasthanam.co.in:5443/AHOBHILAM/streams/${selectedVod?.vodName}`}
            width={"100%"}
            controls={true}
            playsinline={true}
            fallback={<img src={baseBannerBg} alt="poster" />}
            playing={true}
            loop={false}
          />
        </div>
      )}
    </>
  );
};
export default withRouter(Sd_dashboard_booking_history);
