import moment from "moment";

const timeFunc = (start, end) => {
  if (
    moment()
      ?.tz("Asia/Kolkata")
      ?.isAfter(
        moment(start, "HH:mm:ssZZ").tz("Asia/Kolkata").subtract(300, "seconds")
      )
  ) {
    if (
      moment()
        .tz("Asia/Kolkata")
        ?.isBefore(moment(end, "HH:mm:ssZZ").tz("Asia/Kolkata"))
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default timeFunc;
