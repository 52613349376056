import React from "react";
import {
  SD_divider,
  vamshi,
  killi,
  siva,
  abhilash,
  aboutUs,
  aboutUsDev,
  Amit_Gupta,
  mother,
  topIdols,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";

const Sd_About_Shakthi_Pettas = () => {
  return (
    <div className="sd-about-one" style={{ paddingBottom: "60px" }}>
      <div
        className="sd-about-one-bottom flex-column"
        style={{ alignItems: "center" }}
      >
        <div className="sd-about-one-bottom-top">
          <h1 style={{ fontWeight: "600", fontSize: "30px" }}>
            Shakti Peethas
          </h1>
          <img
            style={{ height: "20px" }}
            src={SD_divider}
            alt={"divider"}
          ></img>
        </div>
        <h3
          style={{
            fontWeight: "600",
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "16px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          In Hindu philosophy, Shakti is celebrated as the paramount source of
          potency and vitality, presenting herself in the forms of Parvati,
          Lakshmi, and Saraswati.
        </h3>
        <br />
        <br />
        <img src={topIdols} alt="" style={{ maxWidth: "970px" }} /> <br />{" "}
        <br />
        <h3
          style={{
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "14px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          Among the divine Trinity, Parvati plays a pivotal role as the
          embodiment of Shakti. Goddess Parvati takes on various forms,
          including Durga (the goddess of strength), Mahakali (the goddess of
          destruction and courage), and Annapurna (the goddess who provides
          sustenance to the world). Shaktism holds a significant position within
          Hinduism, serving as a primary source of energy for other sects. The
          Sanskrit term "Shakti" directly translates to 'power,' symbolising the
          primal energy that drives creation, existence, and destruction.
        </h3>
        <br />
        <h3
          style={{
            paddingBottom: "10px",
            maxWidth: "800px",
            fontSize: "14px",
            fontFamily: "Proxima Nova Alt",
          }}
        >
          At the heart of the Shakti Peethas concept are 51 revered sites
          located globally, with 18 recognized as Maha Shakti Peethas. The
          origins of these Peethas trace back to the Satya Yuga. Daksha,
          Brahma's son, fathered twenty-seven daughters. Among them, Sati (an
          incarnation of Parvati) deeply fell in love with Lord Shiva and
          married him, defying Daksha's wishes. Daksha then conducted a grand
          ritual known as Daksha Yagna, inviting all deities except Lord Shiva
          and Sati, driven by a desire to avenge his daughter's marriage.
        </h3>
        <br />
        <br />
        <div className="sd-about-one-bottom-bottom">
          <div className="sd-abb-one" style={{ width: "100%" }}>
            <div className="top" style={{ width: "40%" }}>
              <img src={mother} alt="" />
            </div>
            <div className="bottom" style={{ width: "60%" }}>
              <div>
                Despite Shiva's advice against attending, Sati insisted on
                participating in the Yagna. However, Daksha extended a hostile
                reception, openly deriding Shiva's appearance and actions. Hurt
                by Daksha's disrespectful behaviour, Sati became despondent and
                immolated herself in the ritual fire. Learning of Sati's
                sacrifice, Shiva was consumed by grief and rage, decapitating
                Daksha and disrupting the ceremony. Shiva then hoisted Sati's
                half-burnt body onto his shoulders and began a furious dance,
                the Shiva Tandava.
              </div>
              <div>
                This cosmic dance threatened cataclysmic consequences, prompting
                Lord Vishnu to employ his Sudarshan Chakra to dismember Sati's
                body and calm Shiva's fury. The fragments of Sati's body fell to
                different parts of the world, giving rise to the sacred sites
                known as Shakti Peethas.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sd_About_Shakthi_Pettas;
