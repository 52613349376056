import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import TemplateOne from "../../Components/body/sd-home-template/sd-home-component-one";
import TemplateThree from "../../Components/body/sd-home-template/sd-home-component-three";
import TemplateTwo from "../../Components/body/sd-home-template/sd-home-component-two";
import "../../Utils/sd-styles/sd-home.scss";
import { SD_Context } from "../../context/sd-context";
import { withRouter } from "../../Utils/with-router/withRouter";
const Sd_Home = ({ router }) => {
  console.log("home");
  const { setActiveTopHeader, setActiveDownHeader, setCurrentDashboardLower } =
    useContext(SD_Context);
  // const [detectEnter, setDetect] = useState(false);
  console.log({ router: router });
  useEffect(() => {
    setActiveDownHeader("");
    setActiveTopHeader("");
    setCurrentDashboardLower("");
  }, []);
  // console.log("home element rerender");
  return (
    <div className="w-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title> Online Pooja Booking | Devasthanam</title>
        <meta
          name="description"
          content="Home Page of Online Pooja Booking  | Devasthanam"
        />
        <meta
          name="keywords"
          content="Online Pooja Booking  | Devasthanam , Home"
        />
      </Helmet>
      <TemplateOne />
      <TemplateTwo />
      <TemplateThree />
      {/* <TemplateFour />
      <Sd_gallery_semi_view /> */}
      {/* <TemplateFive /> */}
    </div>
  );
};
export default withRouter(Sd_Home);
