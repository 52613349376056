import { addDays } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import {
  noRecords,
  sd_dashboard_dob,
  SD_Logo_circle,
  startArrow,
} from "../../../Assets/imageList";
import { SD_Context } from "../../../context/sd-context";
import "../../../Utils/sd-styles/temples.scss";
import "../../../Utils/sd-styles/sd-css/main.css";
import "../../../Utils/sd-styles/sd-datepicker.scss";
import "../../../Utils/sd-styles/sd-dashboard.scss";
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
toast.configure();
import ConductPoojaService from "../../../services/sd-conduct-pooja-service";
import { withRouter } from "../../../Utils/with-router/withRouter";
import timeFunc from "../../../Utils/time-func/time-func";
const SdDashboardConductAPooja = ({ router }) => {
  const { setActivePath, setActive, userData, templeData, removeExpiredSlots } =
    useContext(SD_Context);
  useEffect(() => {
    setActive("conduct-a-pooja");
    setActivePath("Conduct A Pooja");
  });
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [slotsData, setSLotsData] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [itemId, setItemId] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const [poojaData, setPoojaData] = useState([]);

  const typeFunc = (type) => {
    return type?.replace("Seva", " Pooja");
  };

  const priestSlots = async () => {
    setSlotLoading(true);
    try {
      let data = await ConductPoojaService.getPriestSlots(
        moment(startDate)?.format("YYYY-MM-DD"),
        templeData?.find((item) => item?.id === userData?.templeId)?.shortName
      );
      data = removeExpiredSlots(data);

      if (data.length > 0) {
        const uniqueIds = new Set();
        const targetArray = [];
        data.forEach((item) => {
          if (!uniqueIds.has(item.productSlotLocation.productSlot.productId)) {
            uniqueIds.add(item.productSlotLocation.productSlot.productId);

            targetArray.push({
              name: item.productSlotLocation.productSlot.product.name,
              id: item.productSlotLocation.productSlot.productId,
              type: typeFunc(
                item?.productSlotLocation?.productSlot?.product?.sevaType
              ),
              slots: [
                {
                  name: item.productSlotLocation.productSlot.product.name,
                  type: typeFunc(
                    item?.productSlotLocation?.productSlot?.product?.sevaType
                  ),
                  productId: item.productSlotLocation.productSlot.productId,
                  id: item.productSlotLocation.productSlot.id,
                  date: item.date,
                  startTime: item.productSlotLocation.productSlot.startTime,
                  endTime: item.productSlotLocation.productSlot.endTime,
                  location: item.productSlotLocation.location.name,
                  quantity: item.quantity,
                },
              ],
            });
          } else {
            const index = targetArray.findIndex(
              (obj) => obj.id === item.productSlotLocation.productSlot.productId
            );
            targetArray[index]?.slots?.push({
              name: item.productSlotLocation.productSlot.product.name,
              type: typeFunc(
                item?.productSlotLocation?.productSlot?.product?.sevaType
              ),
              productId: item.productSlotLocation.productSlot.productId,
              id: item.productSlotLocation.productSlot.id,
              date: item.date,
              startTime: item.productSlotLocation.productSlot.startTime,
              endTime: item.productSlotLocation.productSlot.endTime,
              location: item.productSlotLocation.location.name,
              quantity: item.quantity,
            });
          }
        });
        setPoojaData(targetArray);
        setSlotLoading(false);
      } else {
        setSlotLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (startDate && userData?.templeId) {
      setPoojaData();
      setItemId("");
      setSLotsData([]);
      priestSlots();
    }
  }, [startDate, userData?.templeId]);

  const getSlots = () => {
    if (slotsData?.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData
      ?.filter((item) => moment(startDate).format("YYYY-MM-DD") === item?.date)
      ?.map((item, i) => {
        if (item?.quantity < 1) {
          return;
        }
        return (
          <div
            key={i + "slots-prs"}
            style={{ height: "100%" }}
            className={
              moment()?.format("YYYY-MM-DD") != item?.date ||
              !timeFunc(item.startTime, item.endTime)
                ? "sd-btn-grey"
                : ""
            }
          >
            <div
              className="sd-darshanam-box text-center "
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                borderRadius: "6px",
                border: "2px solid #D0D0D0;",
                alignItems: "center",
              }}
              onClick={() => {
                // setSlots(item);
              }}
            >
              <div
                className="left-box"
                style={{
                  borderRight: "1px solid #cbcbcb",
                  borderRadius: "0",
                  paddingRight: "10px",
                }}
              >
                <h1
                  style={{
                    fontWeight: "600",
                    fontSize: "50px",
                  }}
                >
                  {item?.quantity}
                </h1>
                <h5 style={{ color: "black" }}>BOOKINGS</h5>
              </div>
              <div
                className="sd-heading-2 "
                style={{
                  color: "#2f3193 !important",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  borderBottom: "0px !important",
                  fontSize: "17px",
                }}
              >
                {window.innerWidth < 650 ? (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot - (
                    {moment(item?.startTime, "HH:mm:ss").format("hh:mm A") ===
                    moment(item?.endTime, "HH:mm:ss").format("hh:mm A") ? (
                      moment(item?.startTime, "HH:mm:ss").format("hh:mm A")
                    ) : (
                      <>
                        {" "}
                        {moment(item?.startTime, "HH:mm:ss").format(
                          "hh:mm A"
                        )}{" "}
                        -{moment(item?.endTime, "HH:mm:ss").format("hh:mm A")})
                      </>
                    )}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot - (
                    {moment(item?.startTime, "HH:mm:ss").format("hh:mm A") ===
                    moment(item?.endTime, "HH:mm:ss").format("hh:mm A") ? (
                      moment(item?.startTime, "HH:mm:ss").format("hh:mm A")
                    ) : (
                      <>
                        {" "}
                        {moment(item?.startTime, "HH:mm:ss").format(
                          "hh:mm A"
                        )}{" "}
                        -{moment(item?.endTime, "HH:mm:ss").format("hh:mm A")})
                      </>
                    )}
                  </span>
                )}

                <br />
                <span
                  style={{
                    textAlign: "left",
                    fontWeight: "500",
                    color: "#171717",
                  }}
                >
                  Location: ( {item?.location} )
                </span>
                <br />
                <button
                  className={`
                   sd-btn-orange w-100 mt-5 border-none`}
                  style={{ border: "none" }}
                  onClick={() => {
                    router?.navigate(
                      `/${selectedLanguage}/devotee-app/conduct-a-pooja/stream/${item.productId}?slotID=${item.id}`
                    );
                  }}
                  disabled={
                    moment()?.format("YYYY-MM-DD") !== item?.date ||
                    !timeFunc(item.startTime, item.endTime)
                  }
                >
                  Start{" "}
                  <img
                    style={{ marginLeft: "2px" }}
                    src={startArrow}
                    alt="->"
                    height={"17px"}
                  />
                </button>
              </div>
              {/* <input
                className="sd-darshanam-radio"
                type="radio"
                id={"slot" + 11}
                name=""
                value={slot}
                onClick={() => {
                  setSlot(item);
                }}
                style={{ marginTop: "2px" }}
                checked={slot.id === item?.id}
              /> */}
            </div>
          </div>
        );
      });
  };

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard h-100"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {" "}
      <div className="sd-profile sd-seva-form h-100">
        <h2 className="sd-side-heading fw400">
          <span className="fw700" style={{ marginRight: "5px" }}>
            Conduct A
          </span>
          Pooja
        </h2>
        <hr className="clear-fix"></hr>
        <div className="mt-10 h-100 ">
          {" "}
          <div className="form-group col-xs-12 col-sm-12 col-md-7 col-lg-7  xssm-pr-0 xssm-pl-0 pr-0 pl-0">
            <div className={"sd-dob  sd-conduct-calender "}>
              <DatePicker
                allowSameDay={false}
                inline
                monthsShown={1}
                // highlightDates={highlightDates}
                // excludeDates={excludeDates}
                minDate={new Date()}
                maxDate={addDays(new Date(), 31)}
                calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                clearIcon={null}
                value={startDate}
                onChange={(date) => {
                  //   if (
                  //     err.find(
                  //       (e) => e.type === "date" || e.type === "server"
                  //     )
                  //   ) {
                  //     let data = err.filter((e) => {
                  //       if (e.type !== "date" && e.type !== "server") {
                  //         return e;
                  //       }
                  //     });
                  //     setErr(data);
                  //   }
                  setStartDate(date);
                }}
              />
            </div>

            <div className=" sd-quota">
              <div className="sd-quota-div">
                <p
                  style={{
                    backgroundColor: "#1d5d90",
                    color: "#1d5d90",
                  }}
                ></p>
                Selected
              </div>
              <div className="sd-quota-div">
                <p
                  style={{
                    backgroundColor: "rgb(0, 207, 0)",
                    color: "rgb(0, 207, 0)",
                  }}
                ></p>
                Assigned
              </div>
            </div>
            <p className="sd-EM mr-15"></p>
          </div>
          <div className="form-group col-xs-12 col-sm-12 col-md-5 col-lg-5 xssm-pr-0 xssm-pl-0 pl-0 pr-0  ">
            <div className="cp-box">
              {poojaData?.length > 0 ? (
                poojaData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`poojadiv hover ${
                        itemId === item.id ? "c-selected" : ""
                      }`}
                      style={{ padding: "10px 25px" }}
                      onClick={() => {
                        setItemId(item?.id);
                        setSLotsData(item?.slots);
                      }}
                      tabIndex="-1"
                    >
                      <div className="flex w-100">
                        <div className="flex-a">
                          <img
                            src={SD_Logo_circle}
                            style={{ width: "42px", height: "42px" }}
                          />

                          <h5 style={{ fontSize: "16px" }}>
                            {item?.name}
                            <br />
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.type}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="csoon">
                  <img src={noRecords} alt="No" height={"120px"} />
                  <br />
                  <h6 className="comingSoon">No Sevas found</h6>
                </div>
              )}
            </div>
          </div>
          <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 xssm-pl-0 pl-0 mt-20">
            <h4>Assigned Slots</h4>
            <hr className="clearfix "></hr>
            {slotsData?.length !== 0 ? (
              <>
                <div
                  className="clearfix sd-darshanam-content"
                  style={{ position: "relative" }}
                >
                  <br />
                  {getSlots()}
                  <p
                    style={{
                      position: "absolute",
                      top: "-12px",
                      left: "0px",
                      textAlign: "right",
                      color: "red",
                    }}
                  >
                    {/* {err?.find((e) => e.type === `slot`)?.msg} */}
                  </p>
                </div>
              </>
            ) : (
              <>
                {itemId && slotLoading ? (
                  <>
                    {/* <hr className="clearfix"></hr>{" "} */}
                    <div
                      className="clearfix sd-darshanam-content"
                      style={{ position: "relative", color: "red" }}
                    >
                      Fetching Slots ...
                    </div>
                  </>
                ) : startDate && poojaData?.length > 0 ? (
                  <>
                    {/* <hr className="clearfix"></hr>{" "} */}
                    <div
                      className="clearfix sd-darshanam-content"
                      style={{ position: "relative" }}
                    >
                      Please select pooja
                    </div>
                  </>
                ) : (
                  "No slots found"
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SdDashboardConductAPooja);
