import React, { useEffect, useContext, useState } from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../../Utils/sd-styles/sd-css/main.css";
import { SD_Context } from "../../../context/sd-context";

import {
  sd_btn_loading,
  sd_dashboard_side_arrow,
  sd_dashboard_upcoming_banner,
  sd_eye,
  sd_eye_close,
  sd_infor_icon,
  sd_news_list_icon,
} from "../../../Assets/imageList";
import Slider from "react-slick";
import UserService from "../../../services/sd-user.service";
import { toast } from "react-toastify";
toast.configure();
import { withRouter } from "../../../Utils/with-router/withRouter";
const Sd_dashboard_ma_change_password = ({ router }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { setActive, setActivePath, userData, logout } = useContext(SD_Context);
  const [oldPassword, setOldPassword] = useState("");
  const [newpass, setNewpass] = useState("");
  const [newCpass, setNewCpass] = useState("");
  const [type1, setType1] = useState(true);
  const [type2, setType2] = useState(true);
  const [errArray, setErrArray] = useState([]);

  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const [loading, setLoading] = useState(false);
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nav: false,
    arrows: false,
    autoplaySpeed: 15000,
  };
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useefect--------------------------------------------------------//
  useEffect(() => {
    setActive("change-password");
    setActivePath("My Account-Change Password");

    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  //-------------------------------------------------------------useefect--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//
  const handleSubmit = async () => {
    setLoading(true);
    if (
      newCpass !== newpass ||
      newpass === "" ||
      newCpass === "" ||
      oldPassword === ""
    ) {
      let data = [...errArray];

      if (oldPassword === "") {
        data.push({ type: "old-pass", msg: "Please enter your old password" });
      }
      if (newCpass === "") {
        data.push({ type: "pass", msg: "Please enter your new password" });
      }
      if (newpass === "") {
        data.push({ type: "passN", msg: "Please confirm your new password" });
      }
      if (newCpass !== newpass) {
        data.push({
          type: "pass-match",
          msg: "Password and confirm password does not match",
        });
      }
      setLoading(false);
      setErrArray(data);
      return;
    }
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
        newpass
      )
    ) {
      try {
        let res = await UserService.changePassword(
          oldPassword,
          newpass,
          userData.phone
        );
        if (res) {
          toast.success("Succesfully changed your password.");
          setOldPassword("");
          setNewCpass("");
          setNewpass("");
          setLoading(false);
        }
      } catch (e) {
        let data = [...errArray];

        if (e.message === "Session Expired") {
          logout();
          router?.navigate(
            `/${selectedLanguage}/devoteee/signin?to=devotee-app/my-account/change-password`
          );
        } else if (
          e.message ===
          "Request validation of body failed, because: password must contain 1 number, 1 upper, 1 lower, 1 special and should be 8 chars long"
        ) {
          data.push({ type: "old-pass", msg: "Invalid password. Try again." });
          setErrArray(data);
        } else {
          data.push({ type: "passN", msg: e?.message });
          setErrArray(data);
        }
        setLoading(false);
      }
    } else {
      let data = [...errArray];
      data.push({
        type: "pass-struc",
        msg: "Invalid password structure. Try again.",
      });
      setErrArray(data);
      setLoading(false);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 pl-0">
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 pl-0 xs-pr-0">
          <h2 className="sd-side-heading fw500">
            <span className="fw700">Change</span> Password
          </h2>
          <div className="clearfix">
            <form className="sd-form" autoComplete="off">
              <div className="form-group">
                <label htmlFor="old">
                  Old Password<span className="sd-imp">*</span>
                </label>
                <input
                  autoFocus={true}
                  maxLength="30"
                  placeholder="Please enter your old password"
                  value={oldPassword}
                  type="password"
                  autoComplete="new-password"
                  className={
                    errArray?.find((e) => e.type === "old-pass")
                      ? "form-control sd-red sd-txt"
                      : oldPassword !== ""
                      ? "sd-voilet form-control sd-txt"
                      : "form-control sd-txt"
                  }
                  onChange={(e) => {
                    if (errArray?.find((e) => e.type === "old-pass")) {
                      let array = [...errArray];
                      array = errArray.filter((e) => {
                        e.type !== "old-pass";
                      });
                      setErrArray(array);
                    }
                    setOldPassword(e.target.value);
                  }}
                />
                <p className="sd-EM">
                  {errArray?.find((e) => e.type === "old-pass")?.msg}
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="pwd" className="sd-passwords-tt">
                  New Password<span className="sd-imp">*</span>
                  <img src={sd_infor_icon} />
                  <div>
                    Password must be atleast 8 characters long, and contain
                    atleast 1 Uppercase Alphabet, 1 Number, 1 Special character.
                    Ex: Password@1
                  </div>
                </label>
                <div className="sd-passwords">
                  <input
                    maxLength="30"
                    placeholder="Please enter your new password"
                    value={newpass}
                    type={type1 ? "password" : ""}
                    autoComplete="new-password"
                    className={
                      errArray?.find(
                        (e) =>
                          e.type === "pass" ||
                          e.type === "pass-match" ||
                          e.type === "pass-struc"
                      )
                        ? "sd-red form-control sd-txt"
                        : newpass !== ""
                        ? "sd-voilet form-control sd-txt"
                        : "form-control sd-txt"
                    }
                    onChange={(e) => {
                      if (
                        errArray?.find(
                          (e) =>
                            e.type === "pass" ||
                            e.type === "pass-match" ||
                            e.type === "pass-struc"
                        )
                      ) {
                        let array = [...errArray];
                        array = errArray.filter((e) => {
                          e.type !== "pass" ||
                            e.type !== "pass-match" ||
                            e.type !== "pass-struc";
                        });
                        setErrArray(array);
                      }
                      setNewpass(e.target.value);
                    }}
                  />
                  {type1 ? (
                    <img
                      src={sd_eye}
                      alt="C"
                      onClick={() => {
                        setType1(!type1);
                      }}
                    />
                  ) : (
                    <img
                      src={sd_eye_close}
                      alt="C"
                      onClick={() => {
                        setType1(!type1);
                      }}
                    />
                  )}
                </div>

                <p className="sd-EM">
                  {
                    errArray?.find(
                      (e) => e.type === "pass" || e.type === "pass-match"
                    )?.msg
                  }
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="pwd" className="sd-passwords-tt">
                  Confirm New Password<span className="sd-imp">*</span>
                  <img src={sd_infor_icon} />
                  <div>
                    Password must be atleast 8 characters long, and contain
                    atleast 1 Uppercase Alphabet, 1 Number, 1 Special character.
                    Ex: Password@1
                  </div>
                </label>
                <div className="sd-passwords">
                  <input
                    type={type2 ? "password" : ""}
                    value={newCpass}
                    maxLength="30"
                    autoComplete="new-password"
                    placeholder="Confirm new password"
                    className={
                      errArray?.find(
                        (e) =>
                          e.type === "passN" ||
                          e.type === "pass-match" ||
                          e.type === "pass-struc"
                      )
                        ? "sd-red form-control sd-txt"
                        : newCpass !== ""
                        ? "sd-voilet form-control sd-txt"
                        : "form-control sd-txt"
                    }
                    onChange={(e) => {
                      if (
                        errArray?.find(
                          (e) =>
                            e.type === "passN" ||
                            e.type === "pass-match" ||
                            e.type === "pass-struc"
                        )
                      ) {
                        let array = [...errArray];
                        array = errArray.filter((e) => {
                          e.type !== "passN" ||
                            e.type !== "pass-match" ||
                            e.type !== "pass-struc";
                        });
                        setErrArray(array);
                      }
                      setNewCpass(e.target.value);
                    }}
                  />
                  {type2 ? (
                    <img
                      src={sd_eye}
                      alt="C"
                      onClick={() => {
                        setType2(!type2);
                      }}
                    />
                  ) : (
                    <img
                      src={sd_eye_close}
                      alt="C"
                      onClick={() => {
                        setType2(!type2);
                      }}
                    />
                  )}
                </div>
                {errArray?.find(
                  (e) =>
                    e.type === "passN" ||
                    e.type === "pass-match" ||
                    e.type === "pass-struc" ||
                    e.type === "common"
                ) && (
                  <p className="sd-EM">
                    {
                      errArray?.find(
                        (e) =>
                          e.type === "passN" ||
                          e.type === "pass-match" ||
                          e.type === "pass-struc" ||
                          e.type === "common"
                      )?.msg
                    }
                  </p>
                )}
              </div>
              {/* {errArray?.find((e) => e.type === "common") && (
                <p className="sd-EM">
                  {errArray?.find((e) => e.type === "common")?.msg}
                </p>
              )} */}

              <div className="sd-form-button-orange clearfix mb-20">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit{" "}
                  {loading ? (
                    <img src={sd_btn_loading} alt=".." className="h-20" />
                  ) : (
                    <img
                      className="sd-arrow-btn ml-5"
                      src={sd_dashboard_side_arrow}
                      alt=">"
                    />
                  )}
                </a>
              </div>
            </form>
          </div>
        </div>
        <hr className="clearfix col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0"></hr>
        <div className="clearfix col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0">
          <p className="sd-para-text-18">
            Forgot old password ?{" "}
            <a
              className="sd-text-blue"
              onClick={() => {
                router?.navigate(`/${selectedLanguage}/devotee/assistance`);
              }}
            >
              Get Help
            </a>
          </p>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 sd-dashboard-right-panel xs-pl-0 xs-pr-0 sm-pt-30 pr-0">
        <div className="sd-upcoming-events">
          <h2 className="sd-side-heading fw500 sd-border-no">
            <span className="fw700">Upcoming</span> Events
          </h2>
          <Slider {...settings}>
            <div className="item">
              {" "}
              <img
                src={sd_dashboard_upcoming_banner}
                alt="banner"
                width="100%"
              />
            </div>
            <div className="item">
              {" "}
              <img
                src={sd_dashboard_upcoming_banner}
                alt="banner"
                width="100%"
              />
            </div>
            <div className="item">
              {" "}
              <img
                src={sd_dashboard_upcoming_banner}
                alt="banner"
                width="100%"
              />
            </div>
          </Slider>
        </div>
        <div className="sd-news-updates mt-40">
          <h2 className="sd-side-heading fw500">
            <span className="fw700">News</span> Updates
          </h2>
          <div className="sd-news-list mt-20">
            <div className="item">
              <img
                className="sd-dashboard-icons"
                src={sd_news_list_icon}
                alt="news"
              />
              <div className="sd-news-para">
                <p>
                  Paroksha Pooja will be booked through Online Pooja Booking |
                  Devasthanam -{" "}
                  <a
                    onClick={() => {
                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/online-booking/paroksha-pooja`
                      );
                    }}
                  >
                    Click here
                  </a>
                </p>
                <span className="sd-news-date">14 Feb 2021</span>
              </div>
            </div>
          </div>
          <div className="sd-news-list mt-20">
            <div className="item">
              <img
                className="sd-dashboard-icons"
                src={sd_news_list_icon}
                alt="news"
              />
              <div className="sd-news-para">
                <p>
                  Pratyaksha Pooja will be booked through Durga Malleswara Swamy
                  Varla Devasthanam Website -{" "}
                  <a
                    onClick={() => {
                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-pooja`
                      );
                    }}
                  >
                    Click here
                  </a>
                </p>
                <span className="sd-news-date">14 Feb 2021</span>
              </div>
            </div>
          </div>
          <div className="clearfix mt-50">
            <a className="sd-btn-orange">
              View All{" "}
              <img
                className="sd-arrow-btn ml-5"
                src={sd_dashboard_side_arrow}
                alt=">"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Sd_dashboard_ma_change_password);
