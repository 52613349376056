import React, { useState } from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
toast.configure();
import {
  sd_btn_loading,
  sd_dashboard_side_arrow,
} from "../../Assets/imageList";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../context/sd-context";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import { withRouter } from "../../Utils/with-router/withRouter";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_token_fectcher = ({ router }) => {
  const [userID, setUserID] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const { userData } = useContext(SD_Context);
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  const handleSubmit = async () => {
    setLoading(true);
    if (userID === "") {
      setErr("Please enter a User ID");
    } else {
      setErr("");
    }
    try {
      let res = await Sd_DashboardService.switch(userID);
      if (res) {
        localStorage?.setItem("accessToken", res.accessToken);
        setLoading(false);
        window.location.reload();
      }
    } catch (e) {
      toast.error(e?.message);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (userData?.role !== "admin") {
      router?.navigate(`/${selectedlanguage}/devotee-app/devotee-dashboard`);
    }
  }, []);
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-profile pr-0">
          <h2 className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">User</span> Switch
            </span>
          </h2>
          <div
            className="sd-seva-booking"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pr-0 pl-0 ">
              <label>
                User ID<span className="sd-imp">*</span>
              </label>
              <input
                style={{ boxShadow: "none" }}
                type="text"
                maxLength="30"
                className={
                  err !== ""
                    ? "form-control sd-red"
                    : userID !== ""
                    ? "form-control sd-voilet"
                    : "form-control"
                }
                placeholder="Please enter User id to Switch into user account"
                value={userID}
                onChange={(e) => {
                  if (err !== "") {
                    setErr("");
                  }
                  setUserID(e.target.value);
                }}
              />
            </div>

            <a
              className="sd-btn-orange mt-10"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Continue
              {loading ? (
                <img
                  src={sd_btn_loading}
                  alt=".."
                  className="sd-arrow-btn ml-5 h-20"
                />
              ) : (
                <img
                  className="sd-arrow-btn ml-5 mt-2"
                  src={sd_dashboard_side_arrow}
                />
              )}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default withRouter(Sd_token_fectcher);
