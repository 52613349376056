import React, { useState, useContext, useEffect, useRef } from "react";
import {
  sd_btn_loading,
  SD_Dropdown_Arrow_icon,
  SD_Right_Arrow_white,
  sd_script,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-datepicker.scss";
import "../../../../Utils/sd-styles/sd-dashboard.scss";
import "../../../../Utils/sd-styles/sd-css/main.css";
import "react-datepicker/dist/react-datepicker.css";

import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import { toast } from "react-toastify";
toast.configure();
import "react-toastify/dist/ReactToastify.css";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import DatePicker from "react-datepicker";
import moment from "moment";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import PhoneInput from "react-phone-input-2";
import {
  fnCalculateAge,
  addDays,
} from "../../../../Utils/sd-utility-functions/utility-functions";
import { bookingTimeIsWithinProductTime } from "../../../../Utils/sd-slot-management/slot-management";
import { withRouter } from "../../../../Utils/with-router/withRouter";

const Sd_dashboard_pratyakshaseva_seva_details = ({ router }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [loading, setLoading] = useState(true);
  const {
    setActivePath,
    setActive,
    currentBookingPratyaksha,
    setcurrentBookingPratyaksha,
    devoteeList,
    userData,
    logout,
    templeData,
  } = useContext(SD_Context);
  const ref = useRef(null);
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [noPersons, setNoperons] = useState(1);
  const [pratyakshaSevaData, setPratyakshaSevaData] = useState([]);
  const [countArray, setCountArray] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [autoFill, setAutoFill] = useState("none");
  const [personStates, setPersonStates] = useState([
    {
      id: 1,
      name: "",
    },
  ]);
  const [err, setErr] = useState([]);
  const [gothram, setGothram] = useState("");
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [slotsData, setSlotsData] = useState([]);
  const [highlightDates, setHighlightDates] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);
  const [selectedTemple, setSelectedTemple] = useState("");
  const [mobile, setMobile] = useState("91");
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const termsList = [
    "Only couple (Wife & Husband) or single person will be allowed to perform the Pooja on one ticket.",
    "Other kith and kin will not be allowed along with Poojadars of any Pooja ticket holders. Those kith and kin should take Rs.500/- ticket each to enter into the temple only for having Darshanam and not to perform the Pooja",
    "An ID proof on the name of the ticket holder must also be shown.",
    "This ticket is non-transferable and it cannot be cancelled.",
    "Mobiles are strictly prohibited in Temple Premises.",
  ];

  //-------------------------------------------------------------consts-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//
  useEffect(() => {
    setActive("online-booking-pratyaksha-enter-details");
    setActivePath("Onlinebooking-Pratyaksha Pooja-Enter Details");
    setLoading(true);
    if (
      localStorage.getItem("selected-temple") ||
      currentBookingPratyaksha?.selectedTemple
    ) {
      setSelectedTemple(
        localStorage.getItem("selected-temple") ??
          currentBookingPratyaksha?.selectedTemple
      );
      getData(
        localStorage.getItem("selected-temple") ??
          currentBookingPratyaksha?.selectedTemple
      );
    } else {
      setSelectedTemple(templeData[0].shortName);
      getData(templeData[0].shortName);
    }
    localStorage.removeItem("payment-context");
  }, []);

  useEffect(() => {
    listFunction();
    return () => {};
  }, [noPersons]);

  useEffect(() => {
    if (selectedArray && selectedArray[0]?.id) {
      getQuota(selectedArray[0]);
    }
    return () => {};
  }, [selectedArray]);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//

  const getData = async (templeID) => {
    if (!router.params.type) {
      router?.navigate(
        `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja`
      );
    }
    try {
      let data = await PratyakshaSevaService.getAllvalidOnline(templeID);
      data = data?.filter(
        (item) => item.onlineEnabled && item.sevaType === "pratyakshaSeva"
      );
      setSelectedArray([]);
      setSelectedValue("");
      setPratyakshaSevaData(data);
      if (currentBookingPratyaksha) {
        setNoperons(currentBookingPratyaksha?.noPersons);
        setPersonStates(currentBookingPratyaksha?.personStates);
        setGothram(currentBookingPratyaksha?.gothram);
        setStartDate(currentBookingPratyaksha?.startDate);
        setSelectedArray(currentBookingPratyaksha?.selectedArray);
        currentBookingPratyaksha?.slot &&
          setSlot(currentBookingPratyaksha?.slot);
        setSlot(currentBookingPratyaksha?.slot);
        setSlotsData(currentBookingPratyaksha?.slotsData);
        setMobile(currentBookingPratyaksha?.mobile);
        const count = [];
        for (
          let i = 1;
          i <= currentBookingPratyaksha?.selectedArray[0].noOfPeople;
          i++
        ) {
          count.push(i);
        }
        setCountArray(count);
      } else {
        if (data) {
          let finder = data?.find(
            (item) =>
              item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
              router.params.type
          );
          if (finder) {
            if (finder?.noOfPeople) {
              const count = [];
              for (let i = 1; i <= finder?.noOfPeople; i++) {
                count.push(i);
              }
              setCountArray(count);
            }
            setSelectedArray([finder]);
          }
        }
      }
      setLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        router?.navigate(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-pooja`
        );
      }
      setLoading(false);

      return;
    }
  };

  const buttonsArrayFunction = () => {
    return selectedArray?.map((item, i) => {
      return (
        <div
          className="button-div"
          style={{ display: "flex" }}
          key={i + "buttons-prs+handle"}
        >
          <p>
            {item?.name}
            <span className="mr-3">-</span>Rs.
            {item?.price}
          </p>
        </div>
      );
    });
  };

  const listFunctionSevas = () => {
    // console.log("in pratyaksha seva");
    let temp = pratyakshaSevaData.filter((item) => item.onlineEnabled);
    let array = temp.map((item, i) => {
      if (!item?.onlineEnabled || item?.maxOnlineBookings === 0) {
        return;
      } else {
        let finder = null;
        for (let i = 0; i < selectedArray.length; i++) {
          if (selectedArray[i].name === item?.name) {
            finder = true;
          }
        }
        return (
          <div
            key={i + "list-prsoption"}
            className={finder ? "option-class sd-cyan" : "option-class"}
            onClick={() => {
              setSelectedArray([item]);
              setSlot("");
              setSlotsData([]);
              setStartDate("");
              setShow(false);
            }}
          >
            {item?.name}
            <span className="mr-3">-</span> Rs.
            {item.price}
          </div>
        );
      }
    });
    return array;
  };

  const handleChange = (index, event, name) => {
    if (name === "doc_file_url") {
      let data = [...personStates];
      data[index][`${name}`] = event;
      setPersonStates(data);
    } else {
      if (name === "idProofType") {
        let data = [...personStates];

        let value = data[index][`${name}`];
        data[index][`${name}`] = event.target.value;
        if (value !== event.target.value) {
          data[index][`doc_file_url`] = "";
          data[index][`idProofNumber`] = "";
        }
        setPersonStates(data);
      } else if (name === "idProofNumber") {
        let data = [...personStates];
        data[index][`idProofNumber`] = event.target.value
          ?.replaceAll?.(" ", "")
          ?.toUpperCase();
        setPersonStates(data);
      } else {
        let data = [...personStates];
        data[index][`${name}`] = event.target.value;
        setPersonStates(data);
      }
    }
  };

  const listFunction = () => {
    if (noPersons === personStates.length) {
      return;
    }
    if (noPersons < personStates.length) {
      let arr = [...personStates];

      let data = err.filter((e) => {
        if (
          e.type !== `${personStates.length}-name` &&
          e.type !== `${personStates.length}-idProofType` &&
          e.type !== `${personStates.length}-idProofNumber` &&
          e.type !== `${personStates.length}-doc_file_url`
        ) {
          return e;
        }
      });
      setErr(data);
      arr.pop();

      setPersonStates([...arr]);
      return;
    }
    if (noPersons > personStates.length) {
      let arr = [...personStates];
      arr.push({
        id: personStates.length + 1,
        name: "",
        idProofType: "",
        idProofNumber: "",
        doc_file_url: "",
      });
      setPersonStates([...arr]);
      return;
    }
  };

  const autoFillFunction = (name, i) => {
    let array = [];
    let userOnClick = () => {
      let data = [...personStates];
      data[i]["name"] =
        userData?.firstName || userData?.lastName
          ? `${userData?.firstName} ${userData?.lastName}`
          : userData?.displayName;

      setGothram(userData?.gothram ?? "");
      setMobile(userData?.phone?.replace("+", "") ?? "");
      setPersonStates(data);
      let datas = err.filter((e) => {
        if (e.type !== `${i + 1}-name`) {
          return e;
        }
      });
      setErr(datas);
      setAutoFill("none");
    };
    let a = personStates.filter((e) => {
      if (
        e?.name?.toLowerCase() ===
        `${userData?.firstName?.toLowerCase()} ${userData?.lastName?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {/* {userData?.gender && <p>Gender: {userData?.gender}</p>} */}
          </p>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = personStates.filter((e) => {
            if (e?.name === `${items?.firstName} ${items?.lastName}`) {
              return e;
            }
          });
          if (a?.length === 0) {
            let onCLick = () => {
              let data = [...personStates];
              data[i]["name"] =
                items?.firstName || items?.lastName
                  ? `${items?.firstName} ${items?.lastName}`
                  : "";

              setPersonStates(data);
              let datas = err.filter((e) => {
                if (e.type !== `${i + 1}-name`) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <div
                key={items.id + Math.random()}
                className="sd-autofill-div"
                onClick={() => {
                  onCLick();
                }}
              >
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Name: {items?.firstName}
                  <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
                </p>
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                {/* <p>Gender: {items?.gender}</p> */}
              </div>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <div
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </div>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };

  const getSlotsDetails = () => {
    if (slotsData?.length === 0 || !slotsData) {
      return (
        <div
          className="clearfix sd-darshanam-content"
          style={{ position: "relative", color: "red" }}
        >
          No Slots available on {moment(startDate).format("DD-MM-YYYY")}
        </div>
      );
    }
    return slotsData
      ?.filter((item) => moment(startDate).format("YYYY-MM-DD") === item?.date)
      ?.sort((a, b) =>
        a.productSlot?.startTime.localeCompare(b.productSlot?.startTime)
      )
      ?.map((item, i) => {
        if (item?.availableQuantity < 1) {
          return;
        }
        return (
          <div
            key={i + "slots-prsMG"}
            onClick={() => {
              if (item?.availableQuantity < 1) {
                return;
              }
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <div
              className="sd-darshanam-box text-center"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "row-reverse",
                borderRadius: "6px",
                border:
                  err.find((e) => e.type === "slot") ||
                  item?.availableQuantity < 1
                    ? "1px solid red"
                    : "2px solid #D0D0D0;",
              }}
              onClick={() => {
                if (personStates?.length > item?.availableQuantity) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
            >
              {/* <p
                className="sd-darshanam-time"
                style={{ marginBottom: "0px", maxWidth: "85px" }}
              >
                <span style={{ marginRight: "3px", fontWeight: "600" }}>
                  {item.availableQuantity}
                </span>
                <span style={{ fontWeight: "400" }}> Available </span>
              </p> */}
              <div
                className="sd-heading-2 "
                style={{
                  color: "#2f3193 !important",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  borderBottom: "0px !important",
                  fontSize: "17px",
                }}
              >
                {window.innerWidth < 650 ? (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot <br /> (
                    {moment(
                      item?.productSlotLocation?.productSlot?.startTime,
                      "HH:mm:ssZ"
                    ).format("hh:mm A") ===
                    moment(
                      item?.productSlotLocation?.productSlot?.endTime,
                      "HH:mm:ssZ"
                    ).format("hh:mm A") ? (
                      moment(
                        item?.productSlotLocation?.productSlot?.startTime,
                        "HH:mm:ssZ"
                      ).format("hh:mm A")
                    ) : (
                      <>
                        {moment(
                          item?.productSlotLocation?.productSlot?.startTime,
                          "HH:mm:ssZ"
                        ).format("hh:mm A")}{" "}
                        to
                        <span style={{ marginLeft: "5px" }}>
                          {moment(
                            item?.productSlotLocation?.productSlot?.endTime,
                            "HH:mm:ssZ"
                          ).format("hh:mm A")}
                          )
                        </span>
                      </>
                    )}
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot - (
                    {moment(
                      item?.productSlotLocation?.productSlot?.startTime,
                      "HH:mm:ssZ"
                    ).format("hh:mm A") ===
                    moment(
                      item?.productSlotLocation?.productSlot?.endTime,
                      "HH:mm:ssZ"
                    ).format("hh:mm A") ? (
                      moment(
                        item?.productSlotLocation?.productSlot?.startTime,
                        "HH:mm:ssZ"
                      ).format("hh:mm A")
                    ) : (
                      <>
                        {" "}
                        {moment(
                          item?.productSlotLocation?.productSlot?.startTime,
                          "HH:mm:ssZ"
                        ).format("hh:mm A")}{" "}
                        -
                        {moment(
                          item?.productSlotLocation?.productSlot?.endTime,
                          "HH:mm:ssZ"
                        ).format("hh:mm A")}
                        )
                      </>
                    )}
                  </span>
                )}
                <br />
                <span
                  style={{
                    textAlign: "left",
                    fontWeight: "500",
                    color: "#171717",
                  }}
                >
                  {" "}
                  Available: {item?.availableQuantity}
                </span>
                <br />
              </div>
              <input
                className="sd-darshanam-radio"
                type="radio"
                id={"slot" + 11}
                name=""
                value={slot}
                onClick={() => {
                  if (personStates?.length > item?.availableQuantity) {
                    return;
                  }
                  setSlot(item);
                  if (err?.find((e) => e.type === `slot`)) {
                    let data = err.filter((e) => e.type !== "slot");
                    setErr(data);
                  }
                }}
                style={{ marginTop: "2px" }}
                checked={slot.id === item?.id}
              />
            </div>
          </div>
        );
      });
  };

  const getDates = (startDate, endDate, quotaDates, notAvailableDates) => {
    let date = startDate;
    let dates = [];
    while (date <= endDate) {
      if (
        !quotaDates?.includes(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))
      ) {
        dates.push(new Date(date));
      }
      date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }
    setExcludeDates([...notAvailableDates, ...dates]);
  };

  const getQuota = async (data) => {
    setSlotLoading(true);
    let quotaDates = [];
    let availableDates = [];
    let availableDatesString = [];
    let almostBookedDates = [];
    let almostBookedDatesString = [];
    let notAvailableDates = [];
    let notAvailableDatesString = [];
    try {
      let res = await PratyakshaSevaService.getDailyQuota(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        Number(data?.id),
        data?.onlineEnabled,
        selectedTemple
      );
      const filteredData = res?.filter((item) =>
        bookingTimeIsWithinProductTime(
          item?.date,
          item?.productSlotLocation.productSlot?.startTime,
          data?.minOnlineHours || 1,
          data?.maxOnlineHours
        )
      );
      setSlotsData(filteredData);

      filteredData?.map((item) => {
        quotaDates.push(item?.date);
        if (item?.quantity > 0) {
          if (Number(item?.availableQuantity / item?.quantity) * 100 > 50) {
            availableDates.push(new Date(item?.date));
            availableDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              notAvailableDates.splice(
                notAvailableDates.indexOf(new Date(item?.date).toString()),
                1
              );
              notAvailableDatesString.splice(
                notAvailableDatesString.indexOf(
                  new Date(item?.date).toString()
                ),
                1
              );
            }
          } else if (
            Number(item?.availableQuantity / item?.quantity) * 100 <= 50 &&
            Number(item?.availableQuantity / item?.quantity) * 100 > 0
          ) {
            almostBookedDates.push(new Date(item?.date));
            almostBookedDatesString.push(new Date(item?.date).toString());
            if (
              notAvailableDatesString.includes(new Date(item?.date).toString())
            ) {
              notAvailableDates.splice(
                notAvailableDates.indexOf(new Date(item?.date).toString()),
                1
              );
              notAvailableDatesString.splice(
                notAvailableDatesString.indexOf(
                  new Date(item?.date).toString()
                ),
                1
              );
            }
          } else {
            if (
              !availableDatesString.includes(new Date(item?.date).toString())
            ) {
              if (
                !almostBookedDatesString.includes(
                  new Date(item?.date).toString()
                )
              ) {
                notAvailableDates.push(new Date(item?.date));
                notAvailableDatesString.push(new Date(item?.date).toString());
              }
            }
          }
        }
      });
      notAvailableDates?.map((date) => {
        if (
          availableDatesString.includes(date.toString()) ||
          almostBookedDatesString.includes(date.toString())
        ) {
          delete notAvailableDates[notAvailableDates.indexOf(date)];
        }
      });
      setHighlightDates([
        { "react-datepicker__day--highlighted-custom-1": availableDates },
        { "react-datepicker__day--highlighted-custom-2": almostBookedDates },
      ]);
      // console.log({
      //   availableDates,
      //   notAvailableDates,
      //   almostBookedDates,
      // });
      getDates(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        quotaDates,
        notAvailableDates?.filter((item) => item)
      );
      setSlotLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        router?.navigate(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-pooja`
        );
      }
      setLoading(false);

      return;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];

      if (!checkbox) {
        data.push({
          type: "check",
          msg: "Please agree to terms and conditions",
        });
      }
      if (startDate === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (slot === "") {
        data.push({ type: "slot", msg: "Please select a slot" });
      }
      if (gothram === "") {
        data.push({ type: "gothram", msg: "Please enter gothram" });
      }
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length < 10) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 10) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      }
      personStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            data.push({
              type: `${item["id"]}-${key}`,
              msg: `Invalid ${key}`,
            });
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }
          }
        }
      });
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }

    try {
      setLoading(true);
      let dup = [...personStates];
      let arrayEx = dup.map((e) => {
        return {
          personName: e?.name,
        };
      });
      let schema = {
        cartContains: ["seva"],
        sevaBookings: [
          {
            sevaSlotId: slot?.productSlotLocation.productSlotId,
            bookingDate: moment(startDate).format("YYYY-MM-DD"),
            phoneNumber: `+${mobile}`,
            personDetails: arrayEx,
            gothram: gothram,
            quotaType: "slot_location_quota",
          },
        ],
        returnPriceStats: true,
      };

      let res = await ParokshaSevaService.bookings(schema, selectedTemple);
      if (res) {
        let data = {
          selectedArray,
          startDate,
          noPersons,
          personStates,
          slot,
          slotsData,
          mobile,
          res,
          gothram,
          selectedTemple,
        };
        setcurrentBookingPratyaksha(data);
        router?.navigate(
          `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-pooja/confirm-details`
        );
      }
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      ``;

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        router?.navigate(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-pooja`
        );
      }
      setLoading(false);

      return;
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 xs-pr-0 sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700" ref={ref}>
                Pratyaksha
              </span>{" "}
              Pooja
            </span>
          </h2>
          <div className="clearfix">
            <form className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 pr-0 col-lg-12  xssm-pr-0 pl-0 ">
                <label>
                  Select Temple<span className="sd-imp">*</span>
                </label>
                <select
                  className={
                    err?.find((e) => e.type === `temple`)
                      ? "form-control sd-red"
                      : selectedTemple !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  value={selectedTemple}
                  onChange={(e) => {
                    if (err.find((e) => e.type === `temple`)) {
                      let data = err.filter((e) => e.type !== `$temple`);
                      setErr(data);
                    }
                    setSelectedTemple(e.target.value);

                    getData(e.target.value);
                  }}
                >
                  <option style={{ display: "none" }}>Select a Temple</option>

                  {templeData?.map((item) => {
                    return (
                      <option key={item.shortName} value={item.shortName}>
                        {item.name}-({item.shortName}){" "}
                      </option>
                    );
                  })}
                </select>
                <p className="sd-EM mr-15">
                  {err.find((e) => e.type === `temple`)?.msg}
                </p>
              </div>

              {pratyakshaSevaData.length !== 0 ? (
                <>
                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                    <label>
                      Select a Pooja<span className="sd-imp">*</span>
                    </label>

                    <div className="select-div">
                      <div
                        className={
                          selectedArray.length > 0
                            ? "constant-top sd-white sd-voilet-border"
                            : "constant-top "
                        }
                      >
                        <div className="sd-buttonArray">
                          {buttonsArrayFunction()}
                        </div>

                        <div className="sd-input-image">
                          <input
                            className={
                              selectedArray.length > 0 ? "sd-white" : " "
                            }
                            type="text"
                            onClick={() => {
                              setShow(!show);
                            }}
                            onChange={(e) => {
                              setSelectedValue({ value: e.target.value });
                            }}
                          />
                          <img
                            className={show ? "sd-image-invert" : ""}
                            src={SD_Dropdown_Arrow_icon}
                            alt=""
                            onClick={() => {
                              setShow(!show);
                            }}
                          />
                        </div>
                      </div>

                      {show && (
                        <div
                          className="option-div"
                          style={{ paddingTop: "1vh" }}
                        >
                          {listFunctionSevas()}
                        </div>
                      )}
                    </div>
                    {selectedArray.find(
                      (e) =>
                        e?.name ===
                        "Abhishekam Alamkaramandapam (Couple) Only Alamkara Darshanam"
                    ) && (
                      <p style={{ color: "red" }}>
                        Note : Due to devotee rush on going in this Karthika
                        Masam, devotees are allowed to make Alankara Dharshanam
                        only.
                      </p>
                    )}
                    {selectedArray.find(
                      (e) => e?.name === "Samuhika Abhishekamulu (Couple)"
                    ) && (
                      <p style={{ color: "red" }}>
                        Note : Kind attention Devotees. Due to Heavy Rush during
                        Kartheeka Masam, the Sparsha Darsanam of Sri
                        Mallikarjuna Swamy and Garbhalaya Abhishekams are being
                        stopped on Sundays, Mondays and Kartheeka Pournami from
                        05.11.2021 to 04.12.2021.
                      </p>
                    )}
                    {selectedArray.find(
                      (e) => e?.name === "VIP Break Darshanam"
                    ) && (
                      <p style={{ color: "red" }}>
                        Note : Kind attention Devotees. Due to Heavy Rush during
                        Kartheeka Masam, the Sparsha Darsanam Tickets of Sri
                        Mallikarjuna Swamy and Garbhalaya Abhishekams Tickets
                        are freezed on Sundays, Mondays and Kartheeka Pournami
                        from 05.11.2021 to 04.12.2021.
                      </p>
                    )}
                  </div>
                  <hr className="clearfix"></hr>
                  <div className="form-group col-xs-12 pl-0 col-sm-12 col-md-12 col-lg-12 mb-0 xssm-pl-0 xssm-pr-0 pr-0 sd-acc-main">
                    <label>
                      Select Date<span className="sd-imp">*</span>
                    </label>

                    <div
                      className={
                        err?.find((e) => e.type === `date`)
                          ? "sd-dob sd-acc-calender sd-red"
                          : startDate !== ""
                          ? "sd-date-voilet sd-dob sd-acc-calender"
                          : "sd-dob sd-acc-calender"
                      }
                    >
                      <DatePicker
                        allowSameDay={false}
                        placeholderText="DD/MM/YYYY"
                        dateFormat={"dd/MM/yyyy"}
                        showDisabledMonthNavigation
                        style={{
                          width: "90%",
                          border: "none",
                          backgroundColor: "blue",
                        }}
                        inline
                        monthsShown={
                          window.outerWidth < 600
                            ? 1
                            : window.outerWidth > 1100
                            ? 3
                            : 2
                        }
                        highlightDates={highlightDates}
                        excludeDates={excludeDates}
                        minDate={new Date()}
                        maxDate={addDays(
                          new Date(),
                          selectedArray[0]?.maxOnlineHours / 24
                        )}
                        clearIcon={null}
                        selected={startDate}
                        onChange={(date) => {
                          if (
                            err.find(
                              (e) => e.type === "date" || e.type === "server"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "date" && e.type !== "server") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setStartDate(date);
                          // setData([]);
                        }}
                      />

                      <div className=" sd-quota">
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "#1d5d90",
                              color: "#1d5d90",
                            }}
                          ></p>
                          Selected
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(0, 207, 0)",
                              color: "rgb(0, 207, 0)",
                            }}
                          ></p>
                          Available
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "orange",
                              color: "orange",
                            }}
                          ></p>
                          Almost Booked
                        </div>
                        <div className="sd-quota-div">
                          <p
                            style={{
                              backgroundColor: "rgb(201, 0, 0)",
                              color: "rgb(201, 0, 0)",
                            }}
                          ></p>
                          Not Available
                        </div>
                      </div>
                    </div>
                    <p className="sd-EM mr-15">
                      {err.find((e) => e.type === "date")?.msg}
                    </p>
                  </div>
                  <hr className="clearfix"></hr>
                  <div className="sd-profile flex">
                    <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4 xssm-pl-0 xssm-pr-0 mb-0 pl-0">
                      <label>
                        Gothram<span className="sd-imp">*</span>
                      </label>
                      <input
                        maxLength="30"
                        type="text"
                        placeholder="Enter gothram."
                        className={
                          err?.find((e) => e.type === `gothram`)
                            ? "form-control sd-red"
                            : gothram !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={gothram}
                        onChange={(e) => {
                          if (
                            /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                            e.target.value === ""
                          ) {
                            if (err.find((e) => e.type === `gothram`)) {
                              let data = err.filter(
                                (e) => e.type !== `gothram`
                              );
                              setErr(data);
                            }
                            setGothram(e.target.value);
                          }
                        }}
                      />
                      <p className="sd-EM ">
                        {err.find((e) => e.type === `gothram`)?.msg}
                      </p>
                    </div>
                    <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0 ">
                      <label>
                        Number of Person(s)<span className="sd-imp">*</span>
                      </label>
                      <select
                        className={
                          noPersons !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={noPersons}
                        onChange={(e) => {
                          setNoperons(e.target.value);
                        }}
                      >
                        {countArray.map((num) => {
                          return (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0 pr-0">
                      <label>
                        Mobile Number<span className="sd-imp">*</span>
                      </label>
                      <div className={"sd-profile-mobile"}>
                        <PhoneInput
                          inputExtraProps={{
                            autoFocus: true,
                            enableSearch: true,
                            countryCodeEditable: false,
                          }}
                          onlyCountries={["in"]}
                          placeholder="Enter Your 10 digit mobile number"
                          maxLength="15"
                          countryCodeEditable={false}
                          containerClass={
                            err?.find((e) => e.type === "phone")
                              ? "sd-phone-voilet sd-red"
                              : mobile !== ""
                              ? "sd-phone-voilet"
                              : ""
                          }
                          country="in"
                          value={mobile}
                          onChange={(e) => {
                            if (
                              err?.find(
                                (e) => e.type === "phone" || e.type === "common"
                              )
                            ) {
                              let array = err.filter((e) => {
                                if (e.type !== "phone" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(array);
                            }
                            setMobile(e);
                          }}
                        />
                        <p className="d-EM ">
                          {err.find((e) => e.type === "phone")
                            ? err.find((e) => e.type === "phone")?.msg
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  {slotsData?.length !== 0 && startDate ? (
                    <>
                      <hr className="clearfix"></hr>

                      <div
                        className="clearfix sd-darshanam-content"
                        style={{ position: "relative" }}
                      >
                        <br />
                        {getSlotsDetails()}
                        <p
                          style={{
                            position: "absolute",
                            top: "-12px",
                            left: "0px",
                            textAlign: "right",
                            color: "red",
                          }}
                        >
                          {err?.find((e) => e.type === `slot`)?.msg}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      {startDate && !slotLoading ? (
                        <>
                          <hr className="clearfix"></hr>{" "}
                          <div
                            className="clearfix sd-darshanam-content"
                            style={{ position: "relative", color: "red" }}
                          >
                            No Slots available on{" "}
                            {moment(startDate).format("DD-MM-YYYY")}
                          </div>
                        </>
                      ) : (
                        startDate && (
                          <>
                            <hr className="clearfix"></hr>{" "}
                            <div
                              className="clearfix sd-darshanam-content"
                              style={{ position: "relative" }}
                            >
                              Fetching slots...Please wait{" "}
                            </div>
                          </>
                        )
                      )}
                    </>
                  )}
                  <div className="clearfix">
                    <hr className="clearfix"></hr>
                  </div>{" "}
                  <div className="clearfix" key={"personstates-prslist"}>
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pl-0 pr-0">
                      {personStates.map((item, i) => {
                        return (
                          <div
                            className={`form-group col-xs-12 col-sm-12 col-md-6 col-lg-4  xssm-pr-0  ${
                              i % 2 === 0 && "pl-0"
                            }`}
                            key={i + "pratyak_namess"}
                          >
                            <label>
                              {i + 1}.Name<span className="sd-imp">*</span>
                            </label>
                            <input
                              maxLength="20"
                              autoComplete="new-password"
                              onFocus={() => {
                                if (autoFill === `${i}autofill`) {
                                  setAutoFill("none");
                                } else {
                                  setAutoFill(`${i}autofill`);
                                }
                              }}
                              onBlur={() => {
                                if (autoFill === `${i}autofill`) {
                                  setTimeout(() => {
                                    setAutoFill("none");
                                  }, 250);
                                }
                              }}
                              placeholder="Please enter your name"
                              type="text"
                              className={
                                err?.find((e) => e.type === `${i + 1}-name`)
                                  ? "form-control sd-red"
                                  : item?.name !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              value={item?.name}
                              onChange={(e) => {
                                if (
                                  /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                                  e.target.value === ""
                                ) {
                                  if (
                                    err.find((e) => e.type === `${i + 1}-name`)
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-name`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, e, "name");
                                }
                              }}
                            />
                            <p className="sd-EM mr-15 ">
                              {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                            </p>
                            {autoFillFunction(item?.name, i)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                    <p
                      className="ml--15"
                      style={{
                        color: "#181818",
                        fontWeight: "600",

                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Note: Pilgrims who book for Pratyaksha Pooja should bring
                      the printed copy of their receipt.{" "}
                    </p>
                    <ol className="sd-terms-list">
                      {termsList.map((item, i) => {
                        return (
                          <li className="active" key={i + "terms-prslist"}>
                            <a style={{ paddingRight: "3px" }}>{item}</a>
                          </li>
                        );
                      })}
                    </ol>

                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox ml--15 items-center">
                      {" "}
                      <input
                        className="mr-10"
                        type="checkbox"
                        name=""
                        value={checkbox}
                        onChange={() => {
                          if (err.find((e) => e.type === `check`)) {
                            let data = err.filter((e) => e.type !== `check`);
                            setErr(data);
                          }
                          setCheckbox(!checkbox);
                        }}
                        defaultChecked={checkbox === true ? true : false}
                      />
                      <label
                        htmlFor=""
                        style={{ marginBottom: "0px", position: "relative" }}
                      >
                        I agree to the{" "}
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedlanguage}/legalities/terms-and-conditions`
                            );
                          }}
                        >
                          Terms & Conditions
                        </a>{" "}
                        and the
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedlanguage}/legalities/privacy-policy`
                            );
                          }}
                        >
                          {" "}
                          Privacy Policy
                        </a>
                      </label>
                      <p className="sd-accept">
                        {err.find((e) => e.type === "check")?.msg}
                      </p>
                    </div>
                  </div>
                  <hr className="clearfix"></hr>
                  <div className="clearfix mt-50">
                    <a
                      className="sd-btn-orange"
                      onClick={() => {
                        if (!loading) {
                          handleSubmit();
                        }
                      }}
                    >
                      Confirm Booking{" "}
                      {loading ? (
                        <img
                          src={sd_btn_loading}
                          alt=".."
                          className="sd-arrow-btn ml-5 h-20"
                        />
                      ) : (
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      )}
                    </a>
                  </div>
                </>
              ) : (
                <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
                  <img
                    src={sd_script}
                    alt="#"
                    height="80px"
                    width="80px"
                    className="mr-5 mb-5 opace"
                  />
                  We are sorry to say that there are no pratyaksha pooja
                  available for booking. We regret the inconvenience.
                </p>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_pratyakshaseva_seva_details);
