/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from "react";
import {
  arrow,
  hands_red,
  laptop,
  noRecords,
  sd_dashboard_side_arrow,
  sd_dashboard_upcoming,
  sd_dots_two,
  sd_news_list_icon,
  SD_Right_Arrow_white,
  shrine,
  upcomingBanner1,
} from "../../Assets/imageList";

import "../../Utils/sd-styles/sd-css/main.css";
import { SD_Context } from "../../context/sd-context";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import IndiaTime from "../../Utils/sd-moment/IndiaTime";
import Slider from "react-slick";
import moment from "moment";
import timeFunc from "../../Utils/time-func/time-func";
import { withRouter } from "../../Utils/with-router/withRouter";
import { memo } from "react";
const Sd_dashboard_devotee_dashboard = ({ router }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { setActive, setActivePath, logout, userData } = useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [hoverActive, setHoverActive] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [statisctics, setStatictics] = useState([]);
  const [modal, setModal] = useState(false);
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nav: false,
    autoplaySpeed: 15000,
    arrows: false,
  };
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-----------------------------------------------------------useeffect------------------------------------------------------//
  useEffect(() => {
    setLoading(true);
    setActive("devotee-dashboard");
    setActivePath("Dashboard");
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getData();
  }, []);

  useEffect(() => {
    if (userData?.email === "") {
      setModal(true);
    }
  }, []);

  //------------------------------------------------------------useffect--------------------------------------------------------//

  //------------------------------------------------------------functions----------------------------------------------------------//

  const getData = async () => {
    try {
      // let res = await Sd_DashboardService.upcoming_booking(4, 1);

      let res = await Sd_DashboardService.booking_history(
        5,
        1,
        "",
        true,
        "success"
      );

      let res1 = await Sd_DashboardService.getStatistics();
      setStatictics(res1);
      setData(res.items);
      setLoading(false);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        router?.navigate(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
      setLoading(false);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard w-auto">
      {loading ? (
        <Sd_loading />
      ) : (
        <>
          <h2 className="sd-side-heading fw500">
            <span className="fw700">Devotee</span> Dashboard
          </h2>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 sd-dd-boxes">
            <div
              className="sd-dd-box "
              onMouseLeave={() => {
                setHoverActive("none");
              }}
            >
              <div className="sd-dd-box-one">
                <img src={shrine} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                }}
              >
                <h2 className="color-2">Pratyaksha Pooja </h2>
                <h1
                  className="color-2"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    fontSize: "50px",
                  }}
                >
                  {statisctics.pratyakshaSeva ?? 0}

                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "5px",
                      alignSelf: "center",
                    }}
                  >
                    <p
                      className="color-2"
                      style={{ lineHeight: "16px", fontSize: "14px" }}
                    >
                      Total <br /> Booking
                    </p>
                  </span>
                </h1>
              </div>

              <img
                src={sd_dots_two}
                alt=""
                className="sd-dd-spcl"
                onClick={() => {
                  setHoverActive("hover2");
                }}
              />
              <div
                className={
                  hoverActive === "hover2"
                    ? "sd-dd-hidden"
                    : "sd-dd-hidden sd-hide"
                }
              >
                <div
                  onClick={() => {
                    setHoverActive("none");
                  }}
                >
                  All
                </div>
              </div>
            </div>
            <div
              className="sd-dd-box "
              onMouseLeave={() => {
                setHoverActive("none");
              }}
            >
              <div className="sd-dd-box-one">
                <img src={hands_red} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                }}
              >
                <h2 className="color-2">Paroksha Pooja </h2>
                <h1
                  className="color-2"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    fontSize: "50px",
                  }}
                >
                  {statisctics.parokshaSeva ?? 0}
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "5px",
                      alignSelf: "center",
                    }}
                  >
                    <p
                      className="color-2"
                      style={{ lineHeight: "16px", fontSize: "14px" }}
                    >
                      Total <br /> Booking
                    </p>
                  </span>
                </h1>
              </div>

              <img
                src={sd_dots_two}
                alt=""
                className="sd-dd-spcl"
                onClick={() => {
                  setHoverActive("hover2");
                }}
              />
              <div
                className={
                  hoverActive === "hover2"
                    ? "sd-dd-hidden"
                    : "sd-dd-hidden sd-hide"
                }
              >
                <div
                  onClick={() => {
                    setHoverActive("none");
                  }}
                >
                  All
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 pl-0 ">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0">
                <div className="sd-upcoming-bookings">
                  <img
                    src={sd_dashboard_upcoming}
                    width="50px"
                    height="50px"
                    style={{ position: "absolute" }}
                  />
                  <div className="sd-upcoming-bookings-table">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #cbcbcb",
                        alignItems: "center",
                      }}
                    >
                      <h3 className="sd-side-heading-2 fw600 b-none">
                        Upcoming Bookings
                      </h3>
                      {data.length !== 0 && (
                        <div className="clearfix mt-20 mb-20">
                          <a
                            onClick={() => {
                              router?.navigate(
                                `/${selectedLanguage}/devotee-app/booking-history`
                              );
                            }}
                            style={{ color: "#181818", fontSize: "16px" }}
                          >
                            View All
                            <img
                              className="sd-arrow-btn ml-5"
                              src={SD_Right_Arrow_white}
                            />
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="clearfix">
                      <table>
                        {data.map((item) => {
                          return (
                            <tr>
                              <td
                                width="100%"
                                className="sd-booking-name flex-j"
                              >
                                <span>
                                  <a
                                    onClick={() => {
                                      router?.navigate(
                                        `/${selectedLanguage}/devotee/print-a-ticket?id=${item?.ticketId}&display=view`
                                      );
                                    }}
                                  >
                                    {item.serviceName}
                                  </a>
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      marginLeft: "3px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    ({item?.serviceType?.replace("_", " ")})
                                  </span>
                                  <br />
                                  {item?.templeDisplayName ?? item?.templeName}
                                </span>
                                <span>
                                  {IndiaTime(item.bookingDate).format(
                                    "ddd, Do MMMM  YYYY"
                                  )}

                                  {item?.serviceType === "online_darshanam" ||
                                  item?.serviceType === "online_seva" ? (
                                    <>
                                      - (
                                      {moment(
                                        item.slotStartTime,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}{" "}
                                      -
                                      {moment(
                                        item.slotEndTime,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                      )
                                    </>
                                  ) : (
                                    <>
                                      {IndiaTime(item?.slotStartTime).format(
                                        "hh:mm A"
                                      ) ===
                                      IndiaTime(item?.slotEndTime).format(
                                        "hh:mm A"
                                      ) ? (
                                        <>
                                          {item.slotStartTime && ` - `}
                                          {item.slotStartTime &&
                                            IndiaTime(
                                              item.slotStartTime
                                            ).format("hh:mm A")}
                                        </>
                                      ) : (
                                        <>
                                          {item.slotStartTime && ` - `}
                                          {item.slotStartTime &&
                                            IndiaTime(
                                              item.slotStartTime
                                            ).format("hh:mm A")}
                                          -
                                          {item.slotEndTime &&
                                            IndiaTime(item.slotEndTime).format(
                                              "hh:mm A"
                                            )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </span>
                                {IndiaTime(item.bookingDate)?.format(
                                  "YYYY-MM-DD"
                                ) === IndiaTime()?.format("YYYY-MM-DD") ? (
                                  timeFunc(
                                    item?.slotStartTime,
                                    item?.slotEndTime
                                  ) && (
                                    <button
                                      className="common-btn"
                                      style={{
                                        whiteSpace: "pre",
                                        height: "fit-content",
                                        padding: "4px 8px",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() => {
                                        router?.navigate(
                                          `/${selectedLanguage}/devotee-app/join-a-pooja`
                                        );
                                      }}
                                    >
                                      Join Now
                                    </button>
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        {data.length === 0 && (
                          <div
                            className="flex"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "30px 0px",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={noRecords}
                              alt="#"
                              height="100%"
                              style={{ marginRight: "5px", maxHeight: "200px" }}
                            />
                            <br />
                            <p style={{ textAlign: "center" }}> No Bookings</p>
                          </div>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="sd-dashboard-banners w-auto"
                style={{ width: "auto" }}
              >
                <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                  <div className="sd-four-sections sd-seva-banner">
                    <div className="sd-seva-right-para">
                      <h3 className="sd-side-heading-2 fw600 sd-border-no pb-15">
                        Pratyaksha Pooja
                      </h3>
                      <div className="clearfix">
                        <p className="sd-booking-date sd-para-text">
                          Facility to the Devotees to perform Pooja / Abhishekam
                          / Homam etc. for Their wellbeing.
                          <br />
                          <a
                            style={{
                              fontSize: "13px",
                              color: "#9A031E",
                              borderBottom: "1px solid #9A031E",
                            }}
                          >
                            View more
                          </a>
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <a
                          className="sd-btn-orange"
                          style={{ padding: "6px 13px", height: "100%" }}
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-pooja`
                            );
                          }}
                        >
                          Book Now
                          <img
                            className="sd-arrow-btn ml-5"
                            src={arrow}
                            style={{ height: "14px", width: "auto" }}
                          />
                        </a>
                        <img src={laptop} height="50px" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                  <div className="sd-four-sections sd-accommodation-banner">
                    <div className="sd-seva-right-para">
                      <h3 className="sd-side-heading-2 fw600 sd-border-no  pb-15">
                        Paroksha Pooja
                      </h3>
                      <div className="clearfix">
                        <p className="sd-booking-date sd-para-text">
                          Facility to the Devotees to perform Pooja / Abhishekam
                          / Homam etc. for Their wellbeing.
                          <br />
                          <a
                            style={{
                              fontSize: "13px",
                              color: "#9A031E",
                              borderBottom: "1px solid #9A031E",
                            }}
                          >
                            View more
                          </a>
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <a
                          className="sd-btn-orange"
                          style={{ padding: "6px 13px", height: "100%" }}
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/devotee-app/online-booking/paroksha-pooja`
                            );
                          }}
                        >
                          Book Now
                          <img
                            className="sd-arrow-btn ml-5"
                            src={arrow}
                            style={{ height: "14px", width: "auto" }}
                          />
                        </a>
                        <img
                          src={laptop}
                          height="50px"
                          style={{ visibility: "hidden" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 sd-dashboard-right-panel xs-pl-0 xs-pr-0 sm-pt-30 pr-0 pl-0">
              <div className="sd-upcoming-events">
                <h2 className="sd-side-heading fw500 sd-border-no">
                  <span className="fw700">Upcoming</span> Events
                </h2>
                <Slider {...settings}>
                  <div className="item" tabIndex="-2" data-index="-2">
                    {" "}
                    <img
                      src={upcomingBanner1}
                      alt="banner"
                      width="100%"
                      // onClick={() => {
                      //   router?.navigate(
                      //     `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/varalakshmi-vratham`
                      //   );
                      // }}()
                    />
                  </div>
                  {/* <div className="item" tabIndex="-1" data-index="-1">
                  {" "}
                  <img
                    src={sd_event3}
                    alt="banner"
                    width="100%"
                    onClick={() => {
                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/nandeeshwara-swamy-vari-visesharchana`
                      );
                    }}
                  />
                </div>
                <div className="item" tabIndex="-3" data-index="-2">
                  <img
                    src={sd_event1}
                    alt="banner"
                    width="100%"
                    onClick={() => {
                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/laksha-kumkumarchana`
                      );
                    }}
                  />
                </div>
                <div className="item" tabIndex="-2" data-index="-3">
                  {" "}
                  <img
                    src={sd_event2}
                    alt="banner"
                    width="100%"
                    onClick={() => {
                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/sri-bayalu-veerabhadra-swamy-vari-visesharchana`
                      );
                    }}
                  />
                </div>{" "} */}
                </Slider>
              </div>
              <div className="sd-news-updates mt-40">
                <h2 className="sd-side-heading fw500">
                  <span className="fw700">News</span> Updates
                </h2>
                <div className="sd-news-list mt-20">
                  <div className="item">
                    <img
                      className="sd-dashboard-icons"
                      src={sd_news_list_icon}
                      alt="news"
                    />
                    <div className="sd-news-para">
                      <p>
                        Dussera Celebrations will be started with great grandeur
                        from 25-09-2022
                      </p>
                      <span className="sd-news-date">09 Sep 2022</span>
                    </div>
                  </div>
                </div>
                <div className="sd-news-list mt-20">
                  <div className="item">
                    <img
                      className="sd-dashboard-icons"
                      src={sd_news_list_icon}
                      alt="news"
                    />
                    <div className="sd-news-para">
                      <p>
                        Sri Kanaka Durgamma Temple gearing for 10 day Dussera
                        festivities this year
                      </p>
                      <span className="sd-news-date">09 Sep 2022</span>
                    </div>
                  </div>
                </div>
                <div className="clearfix mt-50">
                  <a
                    className="sd-btn-orange"
                    onClick={() => {
                      router?.navigate(
                        `/${selectedLanguage}/media-room/press/temple-news`
                      );
                    }}
                  >
                    View All
                    <img
                      className="sd-arrow-btn ml-5"
                      src={sd_dashboard_side_arrow}
                      alt=">"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {modal && (
            <div className="sd-profile-popup">
              <div
                className="inner"
                style={{
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "150px",
                }}
              >
                <h2>
                  Update Email Address in My Account to recieve online tickets.{" "}
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      router?.navigate(
                        `/${selectedLanguage}/devotee-app/my-account/profile`
                      );
                    }}
                  >
                    Click here
                  </span>{" "}
                </h2>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#752F28",
                    marginTop: "10px",
                    alignSelf: "center",
                  }}
                  className="btn"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default memo(withRouter(Sd_dashboard_devotee_dashboard));
